import React, { Component } from 'react'
import UserContext from '../../contexts/UserContext';
// import TokenService from '../../services/token-service'
import UserService from '../../services/UserService'
import validator from 'validator'

export class ResetPasswordForm extends Component {
  static contextType = UserContext
  static defaultProps = {
    onResetSuccess: () => {},
    email:""
  };
  state = { error: null };

  // Handles JWT authentication for RegForm credentials
  handleSubmitJwtAuth = (ev) => {
    ev.preventDefault();
    this.setState({ error: null });
    let errors = []
    const { email, password, confirm } = ev.target;
    console.log(password.value)
    // test function, delete when hooked up to node backend
    // validations
    if (!validator.isEmail(email.value)) {
      errors = [
        ...errors,
        'Please Enter a valid Email'
      ]
    }

    if(password.value.length < 5){
      errors = [
        ...errors,
        'Passwords must be longer than 5 characters'
      ]
    }

    if (password.value !== confirm.value) {
      errors = [
        ...errors,
        'Passwords must match'
      ]
    }

    if (errors.length > 0)
      return this.setState({ error: errors })
    // need to make this function
    console.log(password.value)
    UserService.updatePassword({
        email: email.value,
        password: password.value,
      })
        .then((res) => {
          console.log("then",res)
          // email.value = '';
          // password.value = '';
          // // const authToken = TokenService.makeBasicAuthToken(res.email, res.email)
          // TokenService.saveAuthToken(res.token);
          // // this.context.setUserId(res.id);
          // this.context.setCurrentUser(res.user)
          this.props.onResetSuccess();
        })
        .catch((res) => {
          console.log(res,"catch")
          if(res === undefined){
            res = "No User found"
          }
          this.setState({ error: res });
        });
  };

  render() {
    const { error } = this.state;
    return (
      <form className='LoginForm' onSubmit={this.handleSubmitJwtAuth}>
        <div role='alert'>
          {error && <p className='login-error'>{error}</p>}
        </div>
        <div className='email flexbox-column'>
          <label className="login-form-label-left login-label" htmlFor='RegForm__email'>Email</label>{' '}
          <input
            type='text'
            required
            name='email'
            disabled
            value={this.props.email}
            id='RegForm__email'
          ></input>
          <span className="tooltip">Email should match contact email in Zoho CRM</span>
        </div>
        <div className='password flexbox-column'>
          <label className="login-form-label-left login-label" htmlFor='RegForm__password'>Password</label>{' '}
          <input
            required
            name='password'
            type='password'
            id='RegForm__password'
          ></input>
          <span></span>
        </div>
        <div className='confirm flexbox-column'>
          <label className="login-form-label-left login-label" htmlFor='RegForm__confirm'>Confirm Password</label>{' '}
          <input
            required
            name='confirm'
            type='password'
            id='RegForm__confirm'
          ></input>
        </div>
        <button className="cta-button" type='submit'>RESET</button>
      </form>
    );
  }
}

export default ResetPasswordForm

