import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import ProjectService from '../../services/ProjectService'
import ProductService from '../../services/ProductService'
import UserService from '../../services/UserService'

const SearchPage = () => {
  // add search functionality, pass key word via props and only show projects, professionals, professional
  let { keyword } = useParams()
  const [professionals, setProfessionals] = useState([]);
  const [projects, setProjects] = useState([]);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    let projectList, productList, professionalList;
    // console.log(keyword)
    if (keyword !== undefined) {
      keyword = decodeURIComponent(keyword)
      // console.log(getProjectsByKeyword)
      ProjectService.getProjectsByKeyword(keyword)
        .then((results) => {
          console.log(results)
          projectList = results.projects.map(project => {
            return (<div key={project.id} className="projects-headers bolder-font">
              <div>{project.number}</div>
              <div><Link to={`/details/project/${project.id}`} className="remove-text-decoration card-header-title-style-gray">{project.project_name}</Link></div>
              <div>{project.status}</div>
              {/* need to grab correct column names from SQL Table */}
              <div>{project.LeadCPHC}</div>
              <div>{project.Builder}</div>
              <div>{project.Location}</div>
              <div>{project.ConstructionType}</div>
              <div>{project.BldgFunction}</div>
              <div>{project.FloorArea}</div>
              <div>{project.ProjectType}</div>
              <img className="listImgThumbnail" src={project.image} alt={project.project_name} />
              {/* <div>{project.image}</div> */}
            </div>
            )
          })
          setProjects(projectList)
        })
      ProductService.getProductsByKeyword(keyword)
        .then(results => {
          console.log(results)
          productList = results.certified_products.map(product => {
            return (<div key={product.id} className="products-headers bolder-font">
              <div>{product.product_id_number}</div>
              <div><Link to={`/details/product/${product.id}`} className="remove-text-decoration card-header-title-style-gray">{product.product_name}</Link></div>
              <div>{product.category}</div>
              <div>{product.manufacturer_crm_name}</div>
              <div>{product.sales_end_date}</div>
              <div>{product.model}</div>
              <div>{product.frame_material}</div>
              {/* missing columns in SQL table/CRM */}
              <div>{product['PsiOpaqueGrade']}</div>
            </div>
            )
          })
          setProducts(productList)
        })
      UserService.getUsersByKeyword(keyword)
        .then(results => {
          console.log(results)
          professionalList = results.contacts.map(user => {
            return (user.profile_type === "Professional") ? (
              <div className="professional-headers bolder-font" key={user.user_id}>
                <div>{user.first_name}</div>
                <div>{user.last_name}</div>
                <div>{user.city}</div>
                <div>{user.state}</div>
                <Link to={`/profile/${user.user_id}`} className="remove-text-decoration card-header-title-style-gray">Profile</Link>
              </div>
            )
              : null
          })
          setProfessionals(professionalList)
        })
    }
    else {
      // console.log("search else")
      ProjectService.getAllProjects()
        .then(projects => {
          // console.log(projects)
          projectList = projects.projects.map(project => {
            return (
              <div key={project.id} className="projects-headers bolder-font">
                <div>{project.project_number}</div>
                <div><Link to={`/details/project/${project.id}`} className="remove-text-decoration card-header-title-style-gray">{project.project_name}</Link></div>
                <div>{project.status}</div>
                {/* need to grab correct column names from SQL Table */}
                <div>{project.LeadCPHC}</div>
                <div>{project.Builder}</div>
                <div>{project.Location}</div>
                <div>{project.ConstructionType}</div>
                <div>{project.BldgFunction}</div>
                <div>{project.FloorArea}</div>
                <div>{project.ProjectType}</div>
                {/* <img className="listImgThumbnail" src={project.image} alt={project.title} /> */}
                {/* <div>{project.image}</div> */}
              </div>
            )
          })
          setProjects(projectList)
        })
      ProductService.getAllProducts()
        .then(products => {
          // console.log(products.certified_products)
          productList = products.certified_products.map(product => {
            // console.log(product)
            return (
              <div key={product.id} className="products-headers bolder-font">
                <div>{product.product_id_number}</div>
                <div><Link to={`/details/product/${product.id}`} className="remove-text-decoration card-header-title-style-gray">{product.name}</Link></div>
                <div>{product.category}</div>
                <div>{product.manufacturer_crm_name}</div>
                <div>{product.expiration_date}</div>
                <div>{product.model}</div>
                <div>{product.frame_material}</div>
                <div>{product.glazing_option}</div>
              </div>
            )
          })
          setProducts(productList)
        })
      UserService.getAllUsers()
        .then(users => {
          // console.log(users)
          professionalList = users.users.map(user => {
            // console.log(user)
            // console.log(user.membertype)
            return (user.profile_type === "Professional") ? (
              <div className="professional-headers bolder-font" key={user.user_id}>
                <div>{user.first_name}</div>
                <div>{user.last_name}</div>
                <div>{user.city}</div>
                <div>{user.state}</div>
                <Link to={`/profile/${user.user_id}`} className="remove-text-decoration card-header-title-style-gray">Profile</Link>
              </div>
            ) : null
          })
          setProfessionals(professionalList)
        })
    }
  }, [])
  return (
    <section style={{ maxHeight: '100%' }}>
      <div className="card-container" >
        <div className="card-header-grey display-flex">
          <p className="card-header-title-style-gray">Projects</p>
          <div>
            <Link className="remove-text-decoration card-header-title-style-gray report-button" to="/projects">View All</Link>
            <Link className="remove-text-decoration card-header-title-style-gray" to="#">Add</Link>
          </div>
        </div>
        <div className="card-body h30">
          <div className="projects-headers">
            <p className="projects-and-products-headers-text-color-grey">No.</p>
            <p className="projects-and-products-headers-text-color-grey">Title</p>
            <p className="projects-and-products-headers-text-color-grey">Status</p>
            <p className="projects-and-products-headers-text-color-grey">Lead CPHC</p>
            <p className="projects-and-products-headers-text-color-grey">Builder</p>
            <p className="projects-and-products-headers-text-color-grey">Location</p>
            <p className="projects-and-products-headers-text-color-grey">Const. Type</p>
            <p className="projects-and-products-headers-text-color-grey">Bldg. Function</p>
            <p className="projects-and-products-headers-text-color-grey">Floor Area</p>
            <p className="projects-and-products-headers-text-color-grey">Project Type</p>
            <p className="projects-and-products-headers-text-color-grey">Photo</p>
          </div>
          <div className="overflow-container">
            {projects}
          </div>
        </div>
        <div className="card-header-grey display-flex">
          <p className="card-header-title-style-gray">Products</p>
          <div>
            <Link className="remove-text-decoration card-header-title-style-gray report-button" to="/products">View All</Link>
            <Link className="remove-text-decoration card-header-title-style-gray" to="#">Add</Link>
          </div>
        </div>
        <div className="card-body h30">
          <div className="products-headers">
            <p className="projects-and-products-headers-text-color-grey">Product Number</p>
            <p className="projects-and-products-headers-text-color-grey">Certified Product Name</p>
            <p className="projects-and-products-headers-text-color-grey">Product Category</p>
            <p className="projects-and-products-headers-text-color-grey">Manufacturer</p>
            <p className="projects-and-products-headers-text-color-grey">Expiration Date</p>
            <p className="projects-and-products-headers-text-color-grey">Model</p>
            <p className="projects-and-products-headers-text-color-grey">Frame Material</p>
            <p className="projects-and-products-headers-text-color-grey">Psi-Opaque Grade</p>
          </div>
          <div className="overflow-container">
            {products}
          </div>
        </div>
        <div className="card-header-grey display-flex">
          <p className="card-header-title-style-gray">Professionals</p>
          <div>
            <Link className="remove-text-decoration card-header-title-style-gray report-button" to="/professionals">View All</Link>
            <Link className="remove-text-decoration card-header-title-style-gray" to="#">Add</Link>
          </div>
        </div>
        <div className="card-body h30">
          <div className="professional-headers">
            <p className="projects-and-products-headers-text-color-grey">First Name</p>
            <p className="projects-and-products-headers-text-color-grey">Last Name</p>
            <p className="projects-and-products-headers-text-color-grey">City</p>
            <p className="projects-and-products-headers-text-color-grey">State</p>
            <p className="projects-and-products-headers-text-color-grey">Details</p>
          </div>
          <div className="overflow-container">
            {professionals}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SearchPage
