import React, { useContext, useState, useEffect } from 'react'
import ProductService from '../../services/ProductService'
import UserContext from '../../contexts/UserContext'
import Tabs from './Tabs'
import './ProjectDetail.css'

export default function ProductDetail(props) {
  const [profileType, setProfileType] = useState()
  const [product, setProduct] = useState(props.product)
  const [loading,setLoading] = useState(true)
  const userContext = useContext(UserContext)

  useEffect(() => {
    setProfileType(userContext.currentUser.profile_type)
    setProduct(props.product)
    setLoading(false)
  }, [])

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log(name," : ",value)
  //   setProduct({
  //     ...product,
  //     [name]: value
  //   })
  // }

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   console.log(product)
  //   // console.log(e)
  //   ProductService.updateProduct(product).then(res =>{
  //     setProduct(res.product)
  //   })
  // }

  // const submitButton = profileType === "Submitter" ? <input type="submit" className="cta-button" value="Update" /> : null

  if(loading){
    return <section style={{ position: 'relative', top: '50%', left: '50%' }}>
    {/* loading user */}
    <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </section>
  }
  console.log("Product Detail",props.product)

  return (
    <form className="detailCard-project">
      <div className="detailProjectHeader">
        <h3 className="bolder">{product.certification_job_number}</h3>
        <p>-</p>
        <h3 className="bolder">{product.certification_job_name}</h3>
        {/* {submitButton} */}
      </div>
      <Tabs>
        <div label="Project Details">
          <table className="project-detail-table">
            <tbody>
              <tr>
                <td>Certification Category</td>
                <td >{product.product_category}</td>
              </tr>
              <tr>
                <td>Certification Job Name</td>
                <td >{product.certification_job_name}</td>
              </tr>
              <tr>
                <td>Manufacturer</td>
                <td >{product.crm_account_name}</td>
              </tr>
              <tr>
                <td>Certification Job Date</td>
                <td >{new Date(product.certification_job_date).toLocaleDateString()}</td>
              </tr>
              <tr>
                <td>Certification Job Expiration Date</td>
                <td >{new Date(product.certification_job_expiration_date).toLocaleDateString()}</td>
              </tr>
              <tr>
                <td>Certifcation Job Number</td>
                <td >{product.certification_job_number}</td>
              </tr>
              <tr>
                <td>Job Folder</td>
                <td >{product.job_folder}</td>
              </tr>
              <tr>
                <td>Submitter</td>
                <td >{product.crm_contact_name}</td>
              </tr>
              <tr>
                <td>Job Status</td>
                <td >{product.job_status}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div label="null">
          <table className="project-detail-table">
            {/* <tbody> */}
              {/* <tr>
                <td>Glazing Name</td>
                <td >{profileType === "Submitter" ? <input className="project-detail-input" name="glazing_names" value={product.glazing_names} />:<input className="project-detail-input" disabled value={product.glazing_names} />}</td>
              </tr>
              <tr>
                <td>Number of Panes</td>
                <td >{profileType === "Submitter" ? <input className="project-detail-input" name="number_of_panes" value={product.number_of_panes} /> :<input className="project-detail-input" disabled value={product.number_of_panes} />}</td>
              </tr>
              <tr>
                <td>Glazing Overall Thickness</td>
                <td >{profileType === "Submitter" ? <input className="project-detail-input" name="glazing_overall_thickness" value={product.glazing_overall_thickness} />:<input className="project-detail-input" disabled value={product.glazing_overall_thickness} />}</td>
              </tr>
              <tr>
                <td>Spacer Manufacturer</td>
                <td >{profileType === "Submitter" ? <input className="project-detail-input" name="spacer_manufacturer" value={product.spacer_manufacturer} />:<input className="project-detail-input" disabled value={product.spacer_manufacturer} />}</td>
              </tr>
              <tr>
                <td>Spacer Model</td>
                <td >{profileType === "Submitter" ? <input className="project-detail-input" name="spacer_model" value={product.spacer_model} /> :<input className="project-detail-input" disabled value={product.spacer_model} />}</td>
              </tr>
              <tr>
                <td>Gas Fill</td>
                <td >{profileType === "Submitter" ? <input className="project-detail-input" name="gas_fill" value={product.gas_fill} />:<input className="project-detail-input" disabled value={product.gas_fill} />}</td>
              </tr>
            </tbody> */}
          </table>
        </div>
      </Tabs>
    </form>)
}
