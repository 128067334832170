import React, {useEffect,useState} from 'react';
import { Link,useHistory,useParams } from 'react-router-dom'
import ResetPasswordForm from '../../components/resetPasswordForm/ResetPasswordForm'
import UserService from '../../services/UserService';
// import './loginPage.css'

export default function ResetPasswordPage() {
  const [email,setEmail] = useState("");
  const history = useHistory()
  const handleResetSuccess = () => {
    history.push('/');
    // or push success modal, prompt close or add another?
  };
  const { hash } = useParams()

  useEffect(()=>{
    // grab hash from params, send to node, if node returns email, render page, else, redirect
    UserService.decryptUser(hash).then(email =>{
      // if ok
      console.log(email)
      setEmail(email.Email)
    })
    .catch(e =>{
      // if email does not exist in db/crm, redirect out of registration page
      console.log("reset error: ",e)
      // history.push('/');
    })
  },[hash])

  return (
    <section className="detailCardContainer card-container-projectapp flexbox-column login-form-card">
    <div className="card-header-grey width-100">
      <p className="card-header-title-style-gray">Enter a new password</p>
    </div>
    <ResetPasswordForm email={email} onResetSuccess={handleResetSuccess} />
    <Link to="/" className="cta-button2 remove-text-decoration margin-1em">BACK</Link>
  </section>
  )
}

