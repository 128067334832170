// import dummy from '../dummyData/dummy.json'
import TokenService from '../services/token-service'
import config from '../config'

const ProductService = {
  // see user service for REST API Express function examples
  getAllProducts(){
    return fetch(`${config.API_ENDPOINT}/certified_products`, {
      method:'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  getMyProducts(){
    return fetch(`${config.API_ENDPOINT}/certified_products/myproducts`, {
      method:'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  getProductByID(id){
    return fetch(`${config.API_ENDPOINT}/certified_products/${id}`, {
      method:'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  getProductsByKeyword(keyword){
    return fetch(`${config.API_ENDPOINT}/certified_products/search/${keyword}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
    // const products = dummy.products;
    // return products.filter(obj => Object.keys(obj).some(key => obj[key].toString().toLowerCase().includes(keyword.toLowerCase())));
  },
  async updateProduct(product){
    // const proj = dummy.projects.find(proj => proj.id === Number(id))
    // // console.log(proj)
    // return {"project":proj}
    return fetch(`${config.API_ENDPOINT}/zoho/update/crm/certified_products`, {
      method:'PATCH',
      body:JSON.stringify(product),
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
}

export default ProductService;