import React, { useContext, Fragment } from 'react';
import { Link } from 'react-router-dom'
import UserContext from '../../contexts/UserContext'

const ProfessionalCertStatus = () => {
  const userContext = useContext(UserContext);
  let certificationDisplay = (user) => {
    if (user.profile_type === "Professional") {
      return (<Fragment>
        <div className="card-header-gray display-flex">
          <p className="card-header-title-style-gray">Certification Status</p>
          <Link className="remove-text-decoration card-header-title-style-gray" to={`/profile/${user.user_id}`}>View more</Link>
        </div>
        <div className="card-body">
          {/* would need to show continuing education units, and expiration date */}
          <p className="body-text">You're Certification Expires on 6/21/21.</p>
          <p className="body-text">You are currently in X Training, with 3 CE Units till completion.</p>
          <Link to="/events">
            <button className="cta-button2" type="button">Find Training</button>
          </Link>
        </div>
      </Fragment>
      )
    }
    else {
      return (<Fragment>
        <div className="card-header-gray display-flex">
          <p className="card-header-title-style-gray">Certification Status</p>
          <Link to="/events" className="remove-text-decoration card-header-title-style-gray">View More</Link>
        </div>
        <div className="card-body">
          <p className="body-text">You are currently not certified.  Get certified now by clicking the link below.</p>
          <Link to="/events">
            <button className="cta-button2" type="button">Get Certified</button>
          </Link>
        </div>
      </Fragment>
      )
    }
  }
  return (
    <div>
      {userContext.currentUser !== undefined ? certificationDisplay(userContext.currentUser) : null}
    </div>
  );
}

export default ProfessionalCertStatus;
