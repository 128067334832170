import React, { useState, useEffect, useContext } from 'react'
// import { Link } from 'react-router-dom'
import EventService from '../../services/EventService'
import UserContext from '../../contexts/UserContext'

export default function ContinuingEducation(props) {
  const [events, setEvents] = useState();
  const userContext = useContext(UserContext)
  useEffect(() => {
    console.log(userContext.currentUser)
    EventService.getAllCEUs()
      .then(evts => {
        // console.log(evts)
        let eventDivs = evts.ceus.filter(evnt => userContext.currentUser.crm_contact_id == evnt.crm_contact_id).map(event => {
          console.log(event)
          return (
            // <div className="training-and-events-container margin-bottom" key={event.id}>
              <div key={event.id} className="ceus-headers bolder-font margin-bottom">
                {/* CEU Name */}
                <div>{event.ceu_name}</div>
                {/* Number of Credits */}
                <div>{event.number_of_credits}</div>
                {/* Number of Creidts */}
                <div >{event.number_of_credits}</div>
                {/* Date Earned */}
                <div>{new Date(event.date_earned).toLocaleDateString()}</div>
                {/* Session Code */}
                <div>{event.session_code}</div>
                {/* old format */}
                {/* <div className="ceus-headers border-bottom-div-gray">
                  <h3 className="bolder-font">{event.ceu_name}</h3>
                  <h4 className="projects-and-products-headers-text-color-grey">{new Date(event.date_earned).toLocaleDateString()}</h4>
                
                </div>
                <div className="upcoming-training-and-events-headers">
                  <div className="margin-bottom">
                    <p className="projects-and-products-headers-text-color-grey">{event.description}</p>
                  </div>
                  <div className="display-flex">
                    <a className="remove-text-decoration card-header-title-style-gray letter-spacing-button" target="_blank" rel="noopener noreferrer" href={`https://phius1.zohobackstage.com/${event.name}`}>Register</a>
                    <button className="cta-button3" type="button">Member Only Event</button>
                  </div>
                </div> */}
              </div>
            // </div>
          )
        })
        setEvents(eventDivs)
      })
  }, [])

  const renderCE = () => {
    // userContext.currentUser.crm_contact_id == event.crm_contact_id
    // if (props.profile !== true) {
    //   return (
    //     <div>
    //       <div className="card-header-orange-with-1em-padding display-flex">
    //         <p className="card-header-title-style">Continuing Education</p>
    //         <a className="remove-text-decoration card-header-title-style" href="/events">View All</a>
    //       </div>
    //       <div className="card-body overflow-container-training-and-events">
    //         {events}
    //       </div>
    //     </div>
    //   )
    // }
    // else {
      return (<section className="card-body profile-card-marg">
        {/* need to be filtered by user */}
        <div className="card-header-orange-with-1em-padding display-flex">
          <p className="card-header-title-style">My Continuing Education</p>
          <a className="remove-text-decoration card-header-title-style" href="/events">View All</a>
        </div>
        <div className="card-body overflow-container-training-and-events profile-overflow">
          <h3>Current Period</h3>
          <div className="ceus-headers border-bottom margin-bottom">
            <p className="projects-and-products-headers-text-color-grey">CEU Name</p>
            <p className="projects-and-products-headers-text-color-grey">Number of Credits</p>
            <p className="projects-and-products-headers-text-color-grey">Number of Credits</p>
            <p className="projects-and-products-headers-text-color-grey">Date Earned</p>
            <p className="projects-and-products-headers-text-color-grey">Session Code</p>
          </div>
          {events}
        </div>
      </section>
      )
    // }
  }
  return (
    <>
      {renderCE()}
    </>
  )
}
