import React, {useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserService from '../../services/UserService';
import AlertService from '../../services/AlertService'

import './AdminPage.css';

export default function AdminPage() {
  const [users,setUsers] = useState()
  const handleAlertSubmit = (e) =>{
    AlertService.postAlert(e.target.value)
  }
// hello
  useEffect(()=>{
    UserService.getAllUsers()
    .then(users =>{
      // console.log(users)
      const userList = users.users.map(user =>{
        return (
          <tr key={user.user_id} className="admin-user-row">
            <td>{user.first_name || 'John'}</td>
            <td>{user.last_name|| 'Doe'}</td>
            <td>{user.email}</td>
          </tr>
        )
      })
      setUsers(userList)
    })
  },[])

  return (
    <section className="admin-container">
      {/* alert creation form */}
      <div className="card-container-projectapp width-60 m1">
        <div className="card-header-orange-with-1em-padding">
          <h3 style={{color:'white',letterSpacing:'1px',fontWeight:'bolder'}}>NOTIFICATIONS</h3>
        </div>
        <div className="card-body flex-column">
          <div className="flexbox-row-with-space-between">
            <div className="width-100">
              <form>
                <textarea name="alert" id="alert" cols="100" rows="10" className="width-100" placeholder="Enter an alert or announcement here..." style={{padding:'0.5em'}}/>
                <div className="alert-form-bar">
                  <span className="help-text">All alerts will expire 1 week after they are created.</span>
                  <input className="cta-button width-20" type="submit" value="POST" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* user entry form */}
      <div className="card-container-projectapp width-40 m1">
        <div className="card-header-gray">
          <h3>USERS</h3>
        </div>
        <div className="card-body">
          <div className="flexbox-row-with-space-between">
            <table className="width-100 admin-user-table">
              <tbody>
              {users}
              </tbody>
            </table>
          </div>
          {/* add margin */}
          <Link to="/register"><button className="cta-button width-100 ">ADD NEW USERS</button></Link>
        </div>
      </div>
    </section>
  )
}
