import React, {useContext} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import TokenService from '../../services/token-service'
import UserService from '../../services/UserService'
import UserContext from '../../contexts/UserContext';
import './loginPage.css'

const AuthPage = () => 
{
  const userContext = useContext(UserContext)
  const { user, isAuthenticated, isLoading, loginWithRedirect} = useAuth0();
  // const handleAuthSuccess = () => 
  // {
  //   history.push('/dashboard');
  // };
  if (isLoading)
  {
    return <div>Loading ...</div>;
  }
  if (!user || !isAuthenticated) 
  {
    return loginWithRedirect();
  }
  if(isAuthenticated)
  {
    console.log("userData",user)
    UserService.postNewlogin({
      email: user.email,
      authid: user.sub,
    })
      .then((res) => 
      {
        TokenService.saveAuthToken(res.token);
        userContext.setUserId(res.token);
        userContext.setCurrentUser(res.user);
        TokenService.saveNewAuthToken(res.user.password);
      })
      .catch((res) => {
        console.log("catch",res)
      });
  }
  // const handleSubmitJwtAuth = (ev) =>
  // {
  //   UserService.postLogin({
  //       email: "kenr@zbrains.net",
  //       password: "Zbrains123!",
  //     })
  //       .then((res) => {
  //         TokenService.saveAuthToken(res.token);
  //         this.context.setUserId(res.token);
  //         this.context.setCurrentUser(res.user)
  //         this.props.onLoginSuccess();
  //         history.push('/dashboard');
  //       })
  //       .catch((res) => {
  //         console.log("catch",res)
  //         this.setState({ error: "Invalid email or password" });
  //       });
  // };
  return (
    isAuthenticated && (
      <section className="detailCardContainer card-container-projectapp flexbox-column login-form-card">
      <div className="card-header-grey width-100">
        <p className="card-header-title-style-gray">Welcome Back!</p>
      </div>
      <br></br>
      <div>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>Email : {user.email}</p>
        {/* <button className="cta-button2" onClick={handleSubmitJwtAuth}>Verify Old Credentials</button> */}
        {/* <button className="cta-button2" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
          Log Out
        </button> */}
        {localStorage.setItem('auth0useremail', user.email)}
        {/* <AuthForm onAuthSuccess={handleAuthSuccess} /> */}
      </div>
      </section>
    )
  );
}

export default AuthPage;
