import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../Utils/PrivateRoute'
import PublicOnlyRoute from '../Utils/PublicOnlyRoute'
// import AdminRoute from '../Utils/AdminRoute'
// import all router pages, navbar, topbar, footer (if applicable)
import { NotFoundPage, certifierPage, forumPage, loginPage, memberPage, professionalPage, reviewerPage, traineePage, authPage } from '../../routes/';
import SearchPage from '../../routes/searchPage/searchPage'
import MyDashboard from '../MyDashboard/MyDashboard';
import ProfilePage from '../../routes/profilePage/profilePage'
import EventsReport from '../../routes/eventsReport/eventsReport'
import ProfessionalsReport from '../../routes/professionalsReport/professionalsReport'
import ProjectsReport from '../../routes/projectsReport/projectsReport'
import ProductsReport from '../../routes/productsReport/productsReport'
import ComingSoonPage from '../../routes/comingSoonPage/comingSoonPage'
import DetailPage from '../../routes/detailPage/detailPage'
import AdminPage from '../../routes/AdminPage/AdminPage'
import RegistrationPage from '../../routes/RegistrationPage/RegistrationPage';
import certPage from '../../routes/certPage/certificationReport'
import NavBar from '../navBar/NavBar'
import TopBar from '../topBar/TopBar'
import NotificationBar from '../../components/notificationBar/NotificationBar'
// import UserService from '../../services/UserService'
import UserContext from '../../contexts/UserContext'
import RequestResetForm from '../resetPasswordForm/RequestResetForm';
import ResetPasswordPage from '../../routes/ResetPasswordPage/ResetPasswordPage';

export class App extends Component {

  static contextType = UserContext

  render() {
    // filter dashboard by user type
    let dashboardComponent = this.context.currentUser !== null ? MyDashboard : authPage
    // // direct admins to admin page, else direct to proper dashboard
    // if(this.context.currentUser.is_admin === true){
    //   // dashboardComponent = AdminPage;
    // }
    // else{
    //   if((Object.keys(this.context.currentUser).length !== 0 && this.context.currentUser.constructor === Object )&& this.context.currentUser !== undefined){
    //     console.log("app dashboard check:",this.context.currentUser)
    //     if (this.context.currentUser.profile_type === "Professional" ) {
    //       dashboardComponent = professionalPage;
    //     }
    //     else if(this.context.currentUser.profile_type === "Member"){
    //       dashboardComponent = memberPage;
    //     }
    //     else if(this.context.currentUser.profile_type === "Reviewer"){
    //       dashboardComponent = reviewerPage;
    //     }
    //     else if (this.context.currentUser.profile_type === "Certified") {
    //       dashboardComponent = certifierPage;
    //     }
    //   }
    // }
    // else direct them to trainee page
    return (
      <div className="App">
        {/* <div className="flexbox-column"> */}
          <header>
            <TopBar />
          </header>
          <main className="main-display width-100">
            {/* side navbar */}
            <NavBar />
            <section className="page">
              {/* <NotificationBar /> */}
              <Switch>
                <PublicOnlyRoute exact path='/' component={authPage} />
                <PrivateRoute path='/dashboard' component={dashboardComponent} />
                {/* <Route path='/forum' component={forumPage} /> */}
                <Route path='/search/:keyword' component={SearchPage} />
                <Route exact path='/search' component={SearchPage} />
                <PrivateRoute path='/details/project/:id' component={() => <DetailPage type={"project"} />} />
                <PrivateRoute path='/details/product/:id' component={() => <DetailPage type={"product"} />} />
                <PrivateRoute path='/details/event/:id' component={() => <DetailPage type={"event"} />} />
                <Route exact path='/projects' component={ProjectsReport} />
                <Route exact path='/products' component={ProductsReport} />
                <Route path='/professionals' component={ProfessionalsReport} />
                <PrivateRoute path='/profile/:id' component={ProfilePage} />
                <PrivateRoute path='/reviewer' component={reviewerPage} />
                <PrivateRoute path="/certification" component={certPage} />
                <Route path='/events' component={EventsReport} /> 
                {/* <Route path='/login' component={loginPage} /> */}
                <Route path='/member' component={memberPage} />
                <PrivateRoute path="/admin" component={AdminPage} />
                <Route path="/register/:hash" component={RegistrationPage} />
                {/* <Route path="request-reset" component={RequestResetForm} /> */}
                <Route path="/reset/:hash" component={ResetPasswordPage} />
                {/* <Route path='/reviewer' component={reviewerPage} /> */}
                {/* <Route path='/sponsor' component={sponsorPage} /> */}
                <Route path="/resources" component={ComingSoonPage} />
                {/* this intentionally doesn't have a path, its a catch all */}
                <Route component={NotFoundPage} />
              </Switch>
            </section>
          </main>
        </div>
      // </div>
    );
  }
}

export default App;
