import React, { Component } from 'react'
import UserContext from '../../contexts/UserContext';
import TokenService from '../../services/token-service'
import UserService from '../../services/UserService'

export class LoginForm extends Component 
{
  
  static contextType = UserContext
  static defaultProps = {
    onLoginSuccess: () => {},
  };
  state = { error: null };

  

  handleSubmitJwtAuth = (ev) => 
  {
    ev.preventDefault();
    this.setState({ error: null });
    const { email, password } = ev.target;
    UserService.postLogin({
        email: email.value,
        password: password.value,
      })
        .then((res) => {
          email.value = '';
          password.value = '';
          TokenService.saveAuthToken(res.token);
          this.context.setUserId(res.token);
          this.context.setCurrentUser(res.user)
          this.props.onLoginSuccess();
        })
        .catch((res) => {
          console.log("catch",res)
          this.setState({ error: "Invalid email or password" });
        });
  };

  render() 
  {
    const { error } = this.state;
    return (
      <form className='LoginForm' onSubmit={this.handleSubmitJwtAuth}>
        <div role='alert'>
          {error && <p className='login-error'>{error}</p>}
        </div>
        <div className='email flexbox-column'>
          <label className="login-form-label-left login-label" htmlFor='LoginForm__email'>Email</label>{' '}
          <input
            type='text'
            required
            name='email'
            id='LoginForm__email'
          ></input>
        </div>

        <div className='password flexbox-column'>
          <label className="login-form-label-left login-label" htmlFor='LoginForm__password'>Password</label>{' '}
          <input
            required
            name='password'
            type='password'
            id='LoginForm__password'
          ></input>
        </div>
        <button className="cta-button" type='submit'>LOGIN</button>
      </form>
    );
  }
}

export default LoginForm
