import React, { Component } from 'react';
import UserContext from '../../contexts/UserContext';
import TokenService from '../../services/token-service';
import UserService from '../../services/UserService';

export class RequestResetForm extends Component {
  static contextType = UserContext;
  // static defaultProps = {
  //   onLoginSuccess: () => {},
  // };
  state = { resp: null };

  // Handles JWT authentication for loginform credentials
  handleSubmitReset = (ev) => {
    ev.preventDefault();
    this.setState({ error: null });
    const { email } = ev.target;
    console.log(email.value);
    // need to make this function
    if (email !== null) {
      UserService.resetPassword({
        email: email.value,
      })
        // test function, delete when hooked up to node backend
        // UserService.postLogin({
        //     email: email.value,
        //     password: password.value,
        //   })
        .then((res) => {
          console.log('then', res);
          email.value = '';
          // password.value = '';
          // const authToken = TokenService.makeBasicAuthToken(res.email, res.email)
          // TokenService.saveAuthToken(res.token);
          // this.context.setUserId(res.token);
          // this.context.setCurrentUser(res.user)
          // this.props.onLoginSuccess();
          this.setState({
            resp: 'Check your email for instructions to reset your password.',
          });
        })
        .catch((res) => {
          console.log('catch', res);
          this.setState({ resp: 'Invalid email' });
        });
    }
  };

  render() {
    const { error } = this.state;
    return (
      <>
        <form className='LoginForm' onSubmit={this.handleSubmitReset}>
          <div role='alert'>
            {error && <p className='login-error'>{error}</p>}
          </div>
          <div className='email flexbox-column'>
            <label
              className='login-form-label-left login-label'
              htmlFor='LoginForm__email'
            >
              Email
            </label>{' '}
            <input
              type='text'
              required
              name='email'
              id='LoginForm__email'
            ></input>
          </div>

          <button className='cta-button' type='submit'>
            Reset
          </button>
        </form>
        <div
          style={{
            color: 'red',
            fontSize: '14px',
            marginBottom: '25px',
            marginTop: '10px',
          }}
        >
          {this.state.resp ? this.state.resp : null}
        </div>
      </>
    );
  }
}

export default RequestResetForm;
