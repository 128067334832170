import React, {Fragment, useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
import EventService from '../../services/EventService'

export default function Events() {
  const [events, setEvents] = useState();

  useEffect(() => {
    EventService.getAllEvents()
      .then(evts => {
        // console.log(evts)
        let eventDivs = evts.events.map(event => {
          const eventDate = new Date(event.start_date)
          const today = new Date()
          // console.log(Math.round(Math.abs(today - eventDate) / (1000 * 60 * 60 * 24)) < 90)
          return (today < eventDate && Math.round(Math.abs(today - eventDate) / (1000 * 60 * 60 * 24)) < 90) ? (<Fragment key={event.id}>
            <div className="training-and-events-container margin-bottom">
              <div className="upcoming-training-and-events-headers border-bottom-div-gray">
                <h3 className="bolder-font">{event.name}</h3>
                <h4 className="projects-and-products-headers-text-color-grey">{eventDate.toLocaleDateString()}</h4>
              </div>
              <div className="upcoming-training-and-events-headers">
                <div className="margin-bottom">
                  <p className="projects-and-products-headers-text-color-grey">{event.description}</p>
                </div>
                <div className="display-flex">
                  <a className="remove-text-decoration card-header-title-style-gray letter-spacing-button" target="_blank" rel="noopener noreferrer" href={`https://phius1.zohobackstage.com/${event.name}`}>Register</a>
                  <button className="cta-button3" type="button">Member Only Event</button>
                </div>
              </div>
            </div>
          </Fragment>
          ) : null
        })
        setEvents(eventDivs)
      })
  }, [])

  return (
    <div className="card-container">
      <div className="card-header-grey">
        <p className="card-header-title-style-gray">Upcoming Training & Events</p>
      </div>
      <div className="card-body overflow-container-training-and-events">
        {events}
      </div>
    </div>
  )
}
