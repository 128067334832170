import React, { useContext } from 'react';
// import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../images/Phius-Logo.png';

const NavBar = () => {
  const userContext = useContext(UserContext);

  const navBarDisplay = (user) => {
    // console.log("Nav bar member type:",memberType)
    let memberType = user.profile_type;
    if (memberType === 'Submitter') {
      // My Dashboard X, Become a member X, Events X, My Projects & Products X, Resources & Guidebooks X, Contact Phius X
      return (
        <ul className='nav-items'>
          <li>
            <NavLink to='/dashboard'>
              <FontAwesomeIcon icon='home' /> Dashboard
            </NavLink>
          </li>
          {/* <li><NavLink to="/signup"><FontAwesomeIcon icon='users' /> Become a Member</NavLink></li> */}
          <li>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.phius.org/alliance/membership'
            >
              <FontAwesomeIcon icon='users' /> Become a Member
            </a>
          </li>
          <li>
            <NavLink to='/events'>
              <FontAwesomeIcon icon='calendar' /> Events
            </NavLink>
          </li>
          {/* <li><NavLink to="/projects"><FontAwesomeIcon icon='calendar'  /> Project/Product Database</NavLink></li> */}
          <li>
            <NavLink to='/reviewer'>
              <FontAwesomeIcon icon='box' /> My Projects & Products
            </NavLink>
          </li>
          <li>
            <NavLink to='/resources'>
              <FontAwesomeIcon icon='folder' /> Resources & Guidebooks
            </NavLink>
          </li>
          {/* <li><NavLink to="/certification"><FontAwesomeIcon icon='trophy'  /> Professional Certification Status</NavLink></li> */}
          <li>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://forms.zohopublic.com/phius/form/ContactPhius/formperma/_u3NnJ8_l4Bcia3a4bHvPvSJQU7OKKtq-LLaVhyKeuM'
            >
              <FontAwesomeIcon icon='users' /> Contact Phius
            </a>
          </li>
        </ul>
      );
    } else if (
      memberType === 'Certified Professional' ||
      memberType === 'Trainee'
    ) {
      // My Dashboard X, Become a member X, Events X, My Projects & Products X , Resources & Guidebooks X, My Professional Certification Status X, Contact Phius X
      return (
        <ul className='nav-items'>
          <li>
            <NavLink to='/dashboard'>
              <FontAwesomeIcon icon='home' /> Dashboard
            </NavLink>
          </li>
          {/* <li>
            <NavLink to='/signup'>
              <FontAwesomeIcon icon='users' /> Become a Member
            </NavLink>
          </li> */}
          <li>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.phius.org/alliance/membership'
            >
              <FontAwesomeIcon icon='users' /> Become a Member
            </a>
          </li>
          <li>
            <NavLink to='/events'>
              <FontAwesomeIcon icon='calendar' /> Events
            </NavLink>
          </li>
          {/* <li><NavLink to="/projects"><FontAwesomeIcon icon='calendar'  /> Project/Product Database</NavLink></li> */}
          <li>
            <NavLink to='/reviewer'>
              <FontAwesomeIcon icon='box' /> My Projects & Products
            </NavLink>
          </li>
          <li>
            <NavLink to='/resources'>
              <FontAwesomeIcon icon='folder' /> Resources & Guidebooks
            </NavLink>
          </li>
          {/* <li><NavLink to="/https://forms.zohopublic.com/phius/form/WebinarCaseStudy/formperma/UhWPOeG4cgfzQIqCdXMybKxyJq2lEGjq1kesV7cWfJI"><FontAwesomeIcon icon='folder' /> Submit a Resource</NavLink></li> */}
          {/* <li><NavLink to={`/profile/${user.user_id}`}><FontAwesomeIcon icon='user-edit'/> Your Membership</NavLink></li> */}
          <li>
            <NavLink to='/certification'>
              <FontAwesomeIcon icon='award' /> My Professional Certification
              Status
            </NavLink>
          </li>
          <li>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://forms.zohopublic.com/phius/form/ContactPhius/formperma/_u3NnJ8_l4Bcia3a4bHvPvSJQU7OKKtq-LLaVhyKeuM'
            >
              <FontAwesomeIcon icon='users' /> Contact Phius
            </a>
          </li>
        </ul>
      );
    } else if (memberType === 'Reviewer') {
      // My Dashboard, My Projects & Products, Resources & Guidebooks, Contact Phius
      return (
        <ul className='nav-items'>
          <li>
            <NavLink to='/dashboard'>
              <FontAwesomeIcon icon='home' /> Dashboard
            </NavLink>
          </li>
          {/* <li><NavLink to="/events"><FontAwesomeIcon icon='calendar'  /> Events</NavLink></li> */}
          {/* <li><NavLink to="/projects"><FontAwesomeIcon icon='calendar'  /> Project/Product Database</NavLink></li> */}
          <li>
            <NavLink to='/reviewer'>
              <FontAwesomeIcon icon='box' /> My Projects & Products
            </NavLink>
          </li>
          <li>
            <NavLink to='/resources'>
              <FontAwesomeIcon icon='folder' /> Resources & Guidebooks
            </NavLink>
          </li>
          {/* <li><NavLink to="/https://forms.zohopublic.com/phius/form/WebinarCaseStudy/formperma/UhWPOeG4cgfzQIqCdXMybKxyJq2lEGjq1kesV7cWfJI"><FontAwesomeIcon icon='folder' /> Submit a Resource</NavLink></li> */}
          {/* <li><NavLink to={`/profile/${user.user_id}`}><FontAwesomeIcon icon='user-edit'/> Your Membership</NavLink></li> */}
          {/* <li><NavLink to="/certification"><FontAwesomeIcon icon='award' /> Professional Certification Status</NavLink></li> */}
          <li>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://forms.zohopublic.com/phius/form/ContactPhius/formperma/_u3NnJ8_l4Bcia3a4bHvPvSJQU7OKKtq-LLaVhyKeuM'
            >
              <FontAwesomeIcon icon='users' /> Contact Phius
            </a>
          </li>
        </ul>
      );
    } else if (memberType === 'Member') {
      // X My Dashboard, X Events, X My Projects & Products, X Resources & Guidebooks, X My Professional Certification Status, X Contact Phius
      return (
        <ul className='nav-items'>
          <li>
            <NavLink to='/dashboard'>
              <FontAwesomeIcon icon='home' /> Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to='/events'>
              <FontAwesomeIcon icon='calendar' /> Events
            </NavLink>
          </li>
          <li>
            <NavLink to='/reviewer'>
              <FontAwesomeIcon icon='box' /> My Projects & Products
            </NavLink>
          </li>
          <li>
            <NavLink to='/resources'>
              <FontAwesomeIcon icon='folder' /> Resources & Guidebooks
            </NavLink>
          </li>
          {/* <li><NavLink to="/projects"><FontAwesomeIcon icon='calendar'  /> Project/Product Database</NavLink></li> */}
          {/* <li><NavLink to="/signup"><FontAwesomeIcon icon='users' /> Become a Member</NavLink></li> */}
          <li>
            <NavLink to='/certification'>
              <FontAwesomeIcon icon='trophy' /> My Professional Certification
              Status
            </NavLink>
          </li>
          <li>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://forms.zohopublic.com/phius/form/ContactPhius/formperma/_u3NnJ8_l4Bcia3a4bHvPvSJQU7OKKtq-LLaVhyKeuM'
            >
              <FontAwesomeIcon icon='users' /> Contact Phius
            </a>
          </li>
        </ul>
      );
    } else {
      // X Become a member, X Events, X Contact Phius.
      return (
        <ul className='nav-items'>
          {/* <li><NavLink to="/dashboard"><FontAwesomeIcon icon='home'  /> Dashboard</NavLink></li> */}
          {/* <li>
            <NavLink to='/signup'>
              <FontAwesomeIcon icon='users' /> Become a Member
            </NavLink>
          </li> */}
          <li>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.phius.org/alliance/membership'
            >
              <FontAwesomeIcon icon='users' /> Become a Member
            </a>
          </li>
          <li>
            <NavLink to='/events'>
              <FontAwesomeIcon icon='calendar' /> Events
            </NavLink>
          </li>
          {/* <li><NavLink to="/projects"><FontAwesomeIcon icon='calendar'  /> Project/Product Database</NavLink></li> */}
          {/* <li><NavLink to="/certification"><FontAwesomeIcon icon='trophy'  /> Professional Certification Status</NavLink></li> */}
          <li>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://forms.zohopublic.com/phius/form/ContactPhius/formperma/_u3NnJ8_l4Bcia3a4bHvPvSJQU7OKKtq-LLaVhyKeuM'
            >
              <FontAwesomeIcon icon='users' /> Contact Phius
            </a>
          </li>
          {/* <li><NavLink to="/admin"><FontAwesomeIcon icon='users' /> Admin Test</NavLink></li> */}
        </ul>
      );
    }
  };
  return (
    <div className='navbar-container'>
      <div className='header-logo-container header-align-content flex-center'>
        <h3 className='header-align-content flex-center header-logo-container'>
          <NavLink className='header-logo header-align-content' to='/'>
            <img src={logo} alt='logo' />
          </NavLink>
        </h3>
      </div>
      {userContext.currentUser !== undefined
        ? navBarDisplay(userContext.currentUser)
        : navBarDisplay('other')}
    </div>
  );
};

export default NavBar;
