import React, { Component } from 'react'
import Events from "../../components/events/Events";
import ManageProject from "../../components/manageProject/ManageProject";
import ProjectForm from "../../components/projectForm/ProjectForm";
import ProfessionalCertStatus from "../../components/professionalCertStatus/ProfessionalCertStatus";
import AskCommunity from "../../components/askCommunity/AskCommunity";
import GuideBook from "../../components/guidebook/GuideBook";
// import NotificationBar from "../../components/notificationBar/NotificationBar";

export class memberPage extends Component {
  render() {
    return (
      <div className="flexbox-column">
    
        <div className="flexbox-column" >
         
          <div className="flexbox-row-with-space-between">
            <div className="width-100">
              <Events/>
            </div>

            <div className="flexbox-column width-100 margin-left">
              <ManageProject/>
              <ProjectForm/>  
            </div>
          </div>

          <div className="space"></div>

          <div className="flexbox-row-with-space-between">
            <div className="width-100">
              <ProfessionalCertStatus/>
            </div>
              
            <div className="flexbox-row-with-space-between width-100 margin-left">
              <div className="width-100">
                <GuideBook/>
              </div>
              <div className="width-100 margin-left">
                <AskCommunity/>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    )
  }
}

export default memberPage
