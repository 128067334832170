import React, { useContext, useState, useEffect } from 'react';
import Select from 'react-select';
import UserContext from '../../contexts/UserContext';
import ProjectService from '../../services/ProjectService';
import UserService from '../../services/UserService';
import Tabs from './Tabs';
import './ProjectDetail.css';

export default function ProjectDetail(props) {
  const [profileType, setProfileType] = useState();
  const [project, setProject] = useState();
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editable, toggleEdit] = useState(false);
  const [policies, setPolicies] = useState([]);
  // const [renderSave, updateRenderSave] = useState(false)
  const userContext = useContext(UserContext);
  // const project = props.project

  useEffect(() => {
    setProfileType(userContext.currentUser.profile_type);
    setProject(props.project);
    setLoading(false);
    UserService.getAllContacts().then((contacts) =>
      setContacts(contacts.contacts)
    );
    console.log(project);
    ProjectService.getAllPolicies().then((policies) => {
      console.log('policies:', policies);
      setPolicies(policies.policies);
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, ' : ', value);
    setProject({
      ...project,
      [name]: value,
    });
  };
  const handleSelectChange = (name, o) => {
    // console.log(o)
    // console.log(name," : ",o)
    // const name = o[0].name
    // const newOptions = o.map(option => delete o.name)
    console.log('O: ', o);
    // const name = o[0].name
    const newOptions = o.map((option) => option.value);
    console.log(name, ' : ', newOptions);
    setProject({
      ...project,
      [name]: newOptions,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // let subform = Object.keys(project).map(key => {
    //   const obj = {};
    //   // lead_CPHC_contact_crm_id
    //   if (key === "lead_CPHC_contact_crm_id" && project[key] !== undefined) {
    //     obj[key] = project[key]
    //     obj['lead_CPHC_subform_id'] = project['lead_CPHC_subform_id']
    //   }
    //   // CPHB_contact_crm_id
    //   else if (key === "CPHB_contact_crm_id" && project[key] !== undefined) {
    //     obj[key] = project[key]
    //     obj['CPHB_subform_id'] = project['CPHB_subform_id']
    //   }
    //   // builder_contact_crm_id
    //   else if (key === "builder_contact_crm_id" && project[key] !== undefined) {
    //     obj[key] = project[key]
    //     obj['builder_subform_id'] = project['builder_subform_id']
    //   }
    //   // phius_certified_rater_contact_crm_id
    //   else if (key === "phius_certified_rater_contact_crm_id" && project[key] !== undefined) {
    //     obj[key] = project[key]
    //     obj['phius_certified_rater_subform_id'] = project['phius_certified_rater_subform_id']
    //   }
    //   // phius_certified_verifier_contact_crm_id
    //   else if (key === "phius_certified_verifier_contact_crm_id" && project[key] !== undefined) {
    //     obj[key] = project[key]
    //     obj['phius_certified_verifier_subform_id'] = project['phius_certified_verifier_subform_id']
    //   }
    //   // primary_reviewer_contact_crm_id
    //   else if (key === "primary_reviewer_contact_crm_id" && project[key] !== undefined) {
    //     obj[key] = project[key]
    //     obj['primary_reviewer_subform_id'] = project['primary_reviewer_subform_id']
    //   }
    //   // project_submitter_contact_crm_id
    //   else if (key === "project_submitter_contact_crm_id" && project[key] !== undefined) {
    //     obj[key] = project[key]
    //     obj['project_submitter_subform_id'] = project['project_submitter_subform_id']
    //   }
    //   // secondary_CPHC_contact_crm_id
    //   else if (key === "secondary_CPHC_contact_crm_id" && project[key] !== undefined) {
    //     obj[key] = project[key]
    //     obj['secondary_CPHC_subform_id'] = project['secondary_CPHC_subform_id']
    //   }
    //   // secondary_reviewer_contact_crm_id
    //   else if (key === "secondary_reviewer_contact_crm_id" && project[key] !== undefined) {
    //     obj[key] = project[key]
    //     obj['secondary_reviewer_subform_id'] = project['secondary_reviewer_subform_id']
    //   }
    //   if (Object.keys(obj).length > 0) {
    //     return obj
    //   }
    //   else return undefined
    // })
    // subform = subform.filter(val => val !== undefined)
    // console.log(subform)

    // let updateProject = {}
    // if(typeof project.policy_type === 'number' && isFinite(project.policy_type)){
    // let policyUpdate = {'policy_type_crm_id':project.policy_type}
    const updateProject = { ...project };
    // }else{
    //   updateProject = {...project}
    // }
    console.log(updateProject);
    // console.log(e)
    ProjectService.updateProject(updateProject).then((res) => {
      toggleEdit(false);
      console.log('RES: ', res);
      console.log('PROJECT', project);
      console.log('POLICIES', policies);
      const updatedPolicy =
        project.policy_type_crm_id !== null
          ? policies.find((p) => p.crm_id === project.policy_type_crm_id)
          : { policy_title: project.policy_type };
      console.log('updatedpolicy', updatedPolicy);
      setProject({ ...project, policy_type: updatedPolicy.policy_title });
      // setProject(res.project)
    });
  };

  const handleToggle = () => {
    toggleEdit(true);
  };

  // const submitButton = editable===true ? <input type="submit" className="cta-button" value="Update" /> : null

  if (loading) {
    return (
      <section style={{ position: 'relative', top: '50%', left: '50%' }}>
        {/* loading user */}
        <div className='lds-grid'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>
    );
  }

  let secondaryCPHCContacts =
    contacts.length > 0
      ? contacts.map((contact) => {
          return contact.crm_id === project.secondary_CPHC_contact_crm_id ? (
            <option key={contact.crm_id} selected value={contact.crm_id}>
              {contact.first_name} {contact.last_name}
            </option>
          ) : (
            <option key={contact.id} value={contact.crm_id}>
              {contact.first_name} {contact.last_name}
            </option>
          );
        })
      : [];
  let CPHBContacts =
    contacts.length > 0
      ? contacts.map((contact) => {
          return contact.crm_id === project.CPHB_contact_crm_id ? (
            <option key={contact.crm_id} selected value={contact.crm_id}>
              {contact.first_name} {contact.last_name}
            </option>
          ) : (
            <option key={contact.id} value={contact.crm_id}>
              {contact.first_name} {contact.last_name}
            </option>
          );
        })
      : [];
  // let leadCPHCContacts = contacts.length > 0 ? contacts.map(contact => {
  //   return contact.crm_id === project.secondary_CPHC_contact_crm_id ? <option key={contact.crm_id} selected value={contact.crm_id}>{contact.first_name} {contact.last_name}</option> : <option key={contact.id} value={contact.crm_id}>{contact.first_name} {contact.last_name}</option>
  // }) : null
  // let certifiedVerifierContacts = contacts.length > 0 ? contacts.map(contact => {
  //   return contact.crm_id === project.phius_certified_verifier_contact_crm_id ? <option key={contact.crm_id} selected value={contact.crm_id}>{contact.first_name} {contact.last_name}</option> : <option key={contact.id} value={contact.crm_id}>{contact.first_name} {contact.last_name}</option>
  // }) : null
  let certifiedRaterContacts =
    contacts.length > 0
      ? contacts.map((contact) => {
          return contact.crm_id ===
            project.phius_certified_rater_contact_crm_id ? (
            <option key={contact.crm_id} selected value={contact.crm_id}>
              {contact.first_name} {contact.last_name}
            </option>
          ) : (
            <option key={contact.id} value={contact.crm_id}>
              {contact.first_name} {contact.last_name}
            </option>
          );
        })
      : [];
  // let primaryReviewerContacts = contacts.length > 0 ? contacts.map(contact => {
  //   return contact.crm_id === project.primary_reviewer_contact_crm_id ? <option key={contact.crm_id} selected value={contact.crm_id}>{contact.first_name} {contact.last_name}</option> : <option key={contact.id} value={contact.crm_id}>{contact.first_name} {contact.last_name}</option>
  // }) : null
  // let projectSubmitterContacts = contacts.length > 0 ? contacts.map(contact => {
  //   return contact.crm_id === project.project_submitter_contact_crm_id ? <option key={contact.crm_id} selected value={contact.crm_id}>{contact.first_name} {contact.last_name}</option> : <option key={contact.id} value={contact.crm_id}>{contact.first_name} {contact.last_name}</option>
  // }) : null
  let builderContacts =
    contacts.length > 0
      ? contacts.map((contact) => {
          return contact.crm_id === project.builder_contact_crm_id ? (
            <option key={contact.crm_id} selected value={contact.crm_id}>
              {contact.first_name} {contact.last_name}
            </option>
          ) : (
            <option key={contact.id} value={contact.crm_id}>
              {contact.first_name} {contact.last_name}
            </option>
          );
        })
      : [];
  // let secondaryReviewerContacts = contacts.length > 0 ? contacts.map(contact => {
  //   return contact.crm_id === project.secondary_reviewer_contact_crm_id ? <option key={contact.crm_id} selected value={contact.crm_id}>{contact.first_name} {contact.last_name}</option> : <option key={contact.id} value={contact.crm_id}>{contact.first_name} {contact.last_name}</option>
  // }) : null

  // let policyTypes = contacts.length > 0 ? contacts.map(contact => {
  //   return contact.crm_id === project.builder_contact_crm_id ? <option key={contact.crm_id} selected value={contact.crm_id}>{contact.first_name} {contact.last_name}</option> : <option key={contact.id} value={contact.crm_id}>{contact.first_name} {contact.last_name}</option>
  // }) : []

  const defaultPolicyType =
    policies.length > 0
      ? policies.find((p) => p.policy_title === project.policy_type)
      : '';
  let policyMap =
    policies.length > 0 ? (
      policies?.map((p) => {
        console.log('policy:', p);
        return (
          <option key={p.crm_id} value={p.crm_id}>
            {p.policy_title}
          </option>
        );
      })
    ) : (
      <option value={project.policy_type}>{project.policy_type}</option>
    );

  // console.log(profileType)
  // console.log(contacts)
  if (project.secondary_CPHC_contact_crm_id === null) {
    secondaryCPHCContacts.push(
      <option key='0' selected value={' '}>
        {' '}
        -{' '}
      </option>
    );
  }
  if (project.CPHB_contact_crm_id === null) {
    CPHBContacts.push(
      <option key='0' selected value={' '}>
        {' '}
        -{' '}
      </option>
    );
  }
  if (project.phius_certified_rater_contact_crm_id === null) {
    certifiedRaterContacts.push(
      <option key='0' selected value={' '}>
        {' '}
        -{' '}
      </option>
    );
  }
  if (project.builder_contact_crm_id === null) {
    builderContacts.push(
      <option key='0' selected value={' '}>
        {' '}
        -{' '}
      </option>
    );
  }
  return (
    <form className='detailCard-project' onSubmit={(e) => handleSubmit(e)}>
      <div className='detailProjectHeader alignCenter width-100'>
        <div className='width-20 flex-space'>
          <h3 className='bolder'>{project.project_number}</h3>
          <p>-</p>
          <h3 className='bolder'>
            {editable === true ? (
              <input
                name='project_name'
                onChange={(e) => handleInputChange(e)}
                className='edit-input bolder'
                value={project.project_name}
              />
            ) : (
              project.project_name
            )}
          </h3>
        </div>
        {/* <input type="submit" value="Update" className="cta-button"/> */}
        <div className='flex-end width-70'>
          {editable === false ? (
            <button
              onClick={() => handleToggle()}
              className='cta-button2 remove-text-decoration'
            >
              EDIT PROJECT
            </button>
          ) : (
            <input
              type='submit'
              className='cta-button remove-text-decoration'
              value='SAVE'
            />
          )}
        </div>
        {/* {submitButton} */}
      </div>
      {/* <div className="fourTables"> */}
      <Tabs>
        {/* <img className="detailImg" src={project.image} alt={project.title} /> */}
        <div label='Phius Project Data'>
          <table className='project-detail-table'>
            <tbody>
              <tr>
                <td>Project Submitter</td>
                <td>{project.project_submitter_contact_name}</td>
              </tr>
              <tr>
                <td>Primary Project Reviewer</td>
                <td>{project.primary_reviewer_contact_name}</td>
              </tr>
              <tr>
                <td>Secondary Project Reviewer</td>
                <td>{project.secondary_reviewer_contact_name}</td>
              </tr>
              <tr>
                <td>Lead CPHC</td>
                <td>{project.lead_CPHC_contact_name}</td>
              </tr>
              <tr>
                <td>Secondary CPHC</td>
                <td>{project.secondary_CPHC_contact_name}</td>
              </tr>
              <tr>
                <td>CPHB (Builder)</td>
                <td>{project.CPHB_contact_name}</td>
                {/* <td >{editable===true ? <input name="CPHB_contact_name" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.CPHB_contact_name} /> : <input name="CPHB_contact_name" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.CPHB_contact_name} />}</td> */}
              </tr>
              <tr>
                <td>Builder</td>
                <td>{project.builder_contact_name}</td>
                {/* <td >{editable===true ? <input name="builder_contact_name" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.builder_contact_name} /> : <input name="builder_contact_name" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.builder_contact_name} />}</td> */}
              </tr>
              <tr>
                <td>Phius Certified Rater or Verifier</td>
                <td>{project.phius_certified_rater_contact_name} </td>
                {/* <td >{editable===true ? <input name="phius_certified_rater_contact_name" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.phius_certified_rater_contact_name} /> : <input name="phius_certified_rater_contact_name" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.phius_certified_rater_contact_name} />}</td> */}
              </tr>
              <tr>
                <td>Status</td>
                <td>{project.crm_status}</td>
              </tr>
              <tr>
                <td>Certification Contract Date</td>
                <td>
                  {new Date(
                    project.certification_contract_date
                  ).toLocaleDateString()}
                </td>
              </tr>
              <tr>
                <td>Design Certification Date</td>
                <td>
                  {new Date(
                    project.design_certification_date
                  ).toLocaleDateString()}
                </td>
              </tr>
              <tr>
                <td>Final Certification Date</td>
                <td>
                  {new Date(
                    project.final_certification_date
                  ).toLocaleDateString()}
                </td>
              </tr>
              {/* move: annual heating demand */}
              <tr>
                <td>Annual Heating Demand</td>
                <td>
                  {project.annual_heating_demand}{' '}
                  <span className='unit-label'>kBTU/ft²yr</span>
                </td>
              </tr>
              {/* move: annual cooling demand */}
              <tr>
                <td>Annual Cooling Demand</td>
                <td>
                  {project.annual_cooling_demand}{' '}
                  <span className='unit-label'>kBTU/ft²yr</span>
                </td>
              </tr>
              {/* move: peak heating load */}
              <tr>
                <td>Peak Heating Load</td>
                <td>
                  {project.peak_heating_load}{' '}
                  <span className='unit-label'>BTU/ft²hr</span>
                </td>
              </tr>
              {/* move: peak cooling load */}
              <tr>
                <td>Peak Cooling Load</td>
                <td>
                  {project.peak_cooling_load}{' '}
                  <span className='unit-label'>BTU/ft²hr</span>
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Site Energy Use Index (EUI)</td>
                <td>
                  {project.site_energy_use_index_EUI}{' '}
                  <span className='unit-label'>kBTU/ft²yr</span>
                </td>
              </tr>
              <tr>
                <td>Site EUI (w/ Renewables)</td>
                <td>
                  {project.site_energy_use_index_w_renewables}{' '}
                  <span className='unit-label'>kBTU/ft²yr</span>
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Source Energy (Residential)</td>
                <td>
                  {Math.round(project.source_energy_residential)}{' '}
                  <span className='unit-label'>kWh/person.yr</span>
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Source Energy (Res. w/ Renewables)</td>
                <td>
                  {project.source_energy_residential_w_renewables}{' '}
                  <span className='unit-label'>kWh/person.yr</span>
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Source Energy (non-Res)</td>
                <td>
                  {project.source_energy_nonresidential}{' '}
                  <span className='unit-label'>kBTU/ft²yr</span>
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Source Energy (Non-Res w/ Renewables)</td>
                <td>
                  {project.source_energy_nonresidential_w_renewables}{' '}
                  <span className='unit-label'>kBTU/ft²yr</span>
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Air-tightness (per enclosure area)</td>
                <td>{project.air_tightness_per_enclosure_area}</td>
              </tr>
              <tr>
                {/* number */}
                <td>Air-tightness (per volume)</td>
                <td>
                  {project.air_tightness_per_volume}{' '}
                  <span className='unit-label'>ACH₅₀</span>
                </td>
              </tr>
              <tr>
                <td>Measured Ventilation Fan Efficiency</td>
                <td>
                  {project.measured_fan_efficiency}{' '}
                  <span className='unit-label'>W/CFM</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div label='Project Overview'>
          <table className='project-detail-table'>
            <tbody>
              <tr>
                <td>Private Project Name</td>
                <td>
                  {editable === true ? (
                    <input
                      name='private_project_name'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.private_project_name}
                    />
                  ) : (
                    <input
                      name='private_project_name'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.private_project_name}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Project Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='project_type'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.project_type}
                    >
                      <option value=''></option>
                      <option value='New Construction'>New Construction</option>
                      <option value='Retrofit'>Retrofit</option>
                      <option value='Addition'>Addition</option>
                    </select>
                  ) : (
                    project.project_type
                  )}
                </td>
              </tr>
              <tr>
                <td>Building Function</td>
                <td>
                  {editable === true ? (
                    <select
                      name='building_function'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.building_function}
                    >
                      <option value=''></option>
                      <option value='Single-Family'>Single-Family</option>
                      <option value='Multifamily'>Multifamily</option>
                      <option value='Mixed-Use'>Mixed-Use</option>
                      <option value='School / Education'>
                        School / Education
                      </option>
                      <option value='Office'>Office</option>
                      <option value='Government / Public'>
                        Government / Public
                      </option>
                      <option value='Hotel'>Hotel</option>
                      <option value='Dormitory'>Dormitory</option>
                      <option value='Other Non-Residential'>
                        Other Non-Residential
                      </option>
                    </select>
                  ) : (
                    project.building_function
                  )}
                </td>
              </tr>
              <tr>
                <td>Architecture Planning</td>
                <td>
                  {editable === true ? (
                    <input
                      name='architecture_planning'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.architecture_planning}
                    />
                  ) : (
                    <input
                      name='architecture_planning'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.architecture_planning}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Mechanical Systems Designer</td>
                <td>
                  {editable === true ? (
                    <input
                      name='mechanical_systems_designer'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.mechanical_systems_designer}
                    />
                  ) : (
                    <input
                      name='mechanical_systems_designer'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.mechanical_systems_designer}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Additional Consultants</td>
                <td>
                  {editable === true ? (
                    <input
                      name='additional_consultants'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.additional_consultants}
                    />
                  ) : (
                    <input
                      name='additional_consultants'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.additional_consultants}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Project Delivery Method</td>
                <td>
                  {editable === true ? (
                    <select
                      name='project_delivery_method'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.project_delivery_method}
                    >
                      <option value=''></option>
                      <option value='Design-Bid-Build (DBB)'>
                        Design-Bid-Build (DBB)
                      </option>
                      <option value='Design Build (DB)'>
                        Design Build (DB)
                      </option>
                      <option value='Construction Manager at Risk (CMAR)'>
                        Construction Manager at Risk (CMAR)
                      </option>
                      <option value='Construction Management Multi-Prime (CMMP)'>
                        Construction Management Multi-Prime (CMMP)
                      </option>
                      <option value='Integrated Project Delivery (IPD)'>
                        Integrated Project Delivery (IPD)
                      </option>
                    </select>
                  ) : (
                    project.project_delivery_method
                  )}
                </td>
              </tr>
              <tr>
                <td>Developer</td>
                <td>
                  {editable === true ? (
                    <input
                      name='developer'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.developer}
                    />
                  ) : (
                    <input
                      name='developer'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.developer}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Affiliate</td>
                <td>
                  {editable === true ? (
                    <input
                      name='affiliate'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.affiliate}
                    />
                  ) : (
                    <input
                      name='affiliate'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.affiliate}
                    />
                  )}
                </td>
              </tr>
              {project.market_category !== undefined ? (
                <tr>
                  {/* picklist */}
                  <td>Market Category</td>
                  <td>
                    {editable === true ? (
                      <select
                        name='market_category'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        defaultValue={project.market_category}
                      >
                        <option value=''></option>
                        <option value='Supportive Housing'>
                          Supportive Housing
                        </option>
                        <option value='Low Income Housing'>
                          Low Income Housing
                        </option>
                        <option value='Affordable Housing'>
                          Affordable Housing
                        </option>
                        <option value='Market Rate'>Market Rate</option>
                      </select>
                    ) : (
                      project.market_category
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              <tr>
                <td>Policy Type</td>
                {/* <td >{editable===true ? <input name="policy_type" onChange={e => handleInputChange(e)} className="edit-input" value={project.policy_type} /> : <input name="policy_type" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.policy_type} />}</td> */}
                <td>
                  {editable === true ? (
                    <select
                      name='policy_type_crm_id'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={defaultPolicyType?.crm_id}
                    >
                      {policyMap}
                    </select>
                  ) : (
                    <input
                      name='policy_type'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.policy_type}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Total Project Cost</td>
                <td>
                  ${' '}
                  {editable === true ? (
                    <input
                      type='number'
                      className='edit-input'
                      onChange={(e) => handleInputChange(e)}
                      name='total_project_cost'
                      value={project.total_project_cost}
                    />
                  ) : (
                    <input
                      className='project-detail-input'
                      disabled
                      name='total_project_cost'
                      value={project.total_project_cost}
                    />
                  )}{' '}
                  USD
                </td>
              </tr>
              <tr>
                <td>Construction Completion Year</td>
                <td>
                  {editable === true ? (
                    <input
                      name='construction_completion_year'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.construction_completion_year}
                    />
                  ) : (
                    <input
                      name='construction_completion_year'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.construction_completion_year}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* checkbox */}
                <td>All-Electric</td>
                <td>
                  {editable === true ? (
                    <input
                      type='checkbox'
                      className='edit-input'
                      name='all_electric'
                      value={project.all_electric}
                    />
                  ) : (
                    <input
                      type='checkbox'
                      className='project-detail-input'
                      disabled
                      name='all_electric'
                      value={project.all_electric}
                      checked={project.all_electric}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Monitored Data</td>
                <td>
                  {editable === true ? (
                    <input
                      type='checkbox'
                      className='edit-input'
                      name='monitored_data_available'
                      value={project.monitored_data_available}
                    />
                  ) : (
                    <input
                      type='checkbox'
                      className='project-detail-input'
                      disabled
                      name='monitored_data_available'
                      value={project.monitored_data_available}
                      checked={project.monitored_data_available}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Monitored Data Report</td>
                <td>
                  {editable === true ? (
                    <input className='edit-input' name='' value='' />
                  ) : (
                    <input
                      className='project-detail-input'
                      disabled
                      name=''
                      value=''
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Additional Notes</td>
                <td>
                  {editable === true ? (
                    <textarea
                      className='edit-input'
                      name='additional_notes'
                      value={project.additional_notes}
                    />
                  ) : (
                    <textarea
                      className='project-detail-input'
                      disabled
                      name='additional_notes'
                      value={project.additional_notes}
                    />
                  )}
                </td>
              </tr>
              {/* <tr>
                <td>Incentive/Funding Source</td>
                <td >{editable===true ? <input name="incentive_funding_source" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.incentive_funding_source} /> : <input name="incentive_funding_source" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.incentive_funding_source} />}</td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div label='Project Information'>
          <table className='project-detail-table'>
            <tbody>
              {/* picklist */}

              {/* picklist */}
              {/* <tr>
                <td>Simple Building Function</td>
                <td >{editable===true ? <select name="simple_building_function" onChange={e => handleInputChange(e)} className="project-detail-input" defaultValue={project.simple_building_function}>
                  <option value="Residential">Residential</option>
                  <option value="Non-Residential">Non-Residential</option>
                </select> : project.simple_building_function}</td>
              </tr> */}
              <tr>
                {/* picklist */}
                <td>Program Version</td>
                <td>
                  {editable === true ? (
                    <select
                      name='program_version'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.program_version}
                    >
                      <option value=''></option>
                      <option value='PHIUS+ 2012'>PHIUS+ 2012</option>
                      <option value='PHIUS+ 2015'>PHIUS+ 2015</option>
                      <option value='PHIUS+ 2015 Source Zero'>
                        PHIUS+ 2015 Source Zero
                      </option>
                      <option value='PHIUS+ 2018 CORE'>PHIUS+ 2018 CORE</option>
                      <option value='PHIUS+ 2018'>PHIUS+ 2018</option>
                      <option value='PHIUS+ 2018 Source Zero'>
                        PHIUS+ 2018 Source Zero
                      </option>
                      <option value='Phius CORE 2021'>Phius CORE 2021</option>
                      <option value='Phius ZERO 2021'>Phius ZERO 2021</option>
                      <option value='Phius CORE REVIVE 2021'>
                        Phius CORE REVIVE 2021
                      </option>
                      <option value='Phius ZERO REVIVE 2021'>
                        Phius ZERO REVIVE 2021
                      </option>
                      <option value='Phius CORE Prescriptive 2021'>
                        Phius CORE Prescriptive 2021
                      </option>
                    </select>
                  ) : (
                    project.program_version
                  )}
                </td>
              </tr>
              <tr>
                {/* multiselect */}
                <td>Construction Type</td>
                <td>
                  {editable === true ? (
                    project.construction_type !== null ||
                    project.construction_type.length !== 0 ? (
                      <Select
                        name='construction_type'
                        defaultValue={project.construction_type.map((type) => ({
                          value: type,
                          label: type,
                        }))}
                        onChange={(option) =>
                          handleSelectChange('construction_type', option)
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        disabled={!editable}
                        class={'edit-input'}
                        options={[
                          { value: 'Wood-Frame', label: 'Wood-Frame' },
                          { label: 'Heavy Timber', value: 'Heavy Timber' },
                          { label: 'Masonry', value: 'Masonry' },
                          {
                            label: 'Insulated Concrete Form',
                            value: 'Insulated Concrete Form',
                          },
                          {
                            label: 'Autoclaved aerated concrete (AAC)',
                            value: 'Autoclaved aerated concrete (AAC)',
                          },
                          { label: 'Steel', value: 'Steel' },
                        ]}
                      />
                    ) : (
                      <Select
                        name='construction_type'
                        onChange={(option) =>
                          handleSelectChange('construction_type', option)
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        options={[
                          { value: 'Wood-Frame', label: 'Wood-Frame' },
                          { label: 'Heavy Timber', value: 'Heavy Timber' },
                          { label: 'Masonry', value: 'Masonry' },
                          {
                            label: 'Insulated Concrete Form',
                            value: 'Insulated Concrete Form',
                          },
                          {
                            label: 'Autoclaved aerated concrete (AAC)',
                            value: 'Autoclaved aerated concrete (AAC)',
                          },
                          { label: 'Steel', value: 'Steel' },
                        ]}
                      />
                    )
                  ) : (
                    project.construction_type.join(', ')
                  )}
                </td>
              </tr>
              {/* <tr>
                <td>Version Description</td>
                <td >{editable===true ? <input name="ver" onChange={e => handleInputChange(e)} className="project-detail-input red" disabled value="missing field in DB" /> : <input name="ver" onChange={e => handleInputChange(e)} className="project-detail-input red" disabled value="missing field in DB" />}</td>
              </tr> */}
              <tr>
                <td>Facade Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='facade_type'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.facade_type}
                    >
                      <option value=''></option>
                      <option value='Masonry Veneer'>Masonry Veneer</option>
                      <option value='Fiber Cement'>Fiber Cement</option>
                      <option value='Metal Panels'>Metal Panels</option>
                      <option value='EIFS'>EIFS</option>
                    </select>
                  ) : (
                    <input
                      name='facade_type'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.facade_type}
                    />
                  )}
                  {/* {editable === true ? (
                    <input
                      name='facade_type'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.facade_type}
                    />
                  ) : (
                    <input
                      name='facade_type'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.facade_type}
                    />
                  )} */}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Number of Units</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='number_of_units'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.number_of_units}
                    />
                  ) : (
                    <input
                      name='number_of_units'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.number_of_units}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Number of Stories</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='number_of_stories'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.number_of_stories}
                    />
                  ) : (
                    <input
                      name='number_of_stories'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.number_of_stories}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Interior Conditioned Floor Area</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='interior_conditioned_floor_area'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.interior_conditioned_floor_area}
                    />
                  ) : (
                    <input
                      name='interior_conditioned_floor_area'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.interior_conditioned_floor_area}
                    />
                  )}{' '}
                  <span className='unit-label'>ft²</span>
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Gross Floor Area</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='gross_floor_area'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.gross_floor_area}
                    />
                  ) : (
                    <input
                      name='gross_floor_area'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.gross_floor_area}
                    />
                  )}{' '}
                  <span className='unit-label'>ft²</span>
                </td>
              </tr>
              <tr>
                <td>Street Address</td>
                <td>
                  {editable === true ? (
                    <input
                      name='street_address'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.street_address}
                    />
                  ) : (
                    <input
                      name='street_address'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.street_address}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>City</td>
                <td>
                  {editable === true ? (
                    <input
                      name='city'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.city}
                    />
                  ) : (
                    <input
                      name='city'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.city}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>State/Province</td>
                <td>
                  {editable === true ? (
                    <select
                      name='state'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.state}
                    >
                      <option value=''></option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">District of Columbia</option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                      <option value="Alberta">Alberta</option>
                      <option value="British Columbia">British Columbia</option>
                      <option value="Manitoba">Manitoba</option>
                      <option value="New Brunswick">New Brunswick</option>
                      <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                      <option value="Northwest Territories">Northwest Territories</option>
                      <option value="Nova Scotia">Nova Scotia</option>
                      <option value="Nunavut">Nunavut</option>
                      <option value="Ontario">Ontario</option>
                      <option value="Prince Edward Island">Prince Edward Island</option>
                      <option value="Quebec">Quebec</option>
                      <option value="Saskatchewan">Saskatchewan</option>
                      <option value="Yukon">Yukon</option>
                    </select> 
                  ) : (
                    <input
                      name='state'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.state}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Zip Code</td>
                <td>
                  {editable === true ? (
                    <input
                      name='zip_code'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.zip_code}
                    />
                  ) : (
                    <input
                      name='zip_code'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.zip_code}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Country</td>
                <td>
                  {editable === true ? (
                    <select
                      name='country'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.country}
                    >
                      <option value='United States'>United States</option>
                      <option value='Afghanistan'>Afghanistan</option>
                      <option value='Albania'>Albania</option>
                      <option value='Algeria'>Algeria</option>
                      <option value='American Samoa'>American Samoa</option>
                      <option value='Andorra'>Andorra</option>
                      <option value='Angola'>Angola</option>
                      <option value='Anguilla'>Anguilla</option>
                      <option value='Antarctica'>Antarctica</option>
                      <option value='Antigua and Barbuda'>
                        Antigua and Barbuda
                      </option>
                      <option value='Argentina'>Argentina</option>
                      <option value='Armenia'>Armenia</option>
                      <option value='Aruba'>Aruba</option>
                      <option value='Australia'>Australia</option>
                      <option value='Austria'>Austria</option>
                      <option value='Azerbaijan'>Azerbaijan</option>
                      <option value='Bahamas'>Bahamas</option>
                      <option value='Bahrain'>Bahrain</option>
                      <option value='Bangladesh'>Bangladesh</option>
                      <option value='Barbados'>Barbados</option>
                      <option value='Belarus'>Belarus</option>
                      <option value='Belgium'>Belgium</option>
                      <option value='Belize'>Belize</option>
                      <option value='Benin'>Benin</option>
                      <option value='Bermuda'>Bermuda</option>
                      <option value='Bhutan'>Bhutan</option>
                      <option value='Bolivia'>Bolivia</option>
                      <option value='Bosnia and Herzegovina'>
                        Bosnia and Herzegovina
                      </option>
                      <option value='Botswana'>Botswana</option>
                      <option value='Bouvet Island'>Bouvet Island</option>
                      <option value='Brazil'>Brazil</option>
                      <option value='British Indian Ocean Territory'>
                        British Indian Ocean Territory
                      </option>
                      <option value='British Virgin Islands'>
                        British Virgin Islands
                      </option>
                      <option value='Brunei'>Brunei</option>
                      <option value='Bulgaria'>Bulgaria</option>
                      <option value='Burkina Faso'>Burkina Faso</option>
                      <option value='Burundi'>Burundi</option>
                      <option value='Cambodia'>Cambodia</option>
                      <option value='Cameroon'>Cameroon</option>
                      <option value='Canada'>Canada</option>
                      <option value='Cape Verde'>Cape Verde</option>
                      <option value='Cayman Islands'>Cayman Islands</option>
                      <option value='Central African Republic'>
                        Central African Republic
                      </option>
                      <option value='Chad'>Chad</option>
                      <option value='Chile'>Chile</option>
                      <option value='China'>China</option>
                      <option value='Christmas Island'>Christmas Island</option>
                      <option value='Cocos Islands'>Cocos Islands</option>
                      <option value='Colombia'>Colombia</option>
                      <option value='Comoros'>Comoros</option>
                      <option value='Congo'>Congo</option>
                      <option value='Cook Islands'>Cook Islands</option>
                      <option value='Costa Rica'>Costa Rica</option>
                      <option value='Croatia'>Croatia</option>
                      <option value='Cuba'>Cuba</option>
                      <option value='Cyprus'>Cyprus</option>
                      <option value='Czech Republic'>Czech Republic</option>
                      <option value='Denmark'>Denmark</option>
                      <option value='Djibouti'>Djibouti</option>
                      <option value='Dominica'>Dominica</option>
                      <option value='Dominican Republic'>
                        Dominican Republic
                      </option>
                      <option value='Ecuador'>Ecuador</option>
                      <option value='Egypt'>Egypt</option>
                      <option value='El Salvador'>El Salvador</option>
                      <option value='Equatorial Guinea'>
                        Equatorial Guinea
                      </option>
                      <option value='Eritrea'>Eritrea</option>
                      <option value='Estonia'>Estonia</option>
                      <option value='Ethiopia'>Ethiopia</option>
                      <option value='Falkland Islands'>Falkland Islands</option>
                      <option value='Faroe Islands'>Faroe Islands</option>
                      <option value='Fiji'>Fiji</option>
                      <option value='Finland'>Finland</option>
                      <option value='France'>France</option>
                      <option value='French Guiana'>French Guiana</option>
                      <option value='French Polynesia'>French Polynesia</option>
                      <option value='French Southern Territories'>
                        French Southern Territories
                      </option>
                      <option value='Gabon'>Gabon</option>
                      <option value='Gambia'>Gambia</option>
                      <option value='Georgia'>Georgia</option>
                      <option value='Germany'>Germany</option>
                      <option value='Ghana'>Ghana</option>
                      <option value='Gibraltar'>Gibraltar</option>
                      <option value='Greece'>Greece</option>
                      <option value='Greenland'>Greenland</option>
                      <option value='Grenada'>Grenada</option>
                      <option value='Guadeloupe'>Guadeloupe</option>
                      <option value='Guam'>Guam</option>
                      <option value='Guatemala'>Guatemala</option>
                      <option value='Guernsey'>Guernsey</option>
                      <option value='Guinea'>Guinea</option>
                      <option value='GuineaBissau'>GuineaBissau</option>
                      <option value='Guyana'>Guyana</option>
                      <option value='Haiti'>Haiti</option>
                      <option value='Heard Island and Mcdonald Islands'>
                        Heard Island and Mcdonald Islands
                      </option>
                      <option value='Honduras'>Honduras</option>
                      <option value='Hong Kong'>Hong Kong</option>
                      <option value='Hungary'>Hungary</option>
                      <option value='Iceland'>Iceland</option>
                      <option value='India'>India</option>
                      <option value='Indonesia'>Indonesia</option>
                      <option value='Iran'>Iran</option>
                      <option value='Iraq'>Iraq</option>
                      <option value='Ireland'>Ireland</option>
                      <option value='Israel'>Israel</option>
                      <option value='Italy'>Italy</option>
                      <option value='Jamaica'>Jamaica</option>
                      <option value='Japan'>Japan</option>
                      <option value='Jersey'>Jersey</option>
                      <option value='Jordan'>Jordan</option>
                      <option value='Kazakhstan'>Kazakhstan</option>
                      <option value='Kenya'>Kenya</option>
                      <option value='Kiribati'>Kiribati</option>
                      <option value='Kosovo'>Kosovo</option>
                      <option value='Kuwait'>Kuwait</option>
                      <option value='Kyrgyzstan'>Kyrgyzstan</option>
                      <option value='Laos'>Laos</option>
                      <option value='Latvia'>Latvia</option>
                      <option value='Lebanon'>Lebanon</option>
                      <option value='Lesotho'>Lesotho</option>
                      <option value='Liberia'>Liberia</option>
                      <option value='Libya'>Libya</option>
                      <option value='Liechtenstein'>Liechtenstein</option>
                      <option value='Lithuania'>Lithuania</option>
                      <option value='Luxembourg'>Luxembourg</option>
                      <option value='Macao'>Macao</option>
                      <option value='Macedonia'>Macedonia</option>
                      <option value='Madagascar'>Madagascar</option>
                      <option value='Malawi'>Malawi</option>
                      <option value='Malaysia'>Malaysia</option>
                      <option value='Maldives'>Maldives</option>
                      <option value='Mali'>Mali</option>
                      <option value='Malta'>Malta</option>
                      <option value='Marshall Islands'>Marshall Islands</option>
                      <option value='Martinique'>Martinique</option>
                      <option value='Mauritania'>Mauritania</option>
                      <option value='Mauritius'>Mauritius</option>
                      <option value='Mayotte'>Mayotte</option>
                      <option value='Mexico'>Mexico</option>
                      <option value='Micronesia'>Micronesia</option>
                      <option value='Moldova'>Moldova</option>
                      <option value='Monaco'>Monaco</option>
                      <option value='Mongolia'>Mongolia</option>
                      <option value='Montenegro'>Montenegro</option>
                      <option value='Montserrat'>Montserrat</option>
                      <option value='Morocco'>Morocco</option>
                      <option value='Mozambique'>Mozambique</option>
                      <option value='Myanmar'>Myanmar</option>
                      <option value='Namibia'>Namibia</option>
                      <option value='Nauru'>Nauru</option>
                      <option value='Nepal'>Nepal</option>
                      <option value='Netherlands'>Netherlands</option>
                      <option value='Netherlands Antilles'>
                        Netherlands Antilles
                      </option>
                      <option value='New Caledonia'>New Caledonia</option>
                      <option value='New Zealand'>New Zealand</option>
                      <option value='Nicaragua'>Nicaragua</option>
                      <option value='Niger'>Niger</option>
                      <option value='Nigeria'>Nigeria</option>
                      <option value='Niue'>Niue</option>
                      <option value='Norfolk Island'>Norfolk Island</option>
                      <option value='North Korea'>North Korea</option>
                      <option value='Northern Mariana Islands'>
                        Northern Mariana Islands
                      </option>
                      <option value='Norway'>Norway</option>
                      <option value='Oman'>Oman</option>
                      <option value='Pakistan'>Pakistan</option>
                      <option value='Palau'>Palau</option>
                      <option value='Palestine'>Palestine</option>
                      <option value='Panama'>Panama</option>
                      <option value='Papua New Guinea'>Papua New Guinea</option>
                      <option value='Paraguay'>Paraguay</option>
                      <option value='Peru'>Peru</option>
                      <option value='Philippines'>Philippines</option>
                      <option value='Pitcairn'>Pitcairn</option>
                      <option value='Poland'>Poland</option>
                      <option value='Portugal'>Portugal</option>
                      <option value='Puerto Rico'>Puerto Rico</option>
                      <option value='Qatar'>Qatar</option>
                      <option value='Reunion'>Reunion</option>
                      <option value='Romania'>Romania</option>
                      <option value='Russia'>Russia</option>
                      <option value='Rwanda'>Rwanda</option>
                      <option value='Saint Helena'>Saint Helena</option>
                      <option value='Saint Kitts and Nevis'>
                        Saint Kitts and Nevis
                      </option>
                      <option value='Saint Lucia'>Saint Lucia</option>
                      <option value='Saint Pierre and Miquelon'>
                        Saint Pierre and Miquelon
                      </option>
                      <option value='Saint Vincent and the Grenadines'>
                        Saint Vincent and the Grenadines
                      </option>
                      <option value='Samoa'>Samoa</option>
                      <option value='San Marino'>San Marino</option>
                      <option value='Sao Tome and Principe'>
                        Sao Tome and Principe
                      </option>
                      <option value='Saudi Arabia'>Saudi Arabia</option>
                      <option value='Senegal'>Senegal</option>
                      <option value='Serbia'>Serbia</option>
                      <option value='Serbia and Montenegro'>
                        Serbia and Montenegro
                      </option>
                      <option value='Seychelles'>Seychelles</option>
                      <option value='Sierra Leone'>Sierra Leone</option>
                      <option value='Singapore'>Singapore</option>
                      <option value='Sint Maarten'>Sint Maarten</option>
                      <option value='Slovakia'>Slovakia</option>
                      <option value='Slovenia'>Slovenia</option>
                      <option value='Solomon Islands'>Solomon Islands</option>
                      <option value='Somalia'>Somalia</option>
                      <option value='South Africa'>South Africa</option>
                      <option value='South Georgia and the South Sandwich Islands'>
                        South Georgia and the South Sandwich Islands
                      </option>
                      <option value='South Korea'>South Korea</option>
                      <option value='Spain'>Spain</option>
                      <option value='Sri Lanka'>Sri Lanka</option>
                      <option value='Sudan'>Sudan</option>
                      <option value='Suriname'>Suriname</option>
                      <option value='Svalbard and Jan Mayen'>
                        Svalbard and Jan Mayen
                      </option>
                      <option value='Swaziland'>Swaziland</option>
                      <option value='Sweden'>Sweden</option>
                      <option value='Switzerland'>Switzerland</option>
                      <option value='Syria'>Syria</option>
                      <option value='Taiwan'>Taiwan</option>
                      <option value='Tajikistan'>Tajikistan</option>
                      <option value='Tanzania'>Tanzania</option>
                      <option value='Thailand'>Thailand</option>
                      <option value='The Democratic Republic of Congo'>
                        The Democratic Republic of Congo
                      </option>
                      <option value='Timor-Leste'>Timor-Leste</option>
                      <option value='Togo'>Togo</option>
                      <option value='Tokelau'>Tokelau</option>
                      <option value='Tonga'>Tonga</option>
                      <option value='Trinidad and Tobago'>
                        Trinidad and Tobago
                      </option>
                      <option value='Tunisia'>Tunisia</option>
                      <option value='Turkey'>Turkey</option>
                      <option value='Turkmenistan'>Turkmenistan</option>
                      <option value='Turks and Caicos Islands'>
                        Turks and Caicos Islands
                      </option>
                      <option value='Tuvalu'>Tuvalu</option>
                      <option value='Virgin Islands'>Virgin Islands</option>
                      <option value='Uganda'>Uganda</option>
                      <option value='Ukraine'>Ukraine</option>
                      <option value='United Arab Emirates'>
                        United Arab Emirates
                      </option>
                      <option value='United Kingdom'>United Kingdom</option>
                      <option value='United States Minor Outlying Islands'>
                        United States Minor Outlying Islands
                      </option>
                      <option value='Uruguay'>Uruguay</option>
                      <option value='Uzbekistan'>Uzbekistan</option>
                      <option value='Vanuatu'>Vanuatu</option>
                      <option value='Venezuela'>Venezuela</option>
                      <option value='Vietnam'>Vietnam</option>
                      <option value='Wallis and Futuna'>
                        Wallis and Futuna
                      </option>
                      <option value='Western Sahara'>Western Sahara</option>
                      <option value='Yemen'>Yemen</option>
                      <option value='Zambia'>Zambia</option>
                      <option value='Zimbabwe'>Zimbabwe</option>
                      <option value='Aland Islands'>Aland Islands</option>
                      {/* <option value='United States'>United States</option>
                      <option value='Afghanistan'>Afghanistan</option>
                      <option value='Albania'>Albania</option>
                      <option value='Algeria'>Algeria</option>
                      <option value='American Samoa'>American Samoa</option>
                      <option value='Andorra'>Andorra</option>
                      <option value='Angola'>Angola</option>
                      <option value='Anguilla'>Anguilla</option>
                      <option value='Antarctica'>Antarctica</option>
                      <option value='Antigua and Barbuda'>Antigua and Barbuda</option>
                      <option value='Argentina'>Argentina</option>
                      <option value='Armenia'>Armenia</option>
                      <option value='Aruban'>Aruban</option>
                      <option value='Australia'>Australia</option>
                      <option value='Austria'>Austria</option>
                      <option value='Azerbaijan'>Azerbaijan</option>
                      <option value='Bahamas'>Bahamas</option>
                      <option value='Bahrain'>Bahrain</option>
                      <option value='Bangladesh'>Bangladesh</option>
                      <option value='Bardbados'>Bardbados</option> */}
                    </select>
                  ) : (
                    project.country
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>ASHRAE Climate Zone</td>
                <td>
                  {editable === true ? (
                    <select
                      name='ASHRAE_climate_zone'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      deafultValue={project.ASHRAE_climate_zone}
                    >
                      <option value=''></option>
                      <option value='0A - Extremely Hot - Humid'>
                        0A - Extremely Hot - Humid
                      </option>
                      <option value='0B - Extremely Hot - Dry'>
                        0B - Extremely Hot - Dry
                      </option>
                      <option value='1A - Very Hot - Humid'>
                        1A - Very Hot - Humid
                      </option>
                      <option value='1B - Very Hot - Humid'>
                        1B - Very Hot - Humid
                      </option>
                      <option value='2A - Hot - Humid'>2A - Hot - Humid</option>
                      <option value='2B - Hot - Dry'>2B - Hot - Dry</option>
                      <option value='3A - Warm - Humid'>
                        3A - Warm - Humid
                      </option>
                      <option value='3B - Warm - Dry'>3B - Warm - Dry</option>
                      <option value='4A - Mixed - Humid'>
                        4A - Mixed - Humid
                      </option>
                      <option value='4B - Mixed - Dry'>4B - Mixed - Dry</option>
                      <option value='4C - Mixed - Marine'>
                        4C - Mixed - Marine
                      </option>
                      <option value='5A - Cool - Humid'>
                        5A - Cool - Humid
                      </option>
                      <option value='5B - Cool - Dry'>5B - Cool - Dry</option>
                      <option value='5C - Cool - Marine'>
                        5C - Cool - Marine
                      </option>
                      <option value='6A - Cool - Humid'>
                        6A - Cool - Humid
                      </option>
                      <option value='6B - Cool -  Dry'>6B - Cool - Dry</option>
                      <option value='7 - Very Cold'>7 - Very Cold</option>
                      <option value='8 - Subarctic / Arctic'>
                        8 - Subarctic / Arctic
                      </option>
                    </select>
                  ) : (
                    project.ASHRAE_climate_zone
                  )}
                </td>
              </tr>
              {/* <tr>
                <td>Region</td>
                <td >{project.US_region}</td>
              </tr> */}
              <tr>
                {/* picklist */}
                <td>Compliance Software</td>
                <td>
                  {editable === true ? (
                    <select
                      name='compliance_software'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.compliance_software}
                    >
                      <option value=''></option>
                      <option value='WUFI Passive'>WUFI Passive</option>
                      <option value='PHPP'>PHPP</option>
                      <option value='N/A'>N/A</option>
                    </select>
                  ) : (
                    project.compliance_software
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Energy Star Rating</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='energy_star_rating'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.energy_star_rating}
                    />
                  ) : (
                    <input
                      name='energy_star_rating'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.energy_star_rating}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>HERS Rating</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='HERS_rating'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.HERS_rating}
                    />
                  ) : (
                    <input
                      name='HERS_rating'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.HERS_rating}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>HERS Rating with Renewables</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='HERS_rating_with_renewables'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.HERS_rating_with_renewables}
                    />
                  ) : (
                    <input
                      name='HERS_rating_with_renewables'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.HERS_rating_with_renewables}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Window-to-Wall Ratio</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='window_to_wall_ratio'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.window_to_wall_ratio}
                    />
                  ) : (
                    <input
                      name='window_to_wall_ratio'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.window_to_wall_ratio}
                    />
                  )}{' '}
                  <span className='unit-label'>%</span>
                </td>
              </tr>
              {/* multiselect */}
              {project.additional_certifications !== undefined &&
              project.additional_certifications.length > 0 ? (
                <tr>
                  <td>Additional Certification</td>
                  <td>
                    {editable === true ? (
                      <Select
                        name='additional_certifications'
                        defaultValue={project.additional_certifications.map(
                          (type) => ({ value: type, label: type })
                        )}
                        onChange={(option) =>
                          handleSelectChange(
                            'additional_certifications',
                            option
                          )
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        options={[
                          { value: 'LEED Platinum', label: 'LEED Platinum' },
                          { label: 'LEED Gold', value: 'LEED Gold' },
                          { label: 'LEED Silver', value: 'LEED Silver' },
                          { label: 'LEED Certified', value: 'LEED Certified' },
                          {
                            label: 'Enterprise Green Communities',
                            value: 'Enterprise Green Communities',
                          },
                          {
                            label: 'Enterprise Green Communities Plus',
                            value: 'Enterprise Green Communities Plus',
                          },
                          { label: 'ILFI Petal', value: 'ILFI Petal' },
                          {
                            label: 'ILFI Zero Energy',
                            value: 'ILFI Zero Energy',
                          },
                          {
                            label: 'ILFI Zero Carbon+',
                            value: 'ILFI Zero Carbon+',
                          },
                          { label: 'NGBS', value: 'NGBS' },
                          { label: 'NGBS Emerald', value: 'NGBS Emerald' },
                        ]}
                      />
                    ) : (
                      project.additional_certifications.join(', ')
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.additional_certification_badges.length !== undefined &&
              project.additional_certification_badges.length > 0 ? (
                <tr>
                  <td>Additional Certification Badges</td>
                  <td>
                    {editable === true ? (
                      project.additional_certification_badges !== null ||
                      project.additional_certification_badges.length !== 0 ? (
                        <Select
                          name='additional_certification_badges'
                          defaultValue={project.additional_certification_badges.map(
                            (type) => ({ value: type, label: type })
                          )}
                          onChange={(option) =>
                            handleSelectChange(
                              'additional_certification_badges',
                              option
                            )
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          options={[
                            {
                              value:
                                'Supply Air Heating and Cooling Sufficient',
                              label:
                                'Supply Air Heating and Cooling Sufficient',
                            },
                            {
                              label: 'PHIUS+ Source Zero',
                              value: 'PHIUS+ Source Zero',
                            },
                          ]}
                        />
                      ) : (
                        <Select
                          name='additional_certification_badges'
                          onChange={(option) =>
                            handleSelectChange(
                              'additional_certification_badges',
                              option
                            )
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          options={[
                            {
                              value:
                                'Supply Air Heating and Cooling Sufficient',
                              label:
                                'Supply Air Heating and Cooling Sufficient',
                            },
                            {
                              label: 'PHIUS+ Source Zero',
                              value: 'PHIUS+ Source Zero',
                            },
                          ]}
                        />
                      )
                    ) : (
                      project.additional_certification_badges.join(', ')
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
            </tbody>
          </table>
        </div>
        <div label='Wall, Roof, Floor Types'>
          <table className='project-detail-table'>
            <tbody>
              <tr>
                <td>Number of Wall Types</td>
                {/* <td >{editable===true ? <input name="number_of_wall_types" onChange={e => handleInputChange(e)} className="edit-input" value={project.number_of_wall_types} /> : <input name="number_of_wall_types" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.number_of_wall_types} />}</td> */}
                <td>
                  {editable === true ? (
                    <select
                      name='number_of_wall_types'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.number_of_wall_types}
                    >
                      <option value=''></option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                    </select>
                  ) : (
                    project.number_of_wall_types
                  )}
                </td>
              </tr>
              {/* <tr>
                <td>Number Wall Types</td>
                <td >{editable===true ? <input name="number_wall_type_2" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.number_wall_type_2} /> : <input name="number_wall_type_2" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.number_wall_type_2} />}</td>
              </tr>
              <tr>
                <td>Number Wall Types</td>
                <td >{editable===true ? <input name="number_of_wall_type_3" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.number_wall_type_3} /> : <input name="number_of_wall_type_3" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.number_wall_type_3} />}</td>
              </tr> */}
              {/* wall group 1 */}
              <tr>
                <td>Wall Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='wall_type_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.wall_type_1}
                    >
                      <option value=''></option>
                      <option value='Interior Load Bearing Double-Stud'>
                        Interior Load Bearing Double-Stud
                      </option>
                      <option value='Exterior Load Bearing Double-Stud'>
                        Exterior Load Bearing Double-Stud
                      </option>
                      <option value='Framing with Continuous Insulation'>
                        Framing with Continuous Insulation
                      </option>
                      <option value='All Insulation within Framing'>
                        All Insulation within Framing
                      </option>
                      <option value='Exterior Continuous Insulation Only'>
                        Exterior Continuous Insulation Only
                      </option>
                      <option value='Insulated Concrete Form (ICF)'>
                        Insulated Concrete Form (ICF)
                      </option>
                      <option value='Structurally Insulated Panel (SIP)'>
                        Structurally Insulated Panel (SIP)
                      </option>
                      <option value='Other'>Other</option>
                    </select>
                  ) : (
                    project.wall_type_1
                  )}
                </td>
              </tr>
              <tr>
                <td>Wall R-Value</td>
                <td>
                  {editable === true ? (
                    <input
                      name='wall_thermal_performance_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.wall_thermal_performance_1}
                    />
                  ) : (
                    <input
                      name='wall_thermal_performance_1'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input red'
                      disabled
                      value={project.wall_thermal_performance_1}
                    />
                  )}{' '}
                  <span className='unit-label'>hr⋅ft²⋅°F/BTU</span>
                </td>
              </tr>
              <tr>
                <td>Wall Insulation Type</td>
                <td>
                  {editable === true ? (
                    project.wall_insulation_type_1 !== null ||
                    project.wall_insulation_type_1.length !== 0 ? (
                      <Select
                        name='wall_insulation_type_1'
                        defaultValue={project.wall_insulation_type_1.map(
                          (type) => ({ value: type, label: type })
                        )}
                        onChange={(option) =>
                          handleSelectChange('wall_insulation_type_1', option)
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        disabled={!editable}
                        options={[
                          { value: 'EPS', label: 'EPS' },
                          { label: 'XPS', value: 'XPS' },
                          { label: 'Fiberglass', value: 'Fiberglass' },
                          { label: 'Cellulose', value: 'Cellulose' },
                          { label: 'Mineral Wool', value: 'Mineral Wool' },
                          { label: 'Other', value: 'Other' },
                        ]}
                      />
                    ) : (
                      <Select
                        name='wall_insulation_type_1'
                        onChange={(option) =>
                          handleSelectChange('wall_insulation_type_1', option)
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        disabled={!editable}
                        options={[
                          { value: 'EPS', label: 'EPS' },
                          { label: 'XPS', value: 'XPS' },
                          { label: 'Fiberglass', value: 'Fiberglass' },
                          { label: 'Cellulose', value: 'Cellulose' },
                          { label: 'Mineral Wool', value: 'Mineral Wool' },
                          { label: 'Other', value: 'Other' },
                        ]}
                      />
                    )
                  ) : (
                    project.wall_insulation_type_1.join(', ')
                  )}
                </td>
                {/* <td >{editable===true ? <input name="wall_insulation_type_1" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.wall_insulation_type_1} /> : <input name="wall_insulation_type_1" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.wall_insulation_type_1} />}</td> */}
              </tr>
              {/* end wall group 1, start wall group 2 */}
              {project.number_of_wall_types > 1 ? (
                <tr>
                  <td>Wall Type</td>
                  <td>
                    {editable === true ? (
                      <select
                        name='wall_type_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        defaultValue={project.wall_type_2}
                      >
                        <option value='Interior Load Bearing Double-Stud'>
                          Interior Load Bearing Double-Stud
                        </option>
                        <option value='Exterior Load Bearing Double-Stud'>
                          Exterior Load Bearing Double-Stud
                        </option>
                        <option value='Framing with Continuous Insulation'>
                          Framing with Continuous Insulation
                        </option>
                        <option value='All Insulation within Framing'>
                          All Insulation within Framing
                        </option>
                        <option value='Exterior Continuous Insulation Only'>
                          Exterior Continuous Insulation Only
                        </option>
                        <option value='Insulated Concrete Form (ICF)'>
                          Insulated Concrete Form (ICF)
                        </option>
                        <option value='Structurally Insulated Panel (SIP)'>
                          Structurally Insulated Panel (SIP)
                        </option>
                        <option value='Other'>Other</option>
                      </select>
                    ) : (
                      project.wall_type_2
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_wall_types > 1 ? (
                <tr>
                  <td>Wall R-Value</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='wall_thermal_performance_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.wall_thermal_performance_2}
                      />
                    ) : (
                      <input
                        name='wall_thermal_performance_2'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input red'
                        disabled
                        value={project.wall_thermal_performance_2}
                      />
                    )}{' '}
                    <span className='unit-label'>hr⋅ft²⋅°F/BTU</span>
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_wall_types > 1 ? (
                <tr>
                  <td>Wall Insulation Type</td>
                  <td>
                    {editable === true ? (
                      project.wall_insulation_type_2 !== null ||
                      project.wall_insulation_type_2.length !== 0 ? (
                        <Select
                          name='wall_insulation_type_2'
                          defaultValue={project.wall_insulation_type_2.map(
                            (type) => ({ value: type, label: type })
                          )}
                          onChange={(option) =>
                            handleSelectChange('wall_insulation_type_2', option)
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      ) : (
                        <Select
                          name='wall_insulation_type_2'
                          onChange={(option) =>
                            handleSelectChange('wall_insulation_type_2', option)
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      )
                    ) : (
                      project.wall_insulation_type_2.join(', ')
                    )}
                  </td>
                  {/* <td >{editable===true ? <input name="wall_insulation_type_2" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.wall_insulation_type_2} /> : <input name="wall_insulation_type_2" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.wall_insulation_type_2} />}</td> */}
                </tr>
              ) : (
                ''
              )}
              {/* end wall group 2, start wall group 3 */}
              {project.number_of_wall_types > 2 ? (
                <tr>
                  <td>Wall Type</td>
                  <td>
                    {editable === true ? (
                      <select
                        name='wall_type_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        defaultValue={project.wall_type_3}
                      >
                        <option value='Interior Load Bearing Double-Stud'>
                          Interior Load Bearing Double-Stud
                        </option>
                        <option value='Exterior Load Bearing Double-Stud'>
                          Exterior Load Bearing Double-Stud
                        </option>
                        <option value='Framing with Continuous Insulation'>
                          Framing with Continuous Insulation
                        </option>
                        <option value='All Insulation within Framing'>
                          All Insulation within Framing
                        </option>
                        <option value='Exterior Continuous Insulation Only'>
                          Exterior Continuous Insulation Only
                        </option>
                        <option value='Insulated Concrete Form (ICF)'>
                          Insulated Concrete Form (ICF)
                        </option>
                        <option value='Structurally Insulated Panel (SIP)'>
                          Structurally Insulated Panel (SIP)
                        </option>
                        <option value='Other'>Other</option>
                      </select>
                    ) : (
                      project.wall_type_3
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_wall_types > 2 ? (
                <tr>
                  <td>Wall R-Value</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='wall_thermal_performance_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.wall_thermal_performance_3}
                      />
                    ) : (
                      <input
                        name='wall_thermal_performance_3'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input red'
                        disabled
                        value={project.wall_thermal_performance_3}
                      />
                    )}{' '}
                    <span className='unit-label'>hr⋅ft²⋅°F/BTU</span>
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_wall_types > 2 ? (
                <tr>
                  <td>Wall Insulation Type</td>
                  <td>
                    {editable === true ? (
                      project.wall_insulation_type_3 !== null ||
                      project.wall_insulation_type_3.length !== 0 ? (
                        <Select
                          name='wall_insulation_type_3'
                          defaultValue={project.wall_insulation_type_3.map(
                            (type) => ({ value: type, label: type })
                          )}
                          onChange={(option) =>
                            handleSelectChange('wall_insulation_type_3', option)
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          disabled={!editable}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      ) : (
                        <Select
                          name='wall_insulation_type_3'
                          onChange={(option) =>
                            handleSelectChange('wall_insulation_type_3', option)
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          disabled={!editable}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      )
                    ) : (
                      project.wall_insulation_type_3.join(', ')
                    )}
                  </td>
                  {/* <td >{editable===true ? <input name="wall_insulation_type_3" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.wall_insulation_type_3} /> : <input name="wall_insulation_type_3" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.wall_insulation_type_3} />}</td> */}
                </tr>
              ) : (
                ''
              )}
              {/* <tr>
                <td>Wall Structure</td>
                <td >{editable===true ? <input name="wall_structure" onChange={e => handleInputChange(e)} className="project-detail-input red" disabled value="unsure of field" /> : <input name="wall_structure" onChange={e => handleInputChange(e)} className="project-detail-input red" disabled value="unsure of field" />}</td>
              </tr> */}
              <tr>
                <td>Number of Floor Types</td>
                {/* <td >{editable===true ? <input name="number_of_floor_types" onChange={e => handleInputChange(e)} className="edit-input" value={project.number_of_floor_types} /> : <input name="number_of_floor_types" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.number_of_floor_types} />}</td> */}
                <td>
                  {editable === true ? (
                    <select
                      name='number_of_floor_types'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.number_of_floor_types}
                    >
                      <option value=''></option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                    </select>
                  ) : (
                    project.number_of_floor_types
                  )}
                </td>
              </tr>
              {/* <tr>
                <td>Floor (#)</td>
                <td >{project.cost_per_floor_area}</td>
              </tr>
              <tr>
                <td>Floor Structure</td>
                <td >{editable===true ? <input name="floor_structure" onChange={e => handleInputChange(e)} className="project-detail-input red" value="unsure of field" /> : <input name="floor_structure" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.floor_type_1} />}</td>
              </tr> */}
              {/* floor group 1 */}
              <tr>
                <td>Floor Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='floor_type_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.wall_type_1}
                    >
                      {/* Slab on Grade, Basement Slab, Crawlspace Floor, Overhang Floor, Other */}
                      <option value=''></option>
                      <option value='Slab on Grade'>Slab on Grade</option>
                      <option value='Basement Slab'>Basement Slab</option>
                      <option value='Crawlspace Floor'>Crawlspace Floor</option>
                      <option value='Overhang Floor'>Overhang Floor</option>
                      <option value='Other'>Other</option>
                    </select>
                  ) : (
                    project.floor_type_1
                  )}
                </td>
              </tr>
              <tr>
                <td>Floor R-Value</td>
                <td>
                  {editable === true ? (
                    <input
                      name='floor_thermal_performance_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.floor_thermal_performance_1}
                    />
                  ) : (
                    <input
                      name='floor_thermal_performance_1'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input red'
                      disabled
                      value={project.floor_thermal_performance_1}
                    />
                  )}{' '}
                  <span className='unit-label'>hr⋅ft²⋅°F/BTU</span>
                </td>
              </tr>
              <tr>
                <td>Floor Insulation Type</td>
                <td>
                  {editable === true ? (
                    project.floor_insulation_type_1 !== null ||
                    project.floor_insulation_type_1.length !== 0 ? (
                      <Select
                        name='floor_insulation_type_1'
                        defaultValue={project.floor_insulation_type_1.map(
                          (type) => ({ value: type, label: type })
                        )}
                        onChange={(option) =>
                          handleSelectChange('floor_insulation_type_1', option)
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        disabled={!editable}
                        options={[
                          { value: 'EPS', label: 'EPS' },
                          { label: 'XPS', value: 'XPS' },
                          { label: 'Fiberglass', value: 'Fiberglass' },
                          { label: 'Cellulose', value: 'Cellulose' },
                          { label: 'Mineral Wool', value: 'Mineral Wool' },
                          { label: 'Other', value: 'Other' },
                        ]}
                      />
                    ) : (
                      <Select
                        name='floor_insulation_type_1'
                        onChange={(option) =>
                          handleSelectChange('floor_insulation_type_1', option)
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        disabled={!editable}
                        options={[
                          { value: 'EPS', label: 'EPS' },
                          { label: 'XPS', value: 'XPS' },
                          { label: 'Fiberglass', value: 'Fiberglass' },
                          { label: 'Cellulose', value: 'Cellulose' },
                          { label: 'Mineral Wool', value: 'Mineral Wool' },
                          { label: 'Other', value: 'Other' },
                        ]}
                      />
                    )
                  ) : (
                    project.floor_insulation_type_1.join(', ')
                  )}
                </td>
                {/* <td >{editable===true ? <input name="floor_insulation_type_1" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.floor_insulation_type_1} /> : <input name="floor_insulation_type_1" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.floor_insulation_type_1} />}</td> */}
              </tr>
              {project.number_of_floor_types > 1 ? (
                <tr>
                  <td>Floor Type</td>
                  <td>
                    {editable === true ? (
                      <select
                        name='floor_type_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        defaultValue={project.wall_type_2}
                      >
                        {/* Slab on Grade, Basement Slab, Crawlspace Floor, Overhang Floor, Other */}
                        <option value=''></option>
                        <option value='Slab on Grade'>Slab on Grade</option>
                        <option value='Basement Slab'>Basement Slab</option>
                        <option value='Crawlspace Floor'>
                          Crawlspace Floor
                        </option>
                        <option value='Overhang Floor'>Overhang Floor</option>
                        <option value='Other'>Other</option>
                      </select>
                    ) : (
                      project.floor_type_2
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_floor_types > 1 ? (
                <tr>
                  <td>Floor R-Value</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='floor_thermal_performance_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.floor_thermal_performance_2}
                      />
                    ) : (
                      <input
                        name='floor_thermal_performance_2'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input red'
                        disabled
                        value={project.floor_thermal_performance_2}
                      />
                    )}{' '}
                    <span className='unit-label'>hr⋅ft²⋅°F/BTU</span>
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_floor_types > 1 ? (
                <tr>
                  <td>Floor Insulation Type</td>
                  <td>
                    {editable === true ? (
                      project.floor_insulation_type_2 !== null ||
                      project.floor_insulation_type_2.length !== 0 ? (
                        <Select
                          name='floor_insulation_type_2'
                          defaultValue={project.floor_insulation_type_2.map(
                            (type) => ({ value: type, label: type })
                          )}
                          onChange={(option) =>
                            handleSelectChange(
                              'floor_insulation_type_2',
                              option
                            )
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          disabled={!editable}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      ) : (
                        <Select
                          name='floor_insulation_type_2'
                          onChange={(option) =>
                            handleSelectChange(
                              'floor_insulation_type_2',
                              option
                            )
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          disabled={!editable}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      )
                    ) : (
                      project.floor_insulation_type_2.join(', ')
                    )}
                  </td>
                  {/* <td >{editable===true ? <input name="floor_insulation_type_2" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.floor_insulation_type_2} /> : <input name="floor_insulation_type_2" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.floor_insulation_type_2} />}</td> */}
                </tr>
              ) : (
                ''
              )}
              {project.number_of_floor_types > 2 ? (
                <tr>
                  <td>Floor Type</td>
                  <td>
                    {editable === true ? (
                      <select
                        name='floor_type_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        defaultValue={project.wall_type_3}
                      >
                        {/* Slab on Grade, Basement Slab, Crawlspace Floor, Overhang Floor, Other */}
                        <option value=''></option>
                        <option value='Slab on Grade'>Slab on Grade</option>
                        <option value='Basement Slab'>Basement Slab</option>
                        <option value='Crawlspace Floor'>
                          Crawlspace Floor
                        </option>
                        <option value='Overhang Floor'>Overhang Floor</option>
                        <option value='Other'>Other</option>
                      </select>
                    ) : (
                      project.floor_type_3
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_floor_types > 2 ? (
                <tr>
                  <td>Floor R-Value</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='floor_thermal_performance_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.floor_thermal_performance_3}
                      />
                    ) : (
                      <input
                        name='floor_thermal_performance_3'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input red'
                        disabled
                        value={project.floor_thermal_performance_3}
                      />
                    )}{' '}
                    <span className='unit-label'>hr⋅ft²⋅°F/BTU</span>
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_floor_types > 2 ? (
                <tr>
                  <td>Floor Insulation Type</td>
                  <td>
                    {editable === true ? (
                      project.floor_insulation_type_3 !== null ||
                      project.floor_insulation_type_3.length !== 0 ? (
                        <Select
                          name='floor_insulation_type_3'
                          defaultValue={project.floor_insulation_type_3.map(
                            (type) => ({ value: type, label: type })
                          )}
                          onChange={(option) =>
                            handleSelectChange(
                              'floor_insulation_type_3',
                              option
                            )
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          disabled={!editable}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      ) : (
                        <Select
                          name='floor_insulation_type_3'
                          onChange={(option) =>
                            handleSelectChange(
                              'floor_insulation_type_3',
                              option
                            )
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          disabled={!editable}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      )
                    ) : (
                      project.floor_insulation_type_3.join(', ')
                    )}
                  </td>
                  {/* <td >{editable===true ? <input name="floor_insulation_type_3" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.floor_insulation_type_3} /> : <input name="floor_insulation_type_3" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.floor_insulation_type_3} />}</td> */}
                </tr>
              ) : (
                ''
              )}
              <tr>
                <td>Number of Roof Types</td>
                {/* <td >{editable===true ? <input name="number_of_roof_types" onChange={e => handleInputChange(e)} className="edit-input" value={project.number_of_roof_types} /> : <input name="number_of_roof_types" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.number_of_roof_types} />}</td> */}
                <td>
                  {editable === true ? (
                    <select
                      name='number_of_roof_types'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.number_of_roof_types}
                    >
                      <option value=''></option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                    </select>
                  ) : (
                    project.number_of_roof_types
                  )}
                </td>
              </tr>
              {/* <tr>
                <td>Roof Structure</td>
                <td >{editable===true ? <input name="roof_type_1" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.roof_type_1} /> : <input name="roof_type_1" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.roof_type_1} />}</td>
              </tr> */}
              <tr>
                <td>Roof Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='roof_type_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.roof_type_1}
                    >
                      <option value=''></option>
                      <option value='Vented Above Deck'>
                        Vented Above Deck
                      </option>
                      <option value='Vented Below Deck'>
                        Vented Below Deck
                      </option>
                      <option value='Unvented Roof'>Unvented Roof</option>
                      <option value='Other'>Other</option>
                    </select>
                  ) : (
                    project.roof_type_1
                  )}
                </td>
              </tr>
              <tr>
                <td>Roof R-Value</td>
                <td>
                  {editable === true ? (
                    <input
                      name='roof_thermal_performance_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input red'
                      value={project.roof_thermal_performance_1}
                    />
                  ) : (
                    <input
                      name='roof_thermal_perfomance_1'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input red'
                      disabled
                      value={project.roof_thermal_performance_1}
                    />
                  )}{' '}
                  <span className='unit-label'>hr⋅ft²⋅°F/BTU</span>
                </td>
              </tr>
              <tr>
                <td>Roof Insulation Type</td>
                <td>
                  {editable === true ? (
                    project.roof_insulation_type_1 !== null ||
                    project.roof_insulation_type_1.length !== 0 ? (
                      <Select
                        name='roof_insulation_type_1'
                        defaultValue={project.roof_insulation_type_1.map(
                          (type) => ({ value: type, label: type })
                        )}
                        onChange={(option) =>
                          handleSelectChange('roof_insulation_type_1', option)
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        disabled={!editable}
                        options={[
                          { value: 'EPS', label: 'EPS' },
                          { label: 'XPS', value: 'XPS' },
                          { label: 'Fiberglass', value: 'Fiberglass' },
                          { label: 'Cellulose', value: 'Cellulose' },
                          { label: 'Mineral Wool', value: 'Mineral Wool' },
                          { label: 'Other', value: 'Other' },
                        ]}
                      />
                    ) : (
                      <Select
                        name='roof_insulation_type_1'
                        onChange={(option) =>
                          handleSelectChange('roof_insulation_type_1', option)
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        disabled={!editable}
                        options={[
                          { value: 'EPS', label: 'EPS' },
                          { label: 'XPS', value: 'XPS' },
                          { label: 'Fiberglass', value: 'Fiberglass' },
                          { label: 'Cellulose', value: 'Cellulose' },
                          { label: 'Mineral Wool', value: 'Mineral Wool' },
                          { label: 'Other', value: 'Other' },
                        ]}
                      />
                    )
                  ) : (
                    project.roof_insulation_type_1.join(', ')
                  )}
                </td>
                {/* <td >{editable===true ? <input name="roof_insulation_type_1" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.roof_insulation_type_1} /> : <input name="roof_insulation_type_1" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.roof_insulation_type_1} />}</td> */}
              </tr>
              {project.number_of_roof_types > 1 ? (
                <tr>
                  <td>Roof Type</td>
                  <td>
                    {editable === true ? (
                      <select
                        name='roof_type_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        defaultValue={project.roof_type_2}
                      >
                        <option value=''></option>
                        <option value='Vented Above Deck'>
                          Vented Above Deck
                        </option>
                        <option value='Vented Below Deck'>
                          Vented Below Deck
                        </option>
                        <option value='Unvented Roof'>Unvented Roof</option>
                        <option value='Other'>Other</option>
                      </select>
                    ) : (
                      project.roof_type_2
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_roof_types > 1 ? (
                <tr>
                  <td>Roof R-Value</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='roof_thermal_performance_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input red'
                        value={project.roof_thermal_performance_2}
                      />
                    ) : (
                      <input
                        name='roof_thermal_performance_2'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input red'
                        disabled
                        value={project.roof_thermal_performance_2}
                      />
                    )}{' '}
                    <span className='unit-label'>hr⋅ft²⋅°F/BTU</span>
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_roof_types > 1 ? (
                <tr>
                  <td>Roof Insulation Type</td>
                  <td>
                    {editable === true ? (
                      project.roof_insulation_type_2 !== null ||
                      project.roof_insulation_type_2.length !== 0 ? (
                        <Select
                          name='roof_insulation_type_2'
                          defaultValue={project.roof_insulation_type_2.map(
                            (type) => ({ value: type, label: type })
                          )}
                          onChange={(option) =>
                            handleSelectChange('roof_insulation_type_2', option)
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          disabled={!editable}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      ) : (
                        <Select
                          name='roof_insulation_type_2'
                          onChange={(option) =>
                            handleSelectChange('roof_insulation_type_2', option)
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          disabled={!editable}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      )
                    ) : (
                      project.roof_insulation_type_2.join(', ')
                    )}
                  </td>
                  {/* <td >{editable===true ? <input name="roof_insulation_type_2" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.roof_insulation_type_2} /> : <input name="roof_insulation_type_2" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.roof_insulation_type_2} />}</td> */}
                </tr>
              ) : (
                ''
              )}
              {project.number_of_roof_types > 2 ? (
                <tr>
                  <td>Roof Type</td>
                  <td>
                    {editable === true ? (
                      <select
                        name='roof_type_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        defaultValue={project.roof_type_3}
                      >
                        <option value=''></option>
                        <option value='Vented Above Deck'>
                          Vented Above Deck
                        </option>
                        <option value='Vented Below Deck'>
                          Vented Below Deck
                        </option>
                        <option value='Unvented Roof'>Unvented Roof</option>
                        <option value='Other'>Other</option>
                      </select>
                    ) : (
                      project.roof_type_3
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_roof_types > 2 ? (
                <tr>
                  <td>Roof R-Value</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='roof_thermal_performance_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input red'
                        value={project.roof_thermal_performance_3}
                      />
                    ) : (
                      <input
                        name='roof_thermal_performance_3'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input red'
                        disabled
                        value={project.roof_thermal_performance_3}
                      />
                    )}{' '}
                    <span className='unit-label'>hr⋅ft²⋅°F/BTU</span>
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_roof_types > 2 ? (
                <tr>
                  <td>Roof Insulation Type</td>
                  <td>
                    {editable === true ? (
                      project.roof_insulation_type_3 !== null ||
                      project.roof_insulation_type_3.length !== 0 ? (
                        <Select
                          name='roof_insulation_type_3'
                          defaultValue={project.roof_insulation_type_3.map(
                            (type) => ({ value: type, label: type })
                          )}
                          onChange={(option) =>
                            handleSelectChange('roof_insulation_type_3', option)
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          disabled={!editable}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      ) : (
                        <Select
                          name='roof_insulation_type_3'
                          onChange={(option) =>
                            handleSelectChange('roof_insulation_type_3', option)
                          }
                          closeMenuOnSelect={false}
                          isMulti={true}
                          options={[
                            { value: 'EPS', label: 'EPS' },
                            { label: 'XPS', value: 'XPS' },
                            { label: 'Fiberglass', value: 'Fiberglass' },
                            { label: 'Cellulose', value: 'Cellulose' },
                            { label: 'Mineral Wool', value: 'Mineral Wool' },
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      )
                    ) : (
                      project.roof_insulation_type_3.join(', ')
                    )}
                  </td>
                  {/* <td >{editable===true ? <input name="roof_insulation_type_3" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.roof_insulation_type_3} /> : <input name="roof_insulation_type_3" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.roof_insulation_type_3} />}</td> */}
                </tr>
              ) : (
                ''
              )}
            </tbody>
          </table>
        </div>
        <div label='Window & Door Types'>
          <table className='project-detail-table'>
            <tbody>
              <tr>
                <td>Number of Window Types</td>
                {/* <td >{editable===true ? <input name="number_of_window_types" onChange={e => handleInputChange(e)} className="edit-input" value={project.number_of_window_types} /> : <input name="number_of_window_types" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.number_of_window_types} />}</td> */}
                <td>
                  {editable === true ? (
                    <select
                      name='number_of_window_types'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.number_of_window_types}
                    >
                      <option value=''></option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                    </select>
                  ) : (
                    project.number_of_window_types
                  )}
                </td>
              </tr>
              {/* <tr>
                <td>Window (#)</td>
                <td >{editable===true ? <input name="phius_certification_window" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.phius_certified_window} /> : <input name="phius_certification_window" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.phius_certified_window} />}</td>
              </tr> */}
              <tr>
                <td>Window Product Name</td>
                <td>
                  {editable === true ? (
                    <input
                      name='window_product_name_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.window_product_name_1}
                    />
                  ) : (
                    <input
                      name='window_product_name_1'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.window_product_name_1}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Window Manufacturer</td>
                <td>
                  {editable === true ? (
                    <input
                      name='window_manufacturer_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.window_manufacturer_1}
                    />
                  ) : (
                    <input
                      name='window_manufacturer_1'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.window_manufacturer_1}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Whole Window U-Value</td>
                <td>
                  {editable === true ? (
                    <input
                      name='whole_window_u_value_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.whole_window_u_value_1}
                    />
                  ) : (
                    <input
                      name='whole_window_u_value_1'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.whole_window_u_value_1}
                    />
                  )}{' '}
                  <span className='unit-label'>BTU/hr⋅ft²⋅°F</span>
                </td>
              </tr>
              <tr>
                <td>Window Frame Material</td>
                <td>
                  {editable === true ? (
                    <select
                      name='window_frame_material_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={
                        project.window_frame_material_1 !== undefined
                          ? project.window_frame_material_1
                          : ''
                      }
                    >
                      <option value=''></option>
                      <option value='Aluminum'>Aluminum</option>
                      <option value='Aluminum Clad Wood'>
                        Aluminum Clad Wood
                      </option>
                      <option value='Fiberglass'>Fiberglass</option>
                      <option value='Unplasticized Polyvinyl Chloride (uPVC)'>
                        Unplasticized Polyvinyl Chloride (uPVC)
                      </option>
                      <option value='Vinyl'>Vinyl</option>
                      <option value='Wood'>Wood</option>
                      <option value='Other'>Other</option>
                    </select>
                  ) : (
                    <input
                      name='window_frame_material_1'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.window_frame_material_1}
                    />
                  )}
                  {/* {editable === true ? (
                    <input
                      name='window_frame_material_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.window_frame_material_1}
                    />
                  ) : (
                    <input
                      name='window_frame_material_1'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.window_frame_material_1}
                    />
                  )} */}
                </td>
              </tr>
              <tr>
                <td>Glazing SHGC</td>
                <td>
                  {editable === true ? (
                    <input
                      name='window_glazing_SHGC_1'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.window_glazing_SHGC_1}
                    />
                  ) : (
                    <input
                      name='window_glazing_SHGC_1'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.window_glazing_SHGC_1}
                    />
                  )}
                </td>
              </tr>
              {project.number_of_window_types > 1 ? (
                <tr>
                  <td>Window Product Name</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='window_product_name_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.window_product_name_2}
                      />
                    ) : (
                      <input
                        name='window_product_name_2'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input'
                        disabled
                        value={project.window_product_name_2}
                      />
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_window_types > 1 ? (
                <tr>
                  <td>Window Manufacturer</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='window_manufacturer_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.window_manufacturer_2}
                      />
                    ) : (
                      <input
                        name='window_manufacturer_2'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input'
                        disabled
                        value={project.window_manufacturer_2}
                      />
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_window_types > 1 ? (
                <tr>
                  <td>Whole Window U-Value</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='whole_window_u_value_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.whole_window_u_value_2}
                      />
                    ) : (
                      <input
                        name='whole_window_u_value_2'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input'
                        disabled
                        value={project.whole_window_u_value_2}
                      />
                    )}{' '}
                    <span className='unit-label'>BTU/hr⋅ft²⋅°F</span>
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_window_types > 1 ? (
                <tr>
                  <td>Window Frame Material</td>
                  <td>
                    {editable === true ? (
                      <select
                        name='window_frame_material_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        defaultValue={
                          project.window_frame_material_2 !== undefined
                            ? project.window_frame_material_2
                            : ''
                        }
                      >
                        <option value=''></option>
                        <option value='Aluminum'>Aluminum</option>
                        <option value='Aluminum Clad Wood'>
                          Aluminum Clad Wood
                        </option>
                        <option value='Fiberglass'>Fiberglass</option>
                        <option value='Unplasticized Polyvinyl Chloride (uPVC)'>
                          Unplasticized Polyvinyl Chloride (uPVC)
                        </option>
                        <option value='Vinyl'>Vinyl</option>
                        <option value='Wood'>Wood</option>
                        <option value='Other'>Other</option>
                      </select>
                    ) : (
                      <input
                        name='window_frame_material_2'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input'
                        disabled
                        value={project.window_frame_material_2}
                      />
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_window_types > 1 ? (
                <tr>
                  <td>Glazing SHGC</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='window_glazing_SHGC_2'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.window_glazing_SHGC_2}
                      />
                    ) : (
                      <input
                        name='window_glazing_SHGC_2'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input'
                        disabled
                        value={project.window_glazing_SHGC_2}
                      />
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_window_types > 2 ? (
                <tr>
                  <td>Window Product Name</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='window_product_name_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.window_product_name_3}
                      />
                    ) : (
                      <input
                        name='window_product_name_3'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input'
                        disabled
                        value={project.window_product_name_3}
                      />
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_window_types > 2 ? (
                <tr>
                  <td>Window Manufacturer</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='window_manufacturer_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.window_manufacturer_3}
                      />
                    ) : (
                      <input
                        name='window_manufacturer_3'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input'
                        disabled
                        value={project.window_manufacturer_3}
                      />
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_window_types > 2 ? (
                <tr>
                  <td>Whole Window U-Value</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='whole_window_u_value_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.whole_window_u_value_3}
                      />
                    ) : (
                      <input
                        name='whole_window_u_value_3'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input'
                        disabled
                        value={project.whole_window_u_value_3}
                      />
                    )}{' '}
                    <span className='unit-label'>BTU/hr⋅ft²⋅°F</span>
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_window_types > 2 ? (
                <tr>
                  <td>Window Frame Material</td>
                  <td>
                    {editable === true ? (
                      <select
                        name='window_frame_material_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        defaultValue={
                          project.window_frame_material_3 !== undefined
                            ? project.window_frame_material_3
                            : ''
                        }
                      >
                        <option value=''></option>
                        <option value='Aluminum'>Aluminum</option>
                        <option value='Aluminum Clad Wood'>
                          Aluminum Clad Wood
                        </option>
                        <option value='Fiberglass'>Fiberglass</option>
                        <option value='Unplasticized Polyvinyl Chloride (uPVC)'>
                          Unplasticized Polyvinyl Chloride (uPVC)
                        </option>
                        <option value='Vinyl'>Vinyl</option>
                        <option value='Wood'>Wood</option>
                        <option value='Other'>Other</option>
                      </select>
                    ) : (
                      <input
                        name='window_frame_material_3'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input'
                        disabled
                        value={project.window_frame_material_3}
                      />
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {project.number_of_window_types > 2 ? (
                <tr>
                  <td>Glazing SHGC</td>
                  <td>
                    {editable === true ? (
                      <input
                        name='window_glazing_SHGC_3'
                        onChange={(e) => handleInputChange(e)}
                        className='edit-input'
                        value={project.window_glazing_SHGC_3}
                      />
                    ) : (
                      <input
                        name='window_glazing_SHGC_3'
                        onChange={(e) => handleInputChange(e)}
                        className='project-detail-input'
                        disabled
                        value={project.window_glazing_SHGC_3}
                      />
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
              {/* <tr>
                <td>Doors</td>
                <td>
                  {editable === true ? (
                    <input
                      name='phius_certified_door_name'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.phius_certified_door_name}
                    />
                  ) : (
                    <input
                      name='phius_certified_door_name'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.phius_certified_door_name}
                    />
                  )}
                </td>
              </tr> */}
              <tr>
                <td>Door Manufacturer</td>
                <td>
                  {editable === true ? (
                    <input
                      name='door_manufacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.door_manufacturer}
                    />
                  ) : (
                    <input
                      name='door_manufacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.door_manufacturer}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Door Product Name</td>
                <td>
                  {editable === true ? (
                    <input
                      name='door_product_name'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.door_product_name}
                    />
                  ) : (
                    <input
                      name='door_product_name'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.door_product_name}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Whole Door U-Value</td>
                <td>
                  {editable === true ? (
                    <input
                      name='whole_door_u_value'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.whole_door_u_value}
                    />
                  ) : (
                    <input
                      name='whole_door_u_value'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.whole_door_u_value}
                    />
                  )}{' '}
                  <span className='unit-label'>BTU/hr⋅ft²⋅°F</span>
                </td>
              </tr>
              <tr>
                <td>Door Frame Material</td>
                <td>
                  {editable === true ? (
                    <select
                      name='door_frame_material'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={
                        project.door_frame_material !== undefined
                          ? project.door_frame_material
                          : '-None-'
                      }
                    >
                      <option value=''></option>
                      <option value='Aluminum'>Aluminum</option>
                      <option value='Aluminum Clad Wood'>
                        Aluminum Clad Wood
                      </option>
                      <option value='Fiberglass'>Fiberglass</option>
                      <option value='uPVC (Unplasticized Polyvinyl Chloride)'>
                        uPVC (Unplasticized Polyvinyl Chloride)
                      </option>
                      <option value='Vinyl'>Vinyl</option>
                      <option value='Wood'>Wood</option>
                      <option value='Other'>Other</option>
                    </select>
                  ) : (
                    <input
                      name='door_frame_material'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.door_frame_material}
                    />
                  )}
                  {/* {editable === true ? (
                    <input
                      name='door_frame_material'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.door_frame_material}
                    />
                  ) : (
                    <input
                      name='door_frame_material'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.door_frame_material}
                    />
                  )} */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div label='Ventilation, Heating & Cooling Equipment'>
          <table className='project-detail-table'>
            <tbody>
              {/* <tr>
                <td>Phius Certified Ventilation System</td>
                <td className="red">unsure of field</td>
              </tr> */}
              <tr>
                <td>Ventilation System Manufacturer</td>
                <td>
                  {editable === true ? (
                    <input
                      name='ventilation_system_manufacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.ventilation_system_manufacturer}
                    />
                  ) : (
                    <input
                      name='ventilation_system_manufacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.ventilation_system_manufacturer}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Ventilation Product Name</td>
                <td>
                  {editable === true ? (
                    <input
                      name='ventilation_system_product_name'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.ventilation_system_product_name}
                    />
                  ) : (
                    <input
                      name='ventilation_system_product_name'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.ventilation_system_product_name}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Ventilation System Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='ventilation_system_type'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.ventilation_system_type}
                    >
                      {/* ERV, HRV, Multifunction, Other */}
                      <option value=''></option>
                      <option value='ERV'>ERV</option>
                      <option value='HRV'>HRV</option>
                      <option value='Multifunction'>Multifunction</option>
                      <option value='Other'>Other</option>
                    </select>
                  ) : (
                    project.ventilation_system_type
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Sensible Recovery Efficiency</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='sensible_recovery_efficiency'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.sensible_recovery_efficiency}
                    />
                  ) : (
                    <input
                      name='sensible_recovery_efficiency'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.sensible_recovery_efficiency}
                    />
                  )}{' '}
                  <span className='unit-label'>%</span>
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Latent Recovery Efficiency</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='latent_recovery_efficiency'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.latent_recovery_efficiency}
                    />
                  ) : (
                    <input
                      name='latent_recovery_efficiency'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.latent_recovery_efficiency}
                    />
                  )}{' '}
                  <span className='unit-label'>%</span>
                </td>
              </tr>
              {/* measured fan effcienct: number */}
              <tr>
                {/* picklist */}
                <td>Ventilation System Layout</td>
                <td>
                  {editable === true ? (
                    <select
                      name='ventilation_system_layout'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.ventilation_system_layout}
                    >
                      <option value=''></option>
                      <option value='Centralized'>Centralized</option>
                      <option value='Semi-Centralized'>Semi-Centralized</option>
                      <option value='De-Centralized'>De-Centralized</option>
                    </select>
                  ) : (
                    project.ventilation_system_layout
                  )}
                </td>
              </tr>
              <tr>
                <td>Heating System Manufacturer</td>
                <td>
                  {editable === true ? (
                    <input
                      name='heating_system_manufacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.heating_system_manufacturer}
                    />
                  ) : (
                    <input
                      name='heating_system_manufacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.heating_system_manufacturer}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Heating System Product Name</td>
                <td>
                  {editable === true ? (
                    <input
                      name='heating_system_product_name'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.heating_system_product_name}
                    />
                  ) : (
                    <input
                      name='heating_system_product_name'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.heating_system_product_name}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Heating System Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='heating_system_type'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.heating_system_type}
                    >
                      <option value=''></option>
                      <option value='Air Source Heat Pump'>
                        Air Source Heat Pump
                      </option>
                      <option value='Variable Refrigerant Flow (VRF)'>
                        Variable Refrigerant Flow (VRF)
                      </option>
                      <option value='Furnace'>Furnace</option>
                      <option value='Boiler'>Boiler</option>
                      <option value='Wood Stove'>Wood Stove</option>
                      <option value='Pellet Stove'>Pellet Stove</option>
                      <option value='Ground Source Heat Pump'>
                        Ground Source Heat Pump
                      </option>
                      <option value='Water Source Heat Pump'>
                        Water Source Heat Pump
                      </option>
                    </select>
                  ) : (
                    project.heating_system_type
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Heating System Rating</td>
                <td>
                  {editable === true ? (
                    <select
                      name='heating_system_rating'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.heating_system_rating}
                    >
                      <option value=''></option>
                      <option value='% Efficiency'>% Efficiency</option>
                      <option value='COP'>COP</option>
                    </select>
                  ) : (
                    project.heating_system_rating
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Heating System Performance Efficiency</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='heating_system_performance_efficiency'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.heating_system_performance_efficiency}
                    />
                  ) : (
                    <input
                      name='heating_system_performance_efficiency'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.heating_system_performance_efficiency}
                    />
                  )}{' '}
                  <span className='unit-label'>%</span>
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Heating System Performance (COP)</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='heating_system_performance_cop'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.heating_system_performance_COP}
                    />
                  ) : (
                    <input
                      name='heating_system_performance_cop'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.heating_system_performance_COP}
                    />
                  )}
                </td>
              </tr>
              {/* <tr>
                <td>Cooling System</td>
                <td >{project.cooling_system_product_name}</td>
              </tr> */}
              <tr>
                <td>Cooling System Manufacturer</td>
                <td>
                  {editable === true ? (
                    <input
                      name='cooling_system_manfacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.cooling_system_manufacturer}
                    />
                  ) : (
                    <input
                      name='cooling_system_manfacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.cooling_system_manufacturer}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Cooling System Product Name</td>
                <td>
                  {editable === true ? (
                    <input
                      name='cooling_system_product_name'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.cooling_system_product_name}
                    />
                  ) : (
                    <input
                      name='cooling_system_product_name'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.cooling_system_product_name}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Cooling System Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='cooling_system_type'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.cooling_system_type}
                    >
                      {/* Air Source Heat Pump, Variable Refrigerant Flow (VRF), Ground Source Heat Pump, Water Source Heat Pump */}
                      <option value=''></option>
                      <option value='Air Source Heat Pump'>
                        Air Source Heat Pump
                      </option>
                      <option value='Variable Refrigerant Flow (VRF)'>
                        Variable Refrigerant Flow (VRF)
                      </option>
                      <option value='Ground Source Heat Pump'>
                        Ground Source Heat Pump
                      </option>
                      <option value='Water Source Heat Pump'>
                        Water Source Heat Pump
                      </option>
                    </select>
                  ) : (
                    project.cooling_system_type
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Cooling System Rating</td>
                <td>
                  {editable === true ? (
                    <select
                      name='cooling_system_rating'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.cooling_system_rating}
                    >
                      {/* Air Source Heat Pump, Variable Refrigerant Flow (VRF), Ground Source Heat Pump, Water Source Heat Pump */}
                      <option value=''></option>
                      <option value='IEER'>IEER</option>
                      <option value='EER'>EER</option>
                      {/* <option value="SEER">SEER</option> */}
                    </select>
                  ) : (
                    project.cooling_system_rating
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Cooling System Performance (IEER)</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='cooling_system_performance_IEER'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={Math.floor(
                        project.cooling_system_performance_IEER
                      )}
                    />
                  ) : (
                    <input
                      name='cooling_system_performance_IEER'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={Math.floor(
                        project.cooling_system_performance_IEER
                      )}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Cooling System Performance (EER)</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='cooling_system_performance_EER'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={Math.floor(project.cooling_system_performance_EER)}
                    />
                  ) : (
                    <input
                      name='cooling_system_performance_EER'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={Math.floor(project.cooling_system_performance_EER)}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div label='Other Building Equipment, Renewable Energy'>
          <table className='project-detail-table'>
            <tbody>
              <tr>
                <td>Hot Water System Manufacturer</td>
                <td>
                  {editable === true ? (
                    <input
                      name='hot_water_system_manufacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.hot_water_system_manufacturer}
                    />
                  ) : (
                    <input
                      name='hot_water_system_manufacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.hot_water_system_manufacturer}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Hot Water System Product Name</td>
                <td>
                  {editable === true ? (
                    <input
                      name='hot_water_system_product_name'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.hot_water_system_product_name}
                    />
                  ) : (
                    <input
                      name='hot_water_system_product_name'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.hot_water_system_product_name}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Hot Water System Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='hot_water_system_type'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.hot_water_system_type}
                    >
                      {/* Electric Water Heater, Solar Hot Water, Heat Pump Water Heater, Natural Gas Water Heater, Tankless Electric Water Heater, Tankless Gas Water Heater */}
                      <option value=''></option>
                      <option value='Electric Water Heater'>
                        Electric Water Heater
                      </option>
                      <option value='Solar Hot Water'>Solar Hot Water</option>
                      <option value='Heat Pump Water Heater'>
                        Heat Pump Water Heater
                      </option>
                      <option value='Natural Gas Water Heater'>
                        Natural Gas Water Heater
                      </option>
                      <option value='Tankless Electric Water Heater'>
                        Tankless Electric Water Heater
                      </option>
                      <option value='Tankless Gas Water Heater'>
                        Tankless Gas Water Heater
                      </option>
                    </select>
                  ) : (
                    project.hot_water_system_type
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Hot Water System Rating</td>
                <td>
                  {editable === true ? (
                    <select
                      name='hot_water_system_rating'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.hot_water_system_rating}
                    >
                      <option value=''></option>
                      <option value='Uniform Energy Factor (UEF)'>
                        Uniform Energy Factor (UEF)
                      </option>
                      <option value='Energy Factor (EF)'>
                        Energy Factor (EF)
                      </option>
                    </select>
                  ) : (
                    project.hot_water_system_rating
                  )}
                </td>
              </tr>
              <tr>
                <td>Hot Water System Performance</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='hot_water_system_performance_rating'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.hot_water_system_performance_rating}
                    />
                  ) : (
                    <input
                      name='hot_water_system_performance_rating'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.hot_water_system_performance_rating}
                    />
                  )}
                </td>
              </tr>
              {/* <tr>
                <td>Hot Water System Efficiency</td>
                <td >{editable===true ? <input type="number" name="hot_water_system_performance_rating" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.hot_water_system_performance_rating} /> : <input name="hot_water_system_performance_rating" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.hot_water_system_performance_rating} />}</td>
              </tr> */}
              <tr>
                {/* picklist */}
                <td>Hot Water System Layout</td>
                <td>
                  {editable === true ? (
                    <select
                      name='hot_water_system_layout'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.hot_water_system_layout}
                    >
                      <option value=''></option>
                      <option value='Centralized'>Centralized</option>
                      <option value='Semi-Centralized'>Semi-Centralized</option>
                      <option value='De-Centralized'>De-Centralized</option>
                    </select>
                  ) : (
                    project.hot_water_system_layout
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Renewable Energy Type(s)</td>
                <td>
                  {editable === true ? (
                    project.renewable_energy_types !== null ||
                    project.renewable_energy_types.length !== 0 ? (
                      <Select
                        name='renewable_energy_types'
                        defaultValue={project.renewable_energy_types.map(
                          (type) => ({ value: type, label: type })
                        )}
                        onChange={(option) =>
                          handleSelectChange('renewable_energy_types', option)
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        disabled={!editable}
                        options={[
                          { value: 'On-Site PV', label: 'On-Site PV' },
                          { label: 'Off-Site PV', value: 'Off-Site PV' },
                          {
                            label: 'Community Solar',
                            value: 'Community Solar',
                          },
                          { label: 'Virtual PPA', value: 'Virtual PPA' },
                          { label: 'RECs', value: 'RECs' },
                        ]}
                      />
                    ) : (
                      <Select
                        name='renewable_energy_types'
                        onChange={(option) =>
                          handleSelectChange('renewable_energy_types', option)
                        }
                        closeMenuOnSelect={false}
                        isMulti={true}
                        disabled={!editable}
                        options={[
                          { value: 'On-Site PV', label: 'On-Site PV' },
                          { label: 'Off-Site PV', value: 'Off-Site PV' },
                          {
                            label: 'Community Solar',
                            value: 'Community Solar',
                          },
                          { label: 'Virtual PPA', value: 'Virtual PPA' },
                          { label: 'RECs', value: 'RECs' },
                        ]}
                      />
                    )
                  ) : (
                    project.renewable_energy_types.join(', ')
                  )}
                </td>
                {/* <td >{editable===true ? <input name="renewable_energy_types" onChange={e => handleInputChange(e)} className="project-detail-input" value={project.renewable_energy_types} /> : <input name="renewable_energy_types" onChange={e => handleInputChange(e)} className="project-detail-input" disabled value={project.renewable_energy_types} />}</td> */}
              </tr>
              <tr>
                <td>Renewable Energy Manufacturer</td>
                <td>
                  {editable === true ? (
                    <input
                      name='renewable_energy_manufacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.renewable_energy_manufacturer}
                    />
                  ) : (
                    <input
                      name='renewable_energy_manufacturer'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.renewable_energy_manufacturer}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Renewable Energy System Size</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='renewable_energy_system_size'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.renewable_energy_system_size}
                    />
                  ) : (
                    <input
                      name='renewable_energy_system_size'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.renewable_energy_system_size}
                    />
                  )}
                </td>
              </tr>
              <tr>
                {/* number */}
                <td>Renewable Energy Annual Production</td>
                <td>
                  {editable === true ? (
                    <input
                      type='number'
                      name='renewable_energy_annual_production'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      value={project.renewable_energy_annual_production}
                    />
                  ) : (
                    <input
                      name='renewable_energy_annual_production'
                      onChange={(e) => handleInputChange(e)}
                      className='project-detail-input'
                      disabled
                      value={project.renewable_energy_annual_production}
                    />
                  )}{' '}
                  <span className='unit-label'>kWh/yr</span>
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Range Hood Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='range_hood_type'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.range_hood_type}
                    >
                      <option value=''></option>
                      <option value='Recirculation'>Recirculation</option>
                      <option value='Exhaust'>Exhaust</option>
                    </select>
                  ) : (
                    project.range_hood_type
                  )}
                </td>
              </tr>
              <tr>
                {/* picklist */}
                <td>Dryer Type</td>
                <td>
                  {editable === true ? (
                    <select
                      name='dryer_type'
                      onChange={(e) => handleInputChange(e)}
                      className='edit-input'
                      defaultValue={project.dryer_type}
                    >
                      <option value=''></option>
                      <option value='Vented'>Vented</option>
                      <option value='Non-Vented'>Non-Vented</option>
                    </select>
                  ) : (
                    project.dryer_type
                  )}
                </td>
              </tr>
              {/* <tr>
                <td>Green Building Verification Program</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Verification Body</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Year Verified</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Verification Rating</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Verification Status</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Verification Metric</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Verification URL</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Energy Generation</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Indoor Air Quality</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Water Conservation</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Energy Efficiency</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Sustainablilty</td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr>
              <tr>
                <td>Green Location </td>
                <td >{editable===true ? <input className="project-detail-input red" name="" value="missing green fields in DB" /> : <input className="project-detail-input red" disabled name="" value="missing green fields in DB" />}</td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div label='Photos'>
          {/* <table className="project-detail-table"> */}
          {/* <tbody> */}
          <div className='flex-center text-center w100'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://forms.zohopublic.com/phius/form/UploadImagestoProject/formperma/j23GEUc9it099122Kcx7gpTGWt15gFR1Xq3hV398nag'
              className='my-1 cta-button remove-text-decoration'
            >
              UPLOAD PHOTOS
            </a>
          </div>
          {/* <tr>
                <td>Contruction Photos</td>
                <td >{editable===true ? <input className="project-detail-input red" value="Photos Unavailable" /> : <input className="project-detail-input red" disabled value="Photos Unavailable" />}</td>
              </tr>
              <tr>
                <td>Photos</td>
                <td >{editable===true ? <input className="project-detail-input red" value="Photos Unavailable" /> : <input className="project-detail-input red" disabled value="Photos Unavailable" />}</td>
              </tr> */}
          {/* </tbody> */}
          {/* </table> */}
        </div>
        {/* <table className="detailTable">
                <tbody>
                  <tr>
                  <th>Product No.</th>
                  <td>{project.number}</td>
                  </tr>
                  <tr>
                  <th>Product</th>
                  <td>{project.title}</td>
                  </tr>
                  <tr>
                  <th>Status</th>
                  <td>{project.status}</td>
                  </tr>
                  <tr>
                  <th>Lead CPHC</th>
                  <td>{project.leadcphc}</td>
                  </tr>
                  <tr>
                    <th>Builder</th>
                    <td>{project.builder}</td>
                    </tr>
                    <tr>
                    <th>Location</th>
                    <td>{project.location}</td>
                    </tr>
                    <tr>
                    <th>Construction Type</th>
                    <td>{project.constructiontype}</td>
                    </tr>
                    <tr>
                    <th>Building Function</th>
                    <td>{project.bldgfunction}</td>
                    </tr>
                    <tr>
                    <th>Floor Area</th>
                    <td>{project.floorarea}</td>
                    </tr>
                    <tr>
                    <th>Product Type</th>
                    <td>{project.projecttype}</td>
                    </tr>
                    </tbody>
                    </table>
                  <div>{project.description}</div> */}
      </Tabs>
      {/* </div> */}
    </form>
  );
}
