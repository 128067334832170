import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EventService from '../../services/EventService';
import UserContext from '../../contexts/UserContext';

const EventsReport = () => {
  const userContext = useContext(UserContext);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [myEvents, setMyEvents] = useState([]);
  const [myPastEvents, setMyPastEvents] = useState([]);
  // useEffect(() => {
  const userType = userContext.currentUser.profile_type;
  // console.log(userType);
  let upcomingEventsBuffer;
  let myEventsBuffer;
  let myPastEventsBuffer;
  let eventList;
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const sortEvents = (evts) => {
    // console.log(evts)
    if (evts === undefined || evts === null) return [];
    return evts.sort(function (a, b) {
      let keyA =
          a.start_date !== undefined
            ? new Date(a.start_date)
            : new Date(a.Start_Date),
        keyB =
          b.start_date !== undefined
            ? new Date(b.start_date)
            : new Date(b.Start_Date);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  };

  const reverseSortEvents = (evts) => {
    // console.log(evts)
    if (evts === undefined || evts === null) return [];
    return evts.sort(function (a, b) {
      let keyA =
          a.start_date !== undefined
            ? new Date(a.start_date)
            : new Date(a.Start_Date),
        keyB =
          b.start_date !== undefined
            ? new Date(b.start_date)
            : new Date(b.Start_Date);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
  };

  const isUpcoming = (startDate) => {
    const now = new Date();
    const date = new Date(`${startDate}T23:00:00`);
    // console.log(date, " | ", now)
    // console.log(date >= now)
    return date >= now;
  };
  const isPast = (event) => {
    // console.log(event.start_date)
    const now = new Date();
    const date =
      event.start_Date !== undefined
        ? new Date(`${event.start_date}T23:00:00`)
        : new Date(`${event.Start_Date}T23:00:00`);
    console.log(now, date);
    return date < now;
  };
  const getEvents = () => {
    EventService.getAllEvents().then((evts) => {
      // all upcoming events
      // console.log(evts);
      eventList = sortEvents(evts.events);
      // console.log('eventlist: ', eventList);
      upcomingEventsBuffer = eventList
        .filter((e) => isUpcoming(e.start_date))
        .map((event) => {
          // console.log('Upcoming event: ', event);
          let startDate = new Date(
            `${event.start_date}T00:00:00`
          ).toLocaleDateString();
          let endDate = new Date(
            `${event.end_date}T00:00:00`
          ).toLocaleDateString();
          return (
            <div key={event.id} className='events-headers bolder-font'>
              {/* Event Name */}
              <div>{nameLinkUpcomingEvents(event)}</div>
              {/* 
                <Link
                  to={`/details/event/${event.id}`}
                  className='remove-text-decoration teal-link'
                >
                  {event.campaign_name}
                </Link> */}

              {/* category */}
              <div>{event.category}</div>
              {/* Event Start Date */}
              <div>{startDate}</div>
              {/* Event End Date */}
              <div>{endDate}</div>
              {/* CEUs*/}
              <div>{event.ceus}</div>
              {/* Event Mode/Format */}
              <div>{event.event_mode}</div>
              {/* Event Location */}
              <div>{event.location}</div>
              {/* Registration Link */}
              {/* {userType === 'Member' && event.members_only == true ? (
                <a
                  className='remove-text-decoration teal-link'
                  target='_blank'
                  rel='noreferrer'
                  href={event.member_website_link}
                >
                  Register
                </a>
              ) : (
                <a
                  className='remove-text-decoration teal-link'
                  target='_blank'
                  rel='noreferrer'
                  href={event.website_link}
                >
                  Register
                </a>
              )} */}
              {registerLinkUpcomingEvents(event)}
            </div>
          );
        });
      if (upcomingEventsBuffer.length == 0) {
        upcomingEventsBuffer = (
          <div key='000' className='events-headers bolder-font'>
            {/* Event Name */}
            <div></div>
            {/* Event Type */}
            <div></div>
            {/* Event Start Date */}
            <div></div>
            {/* Event End Date */}
            <div>No Events Found</div>
            {/* Bottom Right Tag */}
            <div></div>
            {/* Event Event Location */}
            <div></div>
            {/* Event Status */}
            <div></div>
            {/* Registration Link */}
            {/* <a className="remove-text-decoration teal-link" target="_blank" rel="noreferrer" href={`https://phius1.zohobackstage.com/${event.name}`}>Register</a> */}
          </div>
        );
      }
      // console.log('upcomingEventsBuffer', upcomingEventsBuffer);
      setUpcomingEvents(upcomingEventsBuffer);
    });
    return;
  };
  const getMyEvents = () => {
    EventService.getMyEvents().then((evts) => {
      // console.log(evts)
      if (evts.events === undefined) {
        myEventsBuffer = (
          <div key='000' className='events-headers bolder-font'>
            {/* Event Name */}
            <div></div>
            {/* Event Type */}
            <div></div>
            {/* Event Start Date */}
            <div></div>
            {/* Event End Date */}
            <div>No Events Found</div>
            {/* Bottom Right Tag */}
            <div></div>
            {/* Event Event Location */}
            <div></div>
            {/* Event Status */}
            <div></div>
            {/* Registration Link */}
            {/* <a className="remove-text-decoration teal-link" href={`https://phius1.zohobackstage.com/${event.name}`}>Register</a> */}
          </div>
        );
        setMyEvents(myEventsBuffer);
        setMyPastEvents(myEventsBuffer);
        return;
      }
      eventList = sortEvents(evts.events);
      // console.log(eventList)
      // my upcoming events
      myEventsBuffer = eventList
        .filter((e) => isUpcoming(e.Start_Date))
        .map((event) => {
          console.log(event);
          let startDate = new Date(
            `${event.Start_Date}T00:00:00`
          ).toLocaleDateString();
          let endDate = new Date(
            `${event.End_Date}T00:00:00`
          ).toLocaleDateString();
          return (
            <div key={event.id} className='events-headers bolder-font'>
              {/* Event Name */}
              <div>
                {/* <Link
                  to={`/details/event/${event.id}`}
                  className='remove-text-decoration teal-link'
                > */}
                {event.Campaign_Name}
                {/* </Link> */}
              </div>
              {/* Event Type */}
              <div>{event.Category}</div>
              {/* Event Start Date */}
              <div>{startDate}</div>
              {/* Event End Date */}
              <div>{endDate}</div>
              {/* CEUs*/}
              <div>{event.CEUs}</div>
              {/* Event Mode/Format */}
              <div>{event.Event_Mode}</div>
              {/* Event Location */}
              <div>{event.Location}</div>
              {/* Registration Link */}
              {/* {console.log(event.type)} */}
              {/* {userType == 'Member' && event.Alliance_Member_Only == true ? (
                <a
                  className='remove-text-decoration teal-link'
                  target='_blank'
                  rel='noreferrer'
                  href={event.Member_Website_Link}
                >
                  My Registration
                </a>
              ) : (
                <a
                  className='remove-text-decoration teal-link'
                  target='_blank'
                  rel='noreferrer'
                  href={event.Website_Link}
                >
                  My Registration
                </a>
              )} */}
              {registerLinkMyEvents(event)}
            </div>
          );
        });
      if (myEventsBuffer.length == 0) {
        myEventsBuffer = (
          <div key='000' className='events-headers bolder-font'>
            {/* Event Name */}
            <div></div>
            {/* Event Type */}
            <div></div>
            {/* Event Start Date */}
            <div></div>
            {/* Event End Date */}
            <div>No Events Found</div>
            {/* Bottom Right Tag */}
            <div></div>
            {/* Event Event Location */}
            <div></div>
            {/* Event Status */}
            <div></div>
            {/* Registration Link */}
            {/* <a className="remove-text-decoration teal-link" href={`https://phius1.zohobackstage.com/${event.name}`}>Register</a> */}
          </div>
        );
      }
      // console.log('myEventsBuffer', myEventsBuffer);
      setMyEvents(myEventsBuffer);
      // my past events
      const pastEventList = reverseSortEvents(evts.events);
      myPastEventsBuffer = pastEventList.filter(isPast).map((event) => {
        let startDate = new Date(
          `${event.Start_Date}T00:00:00`
        ).toLocaleDateString();
        let endDate = new Date(
          `${event.End_Date}T00:00:00`
        ).toLocaleDateString();
        return (
          <div key={event.id} className='past-events-headers bolder-font'>
            {/* Event Name */}
            <div>
              {/* <Link
                to={`/details/event/${event.id}`}
                className='remove-text-decoration teal-link'
              > */}
              {event.Campaign_Name}
              {/* </Link> */}
            </div>
            {/* Event Type */}
            <div>{event.Category}</div>
            {/* Event Start Date */}
            <div>{startDate}</div>
            {/* Event End Date */}
            <div>{endDate}</div>
            {/* Bottom Right Tag */}
            <div>{event.CEUs}</div>
            {/* <div>{event.Alliance_Member_Only ? "Members Only" : "Open"}</div> */}
            {/* Event Event Location */}
            <div>{event.Status}</div>
            <div>{event.Location}</div>
            {/* Event Status */}
            {/* Registration Link */}
            {/* <a className="remove-text-decoration teal-link" target="_blank" rel="noopener noreferrer" href={`https://forms.zohopublic.com/phius/form/SelfReportCEUs/formperma/vmEEjm8Jympa7RSb2OUOCYaN1fSYqFo4TBMTZKSbEr0`}>Report Attendance</a> */}
          </div>
        );
      });

      if (myPastEventsBuffer.length == 0) {
        myPastEventsBuffer = (
          <div key='000' className='past-events-headers bolder-font'>
            {/* Event Name */}
            <div></div>
            {/* Event Type */}
            <div></div>
            {/* Event Start Date */}
            <div></div>
            {/* Event End Date */}
            <div>No Events Found</div>
            {/* Bottom Right Tag */}
            <div></div>
            {/* Event Event Location */}
            <div></div>
            {/* Event Status */}
            <div></div>
            {/* Registration Link */}
            {/* <a className="remove-text-decoration teal-link" href={`https://phius1.zohobackstage.com/${event.name}`}>Register</a> */}
          </div>
        );
      }
      // console.log('myPastEvents', myPastEventsBuffer);
      setMyPastEvents(myPastEventsBuffer);
    });
    return;
  };
  // useEffect(() => {
  //   getEvents()
  //   getMyEvents()
  // }, [])
  useEffect(() => {
    if (myEvents.length === 0) {
      getMyEvents();
    }
    if (upcomingEvents.length === 0) {
      getEvents();
    }
  }, [myEvents, upcomingEvents]);
  // }, [])
  // console.log("UE:", upcomingEvents)
  // console.log("ME", myEvents)
  // console.log("pE", myPastEvents)
  const renderMyEvents = (
    <>
      <div className='card-header-grey display-flex'>
        <p className='card-header-title-style-gray'>My Events</p>
        {/* <Link className="remove-text-decoration teal-link card-header-title-style-gray" to="#">Add</Link> */}
      </div>
      <div className='card-body-report h30'>
        <h3 className='report-subheader'>Upcoming Events</h3>
        <div className='events-headers'>
          <p className='projects-and-products-headers-text-color-grey'>
            Event Name
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Category
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Start Date
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            End Date
          </p>
          <p className='projects-and-products-headers-text-color-grey'>CEUs</p>
          <p className='projects-and-products-headers-text-color-grey'>
            Format
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Location
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            My Registration
          </p>
        </div>
        <div className='overflow-container report'>{myEvents}</div>
      </div>
      <div className='card-body-report h30'>
        <div className='flexbox-row-with-space-between report-subheader'>
          <h3>Past Events</h3>
          <a
            className='cta-button6 remove-text-decoration teal-link'
            target='_blank'
            rel='noopener noreferrer'
            href={`https://forms.zohopublic.com/phius/form/SelfReportCEUs/formperma/vmEEjm8Jympa7RSb2OUOCYaN1fSYqFo4TBMTZKSbEr0`}
          >
            Report Attendance
          </a>
        </div>
        <div className='past-events-headers'>
          <p className='projects-and-products-headers-text-color-grey'>
            Event Name
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Category
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Start Date
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            End Date
          </p>
          <p className='projects-and-products-headers-text-color-grey'>CEUs</p>
          <p className='projects-and-products-headers-text-color-grey'>
            Format
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Location
          </p>
          {/* <p className="projects-and-products-headers-text-color-grey">Report Attendance</p> */}
        </div>
        <div className='overflow-container report'>{myPastEvents}</div>
      </div>
    </>
  );

  const nameLinkUpcomingEvents = (event) => {
    if (userType == 'Member' && event.members_only == true) {
      return (
        <Link
          to={`/details/event/${event.id}`}
          className='remove-text-decoration teal-link'
        >
          {event.campaign_name}
        </Link>
      );
    } else if (userType != 'Member' && event.members_only == true) {
      return <p>{event.campaign_name}</p>;
    } else if (event.members_only == false) {
      return (
        <Link
          to={`/details/event/${event.id}`}
          className='remove-text-decoration teal-link'
        >
          {event.campaign_name}
        </Link>
      );
    }
  };

  const registerLinkUpcomingEvents = (event) => {
    // if (userType == 'Member' && event.members_only == true) {
    if (userType == 'Member') {
      return (
        <a
          className='remove-text-decoration teal-link'
          target='_blank'
          rel='noreferrer'
          href={event.member_website_link}
        >
          Register
        </a>
      );
    } else if (userType != 'Member' && event.members_only == true) {
      return <p>Members Only</p>;
      // } else if (event.members_only == false) {
    } else if (userType != 'Member' && event.members_only == false) {
      return (
        <a
          className='remove-text-decoration teal-link'
          target='_blank'
          rel='noreferrer'
          href={event.website_link}
        >
          Register
        </a>
      );
    }
  };

  const registerLinkMyEvents = (event) => {
    // if (userType == 'Member' && event.Alliance_Member_Only == true) {
    if (userType == 'Member') {
      return (
        <a
          className='remove-text-decoration teal-link'
          target='_blank'
          rel='noreferrer'
          href={event.Member_Website_Link}
        >
          My Registration
        </a>
      );
    } else if (userType != 'Member' && event.Alliance_Member_Only == true) {
      return <p>Members Only</p>;
    } else if (userType != 'Member' && event.Alliance_Member_Only == false) {
      return (
        <a
          className='remove-text-decoration teal-link'
          target='_blank'
          rel='noreferrer'
          href={event.Website_Link}
        >
          My Registration
        </a>
      );
    }
  };

  return (
    <>
      {/* <div className='card-container'> */}
      <div className='card-header-grey display-flex'>
        <p className='card-header-title-style-gray'>All Events</p>
        {/* <Link className="remove-text-decoration teal-link card-header-title-style-gray" to="#">Add</Link> */}
      </div>
      <div className='card-body-report h30'>
        <h3 className='report-subheader'>Upcoming Events</h3>
        <div className='events-headers'>
          <p className='projects-and-products-headers-text-color-grey'>
            Event Name
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Category
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Start Date
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            End Date
          </p>
          <p className='projects-and-products-headers-text-color-grey'>CEUs</p>
          <p className='projects-and-products-headers-text-color-grey'>
            Format
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Location
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Registration Link
          </p>
        </div>
        <div className='overflow-container report'>{upcomingEvents}</div>
      </div>
      {/* my events */}
      {Object.keys(userContext.currentUser).length > 0 ? renderMyEvents : ''}
      {/* </div> */}
    </>
  );
};

export default EventsReport;
