import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function ManageProject(props) {
  const [keyword,setKeyword] = useState("");

  return (
    <div className="card-container-projectapp">
      <div className="card-header-gray">
        <h3>Find Projects, Products & Professionals</h3>
      </div>
      <div className="card-body flex-column">
        <div className="flexbox-row-with-space-between">
          <div className="width-100">
            <input onChange={e => setKeyword(e.target.value)} className="body-input width-100" value={keyword} type="text" placeholder="Search projects, products and professionals..."></input>
          </div>
          <Link to={`/search/${keyword}`} >
            <button className="cta-button height-100-percent borderbox" style={{borderRadius:"0 5% 5% 0"}}>SEARCH</button>
          </Link>
        </div>
        <Link to="/search/"><button className="cta-button2 top-margin">BROWSE ALL</button></Link>
      </div>
    </div>
  )
}
