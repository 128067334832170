import React, { Component } from 'react'

export class AskCommunity extends Component {
    render() {
        return (
            <div className="card-container">
                <div className="card-header-gray">
                    <h3>Ask the Community</h3>
                </div>
                <div className="card-body">
                    <p className="body-text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <button className="cta-button"><a style={{textDecoration:"none", color:"white"}} href="https://desk.zoho.com/portal/phiusportal/en/signin">GO TO FORUM</a></button>
                </div>
            </div>
        )
    }
}

export default AskCommunity
