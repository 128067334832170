import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './comingSoonPage.css'
// components

export default function comingSoonPage() {
  return (
    <div className="card-body coming-soon-page">
      <div className="flex-column flex-center background-opacity">
      <FontAwesomeIcon icon="hard-hat" size='7x' />
        {/* <h2 className="not-found-number text-center">404</h2> */}
        <h3 className="not-found-text text-center">This page is under construction, check back soon!</h3>
      </div>
      {/* <h2 className="not-found-text text-center">The page requested could not be found.</h2> */}
    </div>
  )
}
