import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ProjectService from '../../services/ProjectService'


const ProjectsReport = () => {
  const [projects, setProjects] = useState([])
  useEffect(() => {

    ProjectService.getAllProjects()
      .then(projects => {
        // console.log(projects.projects)
        let projectsList = projects.projects.map(project => {
          console.log(project)
          return (<div key={project.id} className="projects-headers bolder-font">
          <div>{project.project_number}</div>
          <div><Link to={`/details/project/${project.id}`} className="remove-text-decoration card-header-title-style-teal">{project.project_name}</Link></div>
          <div>{project.status}</div>
          <div>{project.lead_CPHC_contact_name}</div>
          <div>{project.phius_certified_rater_contact_name}</div>
          <div>{project.project_submitter_contact_name}</div>
          <div>{project.building_function}</div>
          <div>{project.program_version}</div>
          <div>{project.interior_conditioned_floor_area}</div>
          <div>{project.primary_reviewer_contact_name}</div>
          <div>{project.secondary_reviewer_contact_name}</div>
        </div>
        )
        })
        setProjects(projectsList)
      })
  }, [])

  return (
    <section>
      <div className="card-container" >
        <div className="card-header-grey display-flex">
          <p className="card-header-title-style-gray">Projects</p>
          {/* <Link className="remove-text-decoration card-header-title-style-gray" to="#">Add</Link> */}
        </div>
        <div className="card-body h30">
          <div className="projects-headers">
            <p className="projects-and-products-headers-text-color-grey">No.</p>
            <p className="projects-and-products-headers-text-color-grey">Name</p>
            <p className="projects-and-products-headers-text-color-grey">Status</p>
            <p className="projects-and-products-headers-text-color-grey">Lead CPHC</p>
            <p className="projects-and-products-headers-text-color-grey">Phius Certified Rater/Verifier</p>
            <p className="projects-and-products-headers-text-color-grey">Submitter</p>
            <p className="projects-and-products-headers-text-color-grey">Bldg. Function</p>
            <p className="projects-and-products-headers-text-color-grey">Program</p>
            <p className="projects-and-products-headers-text-color-grey">Project Type</p>
            <p className="projects-and-products-headers-text-color-grey">Interior Conditioned Floor Area</p>
            <p className="projects-and-products-headers-text-color-grey">Primary Reviewer</p>
            <p className="projects-and-products-headers-text-color-grey">Secondary Reviewer</p>
            {/* <p className="projects-and-products-headers-text-color-grey">Photo</p> */}
          </div>
          <div className="overflow-container report">
            {projects}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectsReport
