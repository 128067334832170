// save Node address in config file and import
// pre-express, use dummy json, delete and reconfigure functions once we build express db.
// import dummy from '../dummyData/dummy.json'
import config from '../config'
import TokenService from './token-service';
const UserService = {
// getUserById
async getAllContacts(){
  return fetch (`${config.API_ENDPOINT}/contacts`,{
    header:{
      'content-type':'application/json',
      'Authorization': TokenService.getAuthToken()
    }
  }).then((res) =>
  // console.log("then",res)
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
},
async getUserById(id){
  // console.log("get user by id")
  // this is what it should look like when we set up the express endpoints
  return fetch(`${config.API_ENDPOINT}/users/${id}`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': TokenService.getAuthToken()
    },
  }).then((res) =>
  // console.log("then",res)
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
  // dummy function, delete this part on express hook up
  // const users = dummy.users;
  // const user = users.filter(user =>user.id === id)
  // console.log("User " + user)
  // return user == null ? "No user found" : user[0];
},
getUserByToken(){
  return fetch(`${config.API_ENDPOINT}/users/user`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': TokenService.getAuthToken()
    },
  }).then((res) =>
  // console.log("then",res)
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
},
async updatePassword(credentials){
  return fetch(`${config.API_ENDPOINT}/auth/changepassword`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(credentials),
  }).then((res) =>
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
},
async resetPassword(email){
  // const credentials = {"email":email}
  console.log(email)
  return fetch(`${config.API_ENDPOINT}/auth/requestpasswordchange`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(email),
  }).then((res) =>
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
},
// decrypt invited user
async decryptUser(hash){
  console.log(hash)
  return fetch(`${config.API_ENDPOINT}/auth/validateInvitation/${hash}`,{
    method:'GET',
    headers:{
      'content-type':'application/json',
      'Access-Control-Allow-Origin': '*'
  }
  }).then((res) =>
  // console.log("then",res)
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
},
// getAllUsers
async getAllUsers(){
  // this is what it should look like when we set up the express endpoints
  return fetch(`${config.API_ENDPOINT}/users/`, {
    headers: {
      'content-type': 'application/json',
      // 'Authorization': TokenService.getAuthToken()
    },
  }).then((res) =>
  // console.log(res)
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
  // const users = {"users":dummy.users};
  // console.log("Users " + users)
  // return users == null ? "No users found" : users;
},
getUsersByKeyword(keyword){
  // function needs to be built in backend to query SQL
  // const users = dummy.users;
  // return users.filter(obj => Object.keys(obj).some(key => obj[key].toString().toLowerCase().includes(keyword.toLowerCase())));
  return fetch(`${config.API_ENDPOINT}/contacts/search/${keyword}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  }).then((res) =>
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
},
// log in existing user
postLogin(credentials){
  // console.log(JSON.stringify(credentials))
  return fetch(`${config.API_ENDPOINT}/auth/login`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(credentials),
  }).then((res) =>
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
},
postNewlogin(credentials){
  // console.log(JSON.stringify(credentials))
  return fetch(`${config.API_ENDPOINT}/auth/loginnew`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(credentials),
  }).then((res) =>
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
},
// createUser/register user
postUser(user){
  return fetch(`${config.API_ENDPOINT}/auth/register`, {
    method:'POST',
    headers:{
      'content-type':'application/json'
    },
    body:JSON.stringify(user)
  })
  .then(response => {
    return response.json().then(json => {
      return response.ok ? json : Promise.reject(json.error);
    });
  });
},
async updateUser(user){
  // const proj = dummy.users.find(proj => proj.id === Number(id))
  // // console.log(proj)
  // return {"user":proj}
  return fetch(`${config.API_ENDPOINT}/zoho/update/crm/contacts`, {
    method:'POST',
    body:JSON.stringify(user),
    headers: {
      'content-type': 'application/json',
      'Authorization': TokenService.getAuthToken()
    },
  }).then((res) =>
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
},
}

export default UserService;