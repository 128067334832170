import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import UserService from '../../services/UserService'


const ProfessionalsReport = () => {
  const [professionals, setProfessionals] = useState([]);
  useEffect(()=>{
  let professionalList;
  UserService.getAllUsers()
  .then(users => {
    professionalList = users.users.map(user => {
      // console.log(user.membertype)
      return (user.profile_type === "Professional") ? (
          <div className="professional-headers bolder-font" key={user.user_id}>
            <div>{user.first_name}</div>
            <div>{user.last_name}</div>
            <div>{user.city}</div>
            <div>{user.state}</div>
            <Link to={`/profile/${user.user_id}`} className="remove-text-decoration card-header-title-style-gray">Profile</Link>
          </div>
        ) : ''
    })
    setProfessionals(professionalList)
  })
},[])
    return (
      <section>
        <div className="card-container" >
          <div className="card-header-grey display-flex">
            <p className="card-header-title-style-gray">Professionals</p>
            <Link className="remove-text-decoration card-header-title-style-gray" to="/professionals">Add</Link>
          </div>
          <div className="card-body h30">
            <div className="professional-headers">
              <p className="projects-and-products-headers-text-color-grey">First Name</p>
              <p className="projects-and-products-headers-text-color-grey">Last Name</p>
              <p className="projects-and-products-headers-text-color-grey">City</p>
              <p className="projects-and-products-headers-text-color-grey">State</p>
              <p className="projects-and-products-headers-text-color-grey">Details</p>
            </div>
            <div className="overflow-container report">
              {professionals}
            </div>
          </div>
        </div>
      </section>
    )
}

export default ProfessionalsReport
