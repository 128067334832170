import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ProductService from '../../services/ProductService'


const ProductsReport = () => {
  const [products, setProducts] = useState([])
  useEffect(() => {
    ProductService.getAllProducts()
      .then(products => {
        console.log(products.certified_products)
        let productList = products.certified_products.map(product => {
          return (<div key={product.id} className="products-headers bolder-font">
            {/* Certified Product Name */}
            <div>{product.product_category}</div>
            <div><Link to={`/details/product/${product.id}`} className="remove-text-decoration card-header-title-style-teal">{product.certification_job_name}</Link></div>
            {/* Product Category */}
            {/* Product Type */}
            {/* if fenestration */}
            {/* else if ventiliator */}
            {/* else if panel system */}
            {/* else */}
            {/* <div>{product.type}</div> */}
            {/* Manufacturer */}
            <div>{product.crm_account_name}</div>
            {/* Certification Job Number */}
            <div>{product.certification_job_number}</div>
            {/* Certification Job Folder */}
            <div>{product.job_folder}</div>
            {/* Submitted By */}
            <div>{product.crm_contact_name}</div>
            {/* Status */}
            <div>{product.job_status}</div>
          </div>
          )
        })
        setProducts(productList)
      })
  }, [])
  return (
    <section>
      <div className="card-container" >
        <div className="card-header-grey display-flex">
          <p className="card-header-title-style-gray">Products</p>
          {/* <Link className="remove-text-decoration card-header-title-style-gray" to="#">Add</Link> */}
        </div>
        <div className="card-body h30">
          <div className="products-headers">
            <p className="projects-and-products-headers-text-color-grey">Product Category</p>
            <p className="projects-and-products-headers-text-color-grey">Certified Product Name</p>
            {/* <p className="projects-and-products-headers-text-color-grey">Product Type</p> */}
            <p className="projects-and-products-headers-text-color-grey">Manufacturer</p>
            <p className="projects-and-products-headers-text-color-grey">Certification Job Number</p>
            <p className="projects-and-products-headers-text-color-grey">Certification Job Folder</p>
            <p className="projects-and-products-headers-text-color-grey">Submitted By</p>
            <p className="projects-and-products-headers-text-color-grey">Status</p>
          </div>
          <div className="overflow-container report">
            {products}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductsReport
