import React, { Component } from 'react'
import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';



export class Conferences extends Component {

    
    render() {
        
        return (

            <div className="width-100">
            <div className="card-header-gray display-flex">
              <p className="card-header-title-style-gray">Upcoming Conferences</p>
              <p className="">View All</p>
            </div>
            <div className="card-body margin-auto">
                <Carousel
                    plugins={[
                        'infinite',
                        'centered',
                        
                        {
                            resolve: slidesToShowPlugin,
                            options: {
                                numberOfSlides: 5
                            }
                        },
                        
                        {
                            resolve: arrowsPlugin,
                            options: {
                              arrowLeft: <button style={{backgroundColor:"#0E2746", fontWeight:"bolder", color:"white", height:"50px", width:"50px"}}> &#60; </button>,
                              arrowLeftDisabled:<button> </button>,
                              arrowRight: <button style={{backgroundColor:"#0E2746", fontWeight:"bolder", color:"white", height:"50px", width:"50px"}}> &#62; </button>,
                              arrowRightDisabled: <button> </button>,
                              addArrowClickHandler: true,
                            }
                          }
                        
                    ]}
                    >
                    
                    <div className="flexbox-column width-150px">
                        <img
                            height="200px"
                            width="150px"
                            alt=""
                            src= "https://images.unsplash.com/photo-1557576146-047908becbb4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=150&q=200"
                        />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        </p>
                    </div>
                    
                    <div className="flexbox-column width-150px">
                        <img
                            height="200px"
                            width="150px"
                            alt=""
                            src="https://images.unsplash.com/photo-1568548527144-383edf5c2728?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=150&q=200"
                        />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        </p>
                    </div>

                    <div className="flexbox-column width-150px">
                        <img
                            height="200px"
                            width="150px"
                            alt=""
                            src="https://images.unsplash.com/photo-1584134046510-d193d66e7ae4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=150&q=200"/
                        >
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        </p>
                    </div>
                    
                    <div className="flexbox-column width-150px">
                        <img
                            height="200px"
                            width="150px"
                            alt=""
                            src="https://images.unsplash.com/photo-1610072947120-8736bbfc56e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=150&q=200"
                        />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        </p>
                    </div>

                    <div className="flexbox-column width-150px">
                    <img
                        height="200px"
                        width="150px"
                        alt=""
                        src="https://images.unsplash.com/photo-1562791241-122a7c501434?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=150&q=200"
                    />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        </p>
                    </div>


                    <div className="flexbox-column width-150px">
                    <img
                        height="200px"
                        width="150px"
                        alt=""
                        src="https://images.unsplash.com/photo-1484335629320-0e089b87a106?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=150&q=200"
                    />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        </p>
                    </div>
                    
                </Carousel>   
            </div>
          </div>

            
                
                        
        )
    }
}

export default Conferences