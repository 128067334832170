import React, { Component } from 'react'

const UserContext = React.createContext({
  get userId() {
    return localStorage.getItem('user_id');
  },
  setUserId: () => {},
  currentUser:{},
})

export default UserContext;

export class UserProvider extends Component{
  state = {
    currentUser:{},
    userId: localStorage.getItem('user_id'),
  }

  setCurrentUser = (user) =>{
    // console.log("updating user")
    if(user !== this.state.currentUser){
      this.setState({currentUser:user})
    }
  }

  setUserId = (userId) => {
    if(this.state.userId !== userId)
    {
      this.setState({ userId: localStorage.setItem('user_id', userId) });
    }
  };

  // getCurrentUser = () =>{

  // }

  render(){
    const values = {
      currentUser:this.state.currentUser,
      setCurrentUser:this.setCurrentUser, 
      userId: this.state.userId,
      setUserId: this.setUserId,
      // getCurrentUser:this.getCurrentUser
    }
    return (
      <UserContext.Provider value={values}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}