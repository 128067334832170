import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ProjectService from '../../services/ProjectService';
import ProductService from '../../services/ProductService';
import EventService from '../../services/EventService';
import ProjectDetail from './ProjectDetail';
import ProductDetail from './ProductDetail';
import UserContext from '../../contexts/UserContext';
// import Tabs from './Tabs'

export default function DetailPage(props) {
  const userContext = useContext(UserContext);
  const { id } = useParams();
  const [details, setDetails] = useState();
  const userType = userContext.currentUser.profile_type;

  const history = useHistory();

  const webLink = (event) => {
    if (userType == 'Member') {
      return (
        <a
          style={{ margin: '0.5em' }}
          className='cta-button remove-text-decoration width-50 m-a'
          href={event.member_website_link}
        >
          Sign Up
        </a>
      );
    } else if (userType != 'Member') {
      return (
        <a
          style={{ margin: '0.5em' }}
          className='cta-button remove-text-decoration width-50 m-a'
          href={event.website_link}
        >
          Sign Up
        </a>
      );
    }
  };

  useEffect(() => {
    if (props.type === 'project') {
      ProjectService.getProjectByID(id).then((project) => {
        project = project.project;
        setDetails(<ProjectDetail project={project} />);
      });
    } else if (props.type === 'product') {
      ProductService.getProductByID(id).then((product) => {
        console.log('PRODUCT', product);
        product = product.certified_product;
        setDetails(<ProductDetail product={product} />);
      });
    } else if (props.type === 'event') {
      EventService.getEventByID(id).then((event) => {
        // console.log(event)
        event = event.event;
        console.log(event);
        // const eventDate = new Date(event.date)
        // console.log(eventDate)
        setDetails(
          <div className='detailCard flex-column header-align-content'>
            {/* <img className="detailImg" src={event.image} alt={event.name} /> */}
            <h2>{event.campaign_name}</h2>
            <table className='detailTable2'>
              <tbody>
                <tr>
                  <th>Format</th>
                  <td>{event.open_text}</td>
                </tr>
                <tr>
                  <th>Member Price</th>
                  <td>${event.member_price}</td>
                </tr>
                <tr>
                  <th>Non-Member Price</th>
                  <td>${event.non_member_price}</td>
                </tr>
                <tr>
                  <th>Location</th>
                  <td>{event.location}</td>
                </tr>
                {/* <tr>
                    <th>Website Link</th>
                    <td>{event.website_link}</td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td>{event.description}</td>
                  </tr> */}
                {/* <tr>
                    <th>Attending</th>
                    <td>{event.attendees.length}</td>
                  </tr> */}
              </tbody>
            </table>
            <div className='flexbox-column'>
              {webLink(event)}
              {/* <a
                style={{ margin: '0.5em' }}
                className='cta-button remove-text-decoration width-50 m-a'
                href={event.member_website_link}
              >
                Sign Up
              </a> */}
              <p>{event.description}</p>
            </div>
          </div>
        );
      });
    } else {
      setDetails(<div>There's nothing here!</div>);
    }
  }, [id, props]);

  return (
    <div className='card-container detailCardContainer'>
      <div className='detail-card-body card-body'>
        {details}
        <button
          onClick={() => history.goBack()}
          className='cta-button2 remove-text-decoration detailButton'
        >
          Go Back
        </button>
      </div>
    </div>
  );
}
