import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ProjectService from '../../services/ProjectService'
import ProductService from '../../services/ProductService'
import "./reviewerProjects.css";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ReviewerProjects = () => {
  const [projects, setProjects] = useState()
  const [products, setProducts] = useState()
  // need to grab user to compare projects/products

  // This is the feedback return date
  const queueLink = (submitDate, projectNum, projectName, queue_type) => {
    if(submitDate == null) {
      return <a href={`https://forms.zohopublic.com/phius/form/SubmittoProjectReviewQueue/formperma/YTSDhsbXUZkyP6UdElySMwSW7EzPvnTDKODAoPsXVJM?ID=${projectNum}&PN=${projectName}&TYP=${queue_type}`} style={{color:'#00AAAF'}}>Submit to Review Queue</a>
    } else {
      return submitDate;
    }
  }

  useEffect(() => {
    // should we add a get all projects by contact role
    ProjectService.getMyProjects()
      .then(projects => {
        console.log(projects)
        let projectList = projects.projects.length !== 0 ? projects.projects.map(project => {
          if (project.status === "Submitted") return ''
          console.log("Project: ", project)
          return (<div className="rev-projects-and-products-headers rev-projects-headers bolder-font" key={project.id}>
            <div>{project.project_number}</div>
            <div><a target="_blank" href={project.dropbox_folder}>{project.dropbox_folder ? project.project_name : '' }</a></div>
            <div>{project.queue_stage}</div>
            <div>{project.queue_submit_date}</div>
            <div>{project.upload_required_date}</div>
            <div>{queueLink(project.queue_due_date, project.project_number, project.project_name, project.queue_type)}</div>
            <div>
              <div class="tooltip">  
    {project.queue_owner_name}
              <span class="tooltiptext">{project.queue_owner_email}</span>
  </div>
              </div>
            <Link to={`/details/project/${project.id}`} className="remove-text-decoration subhead teal-link">EDIT</Link>
          </div >
          )
        }) : []
        setProjects(projectList)
      })
    // should we add a get all products by contact roles?
    ProductService.getMyProducts()
      .then(products => {
        console.log(products)
        let productList = products.products.length !== 0 ? products.products.map(product => {
          console.log(product)
          return (<div className="rev-projects-and-products-headers rev-products-headers bolder-font" key={product.id}>
            {/* 
            Certified Job Name */}
            <div>{product.certification_job_name}</div>
            {/* Certification Job Number */}
            <div>{product.certification_job_number}</div>
            {/* Submitter*/}
            <div>{product.crm_contact_name}</div>
            {/* Product Category */}
            <div>{product.window_product_category.join(', ')}</div>
            {/* Manufacturer*/}
            <div>{product.crm_account_name}</div>
            {/* Job Folder*/}
            <div><a href={product.job_folder}>{product.job_folder ? 'Dropbox' : ''}</a></div>
            {/* Certification Job Date*/}
            <div>{new Date(product.certification_job_date).toLocaleDateString()}</div>
            {/* Certification Job Expiration Date */}
            <div>{new Date(product.certification_job_expiration_date).toLocaleDateString()}</div>
            {/* Job Status */}
            <div>{product.job_status}</div>
          </div >
          )
        }) : []
        setProducts(productList)
      })
  }, [])

  return (
    <div className="rev-card-container width-99" >
      <div className="rev-card-header-grey display-flex">
        <h2 className="rev-card-header-title-style-gray">MANAGE YOUR PROJECTS & PRODUCTS</h2>
        <div style={{marginTop:'0.25em'}}>
          <a className="cta-button4 remove-text-decoration" style={{ "marginRight": "3em" }} target="_blank" rel="noopener noreferrer" href="https://forms.zohopublic.com/phius/form/SubmitaProject/formperma/9OwDJRYIStRrYRRRW7tMOKjPxpLc1w3C1njoj1HsUrU">SUBMIT A PROJECT</a>
          <a className="cta-button4 remove-text-decoration" style={{ "marginRight": "3em" }} target="_blank" rel="noopener noreferrer" href="https://forms.zohopublic.com/phius/form/SubmitaWindow1/formperma/BAVojH4UN8cz-ZeerzQz0Wqxi882i0_lMhHkZG9XxhQ">SUBMIT A PRODUCT</a>
        </div>
      </div>
      <div className="rev-card-body">
        <h3 className="subhead">My Projects</h3>
        <div className="rev-projects-and-products-headers-top rev-projects-headers">
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Project No.</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Project Name</p>
          {/* <p className="rev-projects-and-products-headers-text-color-grey small-text">Dropbox Link</p> */}
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Stage</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Submission Date</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Upload Required Date</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Feedback Return Date</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Project Certifier</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text"><FontAwesomeIcon icon="edit" /></p>
        </div>
        <div className="rev-overflow-container">
          {projects}
        </div>
      </div>
      <div className="rev-card-body">
        <h3 className="subhead">My Products</h3>
        <div className="rev-projects-and-products-headers-top rev-products-headers">
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Certified Job Name</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Certification Job Number</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Submitter</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Product Category</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Manufacturer</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Job Folder</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Certification Job Date</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Certification Job Expiration Date</p>
          <p className="rev-projects-and-products-headers-text-color-grey small-text">Job Status</p>
          {/* <p className="rev-projects-and-products-headers-text-color-grey small-text"><FontAwesomeIcon icon="edit" /></p> */}
        </div>
        <div className="rev-overflow-container">
          {products}
        </div>
      </div>
    </div>
  )
}

export default ReviewerProjects