import React from 'react'
import './notFoundPage.css'
// components

export default function NotFoundPage() {
  return (
    <div className="card-body not-found-page">
      <div className="flex flex-center">
        <h2 className="not-found-number text-center">404</h2>
        <h3 className="not-found-text text-center border-left">The requested page could not be found.</h3>
      </div>
      {/* <h2 className="not-found-text text-center">The page requested could not be found.</h2> */}
    </div>
  )
}
