// import dummy from '../dummyData/dummy.json'
import TokenService from '../services/token-service'
import config from '../config'

const ProjectService = {
  // see user service for REST API Express function examples
  async getAllProjects(){
    // return {"projects":dummy.projects};
    return fetch(`${config.API_ENDPOINT}/projects`, {
      method:'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  async getMyProjects(){
    return fetch(`${config.API_ENDPOINT}/projects/myprojects`, {
      method:'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  async getProjectByID(id){
    // const proj = dummy.projects.find(proj => proj.id === Number(id))
    // // console.log(proj)
    // return {"project":proj}
    return fetch(`${config.API_ENDPOINT}/projects/${id}`, {
      method:'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  getProjectsByKeyword(keyword){
    // const projects = dummy.projects;
    // return projects.filter(obj => Object.keys(obj).some(key => obj[key].toString().toLowerCase().includes(keyword.toLowerCase())));
    return fetch(`${config.API_ENDPOINT}/projects/search/${keyword}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  async updateProject(project){
    // const proj = dummy.projects.find(proj => proj.id === Number(id))
    // // console.log(proj)
    // return {"project":proj}
    return fetch(`${config.API_ENDPOINT}/zoho/update/crm/certified_projects`, {
      method:'POST',
      body:JSON.stringify(project),
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  getAllPolicies(){
    return fetch(`${config.API_ENDPOINT}/policies`,{
      method:'GET',
      headers:{
        'content-type':'application/json',
        'Authorization': TokenService.getAuthToken()
      }
    }).then((res) =>
    !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  );
  }
}

export default ProjectService;