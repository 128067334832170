// import dummy from '../dummyData/dummy.json'
import TokenService from '../services/token-service'
import config from '../config'
const EventService = {
  // see user service for REST API Express function examples
  async getAllEvents(){
    // return {events:dummy.events};
    return fetch(`${config.API_ENDPOINT}/events`, {
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  async getMyEvents(){
    // return {events:dummy.events};
    return fetch(`${config.API_ENDPOINT}/events/myevents`, {
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  async getEventByID(id){
    // const event = dummy.events.find(event => event.id === Number(id))
    // return {"event":event};
    return fetch(`${config.API_ENDPOINT}/events/${id}`, {
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  // getEventsByKeyword(keyword){
  //   const events = dummy.events.filter(obj => Object.keys(obj).some(key => obj[key].toString().toLowerCase().includes(keyword.toLowerCase())));
  //   return {"events":events}
  //   // return fetch(`${config.API_ENDPOINT}/events/search/${keyword}`, {
  //   //   method: 'GET',
  //   //   headers: {
  //   //     'content-type': 'application/json',
  //   //   },
  //   // }).then((res) =>
  //   //   !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
  //   // );
  // },
  // CEU endpoints
  async getAllCEUs(){
    // return {events:dummy.events};
    return fetch(`${config.API_ENDPOINT}/ceus`, {
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  async getCEUByID(id){
    // const event = dummy.events.find(event => event.id === Number(id))
    // return {"event":event};
    return fetch(`${config.API_ENDPOINT}/ceus/${id}`, {
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
}

export default EventService;