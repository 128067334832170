import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import ProjectService from '../../services/ProjectService'

export default function ProjectForm(props) {
  const [projects, setProjects] = useState();
  
  useEffect(()=>{
    ProjectService.getAllProjects()
      .then(projs => {
        let projectDivs = projs.projects.map(project => {
          return (<div key={project.id} className="projects-and-products-headers bolder-font">
            <div>{project.project_number}</div>
            <div>{project.project_name}</div>
            <div>{project.status} &#8287;&#8287;&#8287;&#8287;<Link to={`/details/project/${project.id}`} className="remove-text-decoration"></Link></div>
          </div>
          )
        })
        setProjects(projectDivs)
      })
  },[])
  
  return (
    <div className="card-container" >
      <div className="card-header-grey display-flex">
        <p className="card-header-title-style-gray">Manage Your Projects & Products</p>
        <Link to="/projects" className="remove-text-decoration card-header-title-style-gray">View All</Link>
      </div>
      <div className="card-body">
        <div className="projects-and-products-headers">
          <p className="projects-and-products-headers-text-color-grey">No.</p>
          <p className="projects-and-products-headers-text-color-grey">Title</p>
          <p className="projects-and-products-headers-text-color-grey">Status</p>
        </div>
        <div className="overflow-container">
          {projects}
        </div>
      </div>
    </div>
  )
}
