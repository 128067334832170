import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import UserService from '../../services/UserService'
import EventService from '../../services/EventService'
import ContinuingEducation from '../../components/continuingEducation/continuingEducation'
import './profile.css'

export default function ProfilePage() {
  const { id } = useParams();
  // console.log("ID:", id)
  const [user, setUser] = useState({});
  const [events, setEvents] = useState();
  const [certifications,setCertifications] = useState();

  useEffect(() => {
    EventService.getAllEvents()
      .then(evts => {
        // console.log(evts)
        // need to filter events by USER attending
        let eventDivs = evts.events.map(event => {
          const eventDate = new Date(event.start_date)
          const today = new Date()
          // console.log(Math.round(Math.abs(today - eventDate) / (1000 * 60 * 60 * 24)) < 90)
          return (today < eventDate && Math.round(Math.abs(today - eventDate) / (1000 * 60 * 60 * 24)) < 90) ? (<>
            <div className="training-and-events-container margin-bottom" key={event.id}>
              <div className="upcoming-training-and-events-headers border-bottom-div-gray">
                <h3 className="bolder-font">{event.name}</h3>
                <h4 className="projects-and-products-headers-text-color-grey">{eventDate.toLocaleDateString()}</h4>
              </div>
              <div className="upcoming-training-and-events-headers">
                <div className="margin-bottom">
                  <p className="projects-and-products-headers-text-color-grey">{event.description}</p>
                </div>
                <div className="display-flex">
                  <a className="remove-text-decoration card-header-title-style-gray letter-spacing-button" target="_blank" rel="noopener noreferrer" href={`https://phius1.zohobackstage.com/${event.name}`}>Register</a>
                  <button className="cta-button3" type="button">Member Only Event</button>
                </div>
              </div>
            </div>
          </>
          ) : ''
        })
        setEvents(eventDivs)
      })
  }, [])



  useEffect(() => {
    // console.log("in effect")
    UserService.getUserById(Number(id))
      .then(res => {
        console.log("USER RES: ",res.user)
        setUser(res.user)
        let userCertifications = [];
        // if(res.user.verifier_cert) userCertifications.push('verifier')
        // if(res.user.cphb_cert) userCertifications.push('cphb')
        // if(res.user.cphc_cert) userCertifications.push('cphc')
        // if(res.user.rater_cert) userCertifications.push('rater')
        const certificationState = res.user.certifications.length > 0 ? res.user.certifications.map(cert =>{
          const certificationString = res.user[`${cert.toLowerCase()}_certification_date`]
          const certificationDate = new Date(certificationString).toLocaleDateString()
          const expirationString = res.user[`${cert.toLowerCase()}_expiration_date`]
          const expirationDate = new Date(expirationString).toLocaleDateString()
          // console.log(certificationString,expirationString)
          return (<div className="card-body">
            <h2 className="uppercase certification-title">{cert}</h2>
            <hr />
            <div className="display-flex ">
            <p><span className="bolder">Certification Date: </span>{certificationDate}</p>
            <p><span className="bolder">Expiration Date: </span>{expirationDate}</p>
            </div>
          </div>)
        }) : <div style={{textAlign:'center'}}>No Certifications Found</div>
        setCertifications(certificationState)
      })
    }, [id])
  // console.log(user)
  let certificationStatus;
  // let certifications = [];
  return user == null ? <p>loading...</p> : (
    <div>
      <div className="profile-Row3">
        <section className="card-body profile-card-marg">
          <div className="profile-card-banner">
            <img className="profile-picture" src={user.profilePicture} alt={user.first_name} />
            <h3 className="text-center">{user.first_name} {user.last_name}</h3>
            <h4 className="italics">{certificationStatus}</h4>
          </div>
          <table className="profile-card-table">
            <tbody>
            <tr>
              <td className="profile-th">Phone</td>
              <td>{user.phone}</td>
            </tr>
            <tr>
              <td className="profile-th">Email</td>
              <td>{user.email}</td>
            </tr>
            <tr>
              <td className="profile-th">Address</td>
              <td>{user.street_address}<br></br>{user.city}, {user.state} {user.postal_code}</td>
            </tr>
            </tbody>
          </table>
        </section>
        <section className="card-body profile-card-marg">
          <div className="card-header-grey display-flex">
            <p className="card-header-title-style-gray">Certifications</p>
          </div>
          <div className="profile-certifications-overflow">
            {certifications}
          </div>
        </section>
        <section className="card-body profile-card-marg">
          <div className="card-header-grey display-flex">
            <p className="card-header-title-style-gray">My Events</p>
            <Link to="/events" className="remove-text-decoration card-header-title-style-gray">View All</Link>
          </div>
          <div className="card-body profile-events profile-overflow">
          {events}
        </div>
        </section>
      </div>
      <div className="profile-Row2">
        <section className="card-body profile-card-marg">
          <div className="card-header-grey display-flex">
            <p className="card-header-title-style-gray">Professional Information</p>
          </div>
          <table className="profile-card-table pi-font-size">
            <tbody>
            <tr><td className="profile-th text-right">AIA#</td><td>{user.AIA}</td></tr>
            <tr><td className="profile-th text-right">BPI#</td><td>{user.BPI}</td></tr>
            <tr><td className="profile-th text-right">PHIUS Professional #</td><td>{user.member_type}</td></tr>
            <tr><td className="profile-th text-right">Membership #</td><td>{user.membership_number}</td></tr>
            <tr><td className="profile-th text-right">Liability Insurance</td><td>{user.liabilityinsurance}</td></tr>
            <tr><td className="profile-th text-right">PHIUS Member</td><td>{user.phius_member_id}</td></tr>
            </tbody>
          </table>
        </section>
        {/* <section className="card-body profile-card-marg">Continuing Education</section> */}
        <ContinuingEducation profile={true} />
      </div>
    </div>
  )
}
