import React, { Component } from 'react'

export class GuideBook extends Component {
    render() {
        return (
            <div>
                <div className="card-header-gray">
                <h3>Find Resources & Guidebooks</h3>
                </div>
                <div className="card-body">
                <p className="body-text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </p>
                <button className="cta-button">BROWSE</button>
                </div>
            </div>
        )
    }
}

export default GuideBook
