import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import UserService from '../../services/UserService';
import EventService from '../../services/EventService';

const CertificationReport = () => {
  const userContext = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState(userContext.currentUser);
  const [MyCertifications, setMyCertifications] = useState([]);
  const [myContinuingEducation, setMyContinuingEducation] = useState([]);
  const [myRenewalRequirements, setRenewalRequirements] = useState([]);
  // const [loading, setLoading] = useState(true)

  // // useEffect(() => {
  let certificationsBuffer;
  let ceuBuffer;
  let renewalBuffer;

  const isUsers = (ceu) => {
    console.log('CEU Contact: ', ceu.crm_contact_id, ' : ', currentUser.crm_id);
    return ceu.crm_contact_id === currentUser.crm_id;
  };

  const sortCerts = (certs) => {
    // console.log(evts)
    if (certs === undefined || certs === null) return [];
    return certs.sort(function (a, b) {
      let keyA =
          a.date_earned !== undefined
            ? new Date(a.date_earned)
            : new Date(a.date_earned),
        keyB =
          b.date_earned !== undefined
            ? new Date(b.date_earned)
            : new Date(b.date_earned);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  };
  const getCertifications = () => {
    // UserService.getUserById(currentUser.id)
    // .then(user => {
    // if (user.certifications === undefined || user.certifications.length === 0) {
    //   ceuBuffer = <div key="000" className="events-headers bolder-font">
    //     <div></div>
    //     <div></div>
    //     <div>No Certifications Found</div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //   </div>
    //   setMyContinuingEducation(ceuBuffer)
    //   return
    // }
    console.log(currentUser);
    let user = currentUser;
    let certs =
      user.certifications !== undefined && user.certifications !== null
        ? user.certifications
        : [];
    certificationsBuffer = certs.map((cert, idx) => {
      console.log(user);
      const certificationString =
        user[`${cert.toLowerCase()}_certification_date`];
      const certificationDate = new Date(
        certificationString
      ).toLocaleDateString();
      const expirationString = user[`${cert.toLowerCase()}_expiration_date`];
      const expirationDate = new Date(expirationString).toLocaleDateString();
      const renewalString = user[`${cert.toLowerCase()}_renewal_date`];
      const renewalDate = new Date(renewalString).toLocaleDateString();
      const ceuTotal = user[`${cert.toLowerCase()}_ceu_total`];
      const ceuStatus = user[`${cert.toLowerCase()}_status`];
      // ceu total per cert
      return (
        <div key={idx} className='certification-headers bolder-font'>
          <div>{cert !== null ? cert : '-'}</div>
          <div>{certificationDate !== null ? certificationDate : '-'}</div>
          <div>{renewalDate !== null ? renewalDate : '-'}</div>
          <div>{expirationDate !== null ? expirationDate : '-'}</div>
          <div>{ceuTotal !== null ? ceuTotal : '-'}</div>
          <div>{ceuStatus !== null ? ceuStatus : '-'}</div>
        </div>
      );
    });
    // if (certificationsBuffer.length == 0) {
    //   certificationsBuffer = <div key="000" className="events-headers bolder-font">
    //     <div></div>
    //     <div></div>
    //     <div>No Certifications Found</div>
    //     <div></div>
    //     <div></div>
    //   </div>
    // }
    if (MyCertifications !== certificationsBuffer) {
      setMyCertifications(certificationsBuffer);
    }
    // })
    return;
  };
  const getMyContinuingEducation = () => {
    EventService.getAllCEUs()
      .then((CEU) => {
        console.log('CEU: ', CEU);
        // if (CEU.events === undefined) {
        //   ceuBuffer = <div key="000" className="events-headers bolder-font">
        //     <div></div>
        //     <div></div>
        //     <div>No Certifications Found</div>
        //     <div></div>
        //     <div></div>
        //     <div></div>
        //   </div>
        //   setMyContinuingEducation(ceuBuffer)
        //   return
        // }
        // add filter to only user's ceus
        let sortedCeus = sortCerts(CEU.ceus);
        ceuBuffer = sortedCeus.filter(isUsers).map((cert) => {
          console.log(cert);
          // let startDate = new Date(event.Start_Date);
          // let endDate = new Date(event.End_Date);
          let dateEarned = new Date(cert.date_earned) || null;
          return (
            <div
              key={cert.id}
              className='continuing-education-headers bolder-font'
            >
              <div>{cert.ceu_name !== null ? cert.ceu_name : '-'}</div>
              <div>
                {dateEarned !== null ? dateEarned.toLocaleDateString() : '-'}
              </div>
              <div>
                {cert.number_of_credits !== null ? cert.number_of_credits : '-'}
              </div>
              <div>{cert.session_code !== null ? cert.session_code : '-'}</div>
              <div>
                {cert.project_number !== null ? cert.project_number : '-'}
              </div>
              <div>{cert.project_role !== null ? cert.project_role : '-'}</div>
            </div>
          );
        });
        // if (ceuBuffer.length == 0) {
        //   ceuBuffer = <div key="000" className="events-headers bolder-font">
        //     <div></div>
        //     <div></div>
        //     <div>No Certifications Found</div>
        //     <div></div>
        //     <div></div>
        //     <div></div>
        //   </div>
        // }
        console.log('CEU BUFFER: ', ceuBuffer);
        if (myContinuingEducation !== ceuBuffer && ceuBuffer.length > 0) {
          setMyContinuingEducation(ceuBuffer);
        }
      })
      .catch((err) => {
        console.log(err);
        return;
      });
    return;
  };

  const getRenewalRequirements = () => {
    // UserService.getUserById(Number(UserContext.userId))
    // .then(res => {
    const certs = currentUser !== undefined ? currentUser.certifications : [];
    console.log('certs: ', currentUser.certifications);
    if (certs !== undefined) {
      if (certs.length === 0) {
        //   renewalBuffer = <div key="000" className="events-headers bolder-font">
        //     <div></div>
        //     <div>No Certifications Found</div>
        //     <div></div>
        //     <div></div>
        //   </div>
      } else {
        renewalBuffer = (
          <div className='renewal-headers bolder-font'>
            {certs.includes('CPHC') ? (
              <div>
                <a
                  className='teal-link remove-text-decoration'
                  href='https://www.phius.org/maintain-credentials'
                >
                  See Requirements
                </a>
              </div>
            ) : (
              <div> - </div>
            )}
            {certs.includes('CPHB') ? (
              <div>
                <a
                  className='teal-link remove-text-decoration'
                  href='https://www.phius.org/maintain-credentials'
                >
                  See Requirements
                </a>
              </div>
            ) : (
              <div> - </div>
            )}
            {certs.includes('Rater') ? (
              <div>
                <a
                  className='teal-link remove-text-decoration'
                  href='https://www.phius.org/maintain-credentials'
                >
                  See Requirements
                </a>
              </div>
            ) : (
              <div> - </div>
            )}
            {certs.includes('Verifier') ? (
              <div>
                <a
                  className='teal-link remove-text-decoration'
                  href='https://www.phius.org/maintain-credentials'
                >
                  See Requirements
                </a>
              </div>
            ) : (
              <div> - </div>
            )}
          </div>
        );
      }
    }
    if (myRenewalRequirements === renewalBuffer) return;
    setRenewalRequirements(renewalBuffer);
    // })
    return;
  };

  // // }, [])
  const renderMyEvents = (
    <>
      <div className='card-header-grey display-flex'>
        <p className='card-header-title-style-gray'>My Certifications</p>
      </div>
      <div className='card-body-report h30'>
        <div className='certification-headers'>
          <p className='projects-and-products-headers-text-color-grey'>
            Certification
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Certification Date
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Renewal Date
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Expiration Date
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Current CEU Total
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Status
          </p>
        </div>
        <div className='overflow-container report'>{MyCertifications}</div>
      </div>
      <div className='card-header-grey display-flex'>
        <p className='card-header-title-style-gray'>My Continuing Education</p>
      </div>
      <div className='card-body-report h30'>
        <div className='continuing-education-headers'>
          <p className='projects-and-products-headers-text-color-grey'>
            CEU Name
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Date Earned
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Number of Credits
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Session Code
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Project Number
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Project Role
          </p>
        </div>
        <div className='overflow-container report'>{myContinuingEducation}</div>
      </div>
      <div className='card-header-grey display-flex'>
        <p className='card-header-title-style-gray'>Renewal Requirements</p>
      </div>
      <div className='card-body-report h30'>
        <div className='renewal-headers'>
          <p className='projects-and-products-headers-text-color-grey'>
            Phius Certified CPHC
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Phius Certified CPHB
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Phius Certified Rater
          </p>
          <p className='projects-and-products-headers-text-color-grey'>
            Phius Certified Verifier
          </p>
        </div>
        <div className='overflow-container report'>{myRenewalRequirements}</div>
      </div>
    </>
  );

  useEffect(() => {
    console.log(currentUser);
    if (
      currentUser !== undefined &&
      Object.keys(userContext.currentUser).length > 0
    ) {
      getCertifications();
      console.log('about to call getMyContinuingEducation function in onMount');
      getMyContinuingEducation();
      getRenewalRequirements();
    }
    // else{
    //   UserService.getUserByToken()
    //       .then(res => {
    //         console.log("else2: ",res)
    //         if(userContext.currentUser.crm_contact_id !== res.user.crm_contact_id){
    //           console.log("else3")
    //           if(userContext.currentUser !== res.user){
    //             console.log("else4")
    //             userContext.setCurrentUser(res.user)
    //           }
    //         }
    //         if(currentUser.crm_contact_id !== res.user.crm_contact_id){
    //           console.log("else5")
    //           if(currentUser !== res.user){
    //             console.log("else6")
    //           setCurrentUser(userContext.currentUser)
    //           }
    //         }
    //       })
    // }
  }, []);

  useEffect(() => {
    if (Object.keys(userContext.currentUser).length > 0) {
      console.log('UE-user: ', currentUser);
    } else {
      console.log('else1');
      // if(currentUser !== userContext.currentUser){
      UserService.getUserByToken().then((res) => {
        console.log('else2: ', res);
        if (
          userContext.currentUser.crm_contact_id !== res.user.crm_contact_id
        ) {
          console.log('else3');
          if (userContext.currentUser !== res.user) {
            console.log('else4');
            userContext.setCurrentUser(res.user);
          }
        }
        if (currentUser.crm_contact_id !== res.user.crm_contact_id) {
          console.log('else5');
          if (currentUser !== res.user) {
            console.log('else6');
            setCurrentUser(res.user);
          }
        }
      });
      // }
    }
  }, [currentUser, userContext]);

  useEffect(() => {
    if (Object.keys(currentUser).length > 0) {
      if (MyCertifications !== undefined) {
        // if (MyCertifications.length === 0) {getCertifications()}
      }
      if (myContinuingEducation !== undefined) {
        //       if (myContinuingEducation.length !== 0) {
        //         console.log("about to call getMyContinuingEducation function in useEffect")
        //         console.log(myContinuingEducation)
        //         getMyContinuingEducation()
        //       }
      }
      if (myRenewalRequirements !== undefined) {
        //       if (myRenewalRequirements.length === 0) {getRenewalRequirements()}
      }
    }
  }, [myContinuingEducation, MyCertifications, myRenewalRequirements]);

  // if (loading) {
  //   return <section style={{ position: 'relative', top: '50%', left: '50%' }}>
  //     {/* loading user */}
  //     <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  //   </section>
  // }
  return (
    <section>
      <div className='card-container'>
        {/* my certifications */}
        {Object.keys(userContext.currentUser).length > 0 ? renderMyEvents : ''}
      </div>
    </section>
  );
};

export default CertificationReport;
